import React, { useEffect, useState } from 'react';
import AuthZero from './AuthZero';
import LDAP from './LDAP';
import FileBased from './FileBased';
import BottomBar from '../Home/BottomBar';
import Topbar from '../Home/Topbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '../../hooks/useConfig';
import ToastMessage from '../ToastMessage';
import { useStore } from '../../store/AppContext';

const AuthConfig = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const configHook = useConfig();
    const { state } = useStore();
    const [authConfig, setAuthConfig] = useState({ type: null, label: null, value: null });
    const [canSave, setCanSave] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (state.authConfig.type)
            navigate('/auth');
    }, [state.authConfig])

    useEffect(() => {
        showToast && setTimeout(() => {
            setShowToast(false);
        }, 3000);
    }, [showToast])

    const handleSave = () => {
        if (authConfig.type === 'file_based')
            configHook.uploadFileBased(authConfig.value, navigate, '/user-access');
        else
            configHook.updateConfig('AUTH', authConfig, true, navigate, '/doc-search/app');
    }

    const handleSkip = () => {
        navigate(location.state && location.state.previousPath ? location.state.previousPath : '/doc-search/app');
    }

    const ButtonList = [
        {
            "name": "Skip",
            "handleFunction": handleSkip,
            "disabled": false,
            "type": 'outline',
        },
        {
            "name": "Next",
            "handleFunction": handleSave,
            "disabled": !canSave
        }
    ]

    return (
        <div className="h-screen bg-white dark:bg-gray-dark">
            <Topbar showHeaderIcon={true} />
            <div className="content-container relative bg-white dark:bg-gray-dark overflow-auto hide-scrollbar">
                <p className="mb-4 mx-6 pb-3 text-gray-dark dark:text-white text-2xl font-semibold text-center">Select Your Authentication Method</p>
                <div className="grid md:grid-cols-3 gap-6 mx-6">
                    <AuthZero containerStyle={'w-full'} authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} setShowToast={setShowToast} />
                    <LDAP containerStyle={'w-full'} authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} setShowToast={setShowToast} />
                    <FileBased containerStyle={'w-full'} authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} setShowToast={setShowToast} />
                </div>
                <ToastMessage id={'auth_info'} isOpen={showToast} message={'You can\'t configure more than one method. Clear the fields and configure other.'} />
            </div>
            <BottomBar ButtonList={ButtonList} />
        </div>
    )
}

export default AuthConfig;
