import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DocSearchConfigPage from './DocSearchConfigPage';
import DocSearch from './DocSearch';
import NewLLM from '../../components/NewLLM';
import { ROUTES_ID } from '../../config';
import EditLLM from '../../components/EditLLM';

const DocSearchPage = ({ isSidebarOpen, activeTabId, activeLLM }) => {
	return (
		<Routes>
			<Route path='/app' element={<DocSearch isSidebarOpen={isSidebarOpen} activeTabId={activeTabId} activeLLM={activeLLM} />} />
			<Route path='/config' element={<DocSearchConfigPage />} />
			<Route path='/new-llm' element={<NewLLM appId={ROUTES_ID.DOCUMENT_SEARCH} />} />
			<Route path='/edit-llm' element={<EditLLM appId={ROUTES_ID.DOCUMENT_SEARCH} />} />
		</Routes>
	)
}

export default DocSearchPage;
