import { CONFLUENCE_ICON, DOCUSIGN_ICON, GOOGLE_DRIVE_ICON, JIRA_ICON, LOCAL_ICON, MONDAY_DOT_COM_ICON, ONEDRIVE_ICON, QUICKBOOKS_ICON, SCRAPINGBEE_ICON, SLACK_ICON } from "./images";

export const BASE_URL = (window.origin === 'http://localhost:3000') ? "http://52.40.226.174:2498" : window.origin;
export const BASE_WEBSOCKET_URL = (window.origin === 'http://localhost:3000') ? "ws://52.40.226.174:2498" : `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}`;

export const LICENSE_SERVER_URL = "https://generatehelp.iterate.ai";

export const API_URL = BASE_URL + "/api/v1";

export const API_KEY = "bGxtX21hbmFnZXI6bGxtX21hbmFnZXJAYXBpX2tleQ==";

export const USER_ROLE = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    USER: 'user'
}

export const USER_ROLE_LABEL = {
    [USER_ROLE.SUPER_ADMIN]: 'Super Admin',
    [USER_ROLE.ADMIN]: 'Admin',
    [USER_ROLE.USER]: 'User'
}

export const ROUTES_ID = {
    DASHBOARD: "DASHBOARD",
    SERVICE_PILOT: "CO_PILOT",
    DATABASE_SEARCH: "DB_SEARCH",
    DOCUMENT_SEARCH: "DOCUMENT_SEARCH",
    MY_DOCUMENTS: "MY_DOCUMENTS",
    MY_DATABASES: "MY_DATABASES",
    EMAIL_MANAGER: "EMAIL_MANAGER",
    DATABASE_MANAGER: "DATABASE_MANAGER",
    LLM_MANAGER: "LLM_MANAGER",
    INTEGRATIONS: "INTEGRATIONS",
    AUTHENTICATION: "AUTHENTICATION",
    USER_ACCESS: "USER_ACCESS"
}

export const ROUTES = {
    "/dashboard": ROUTES_ID.DASHBOARD,
    "/service-pilot/app": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/email": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/config": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/new-llm": USER_ROLE.USER ? '' : ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/edit-llm": USER_ROLE.USER ? '' : ROUTES_ID.SERVICE_PILOT,
    "/db-search/app": ROUTES_ID.DATABASE_SEARCH,
    "/db-search/config": ROUTES_ID.DATABASE_SEARCH,
    "/db-search/new-llm": USER_ROLE.USER ? '' : ROUTES_ID.DATABASE_SEARCH,
    "/db-search/edit-llm": USER_ROLE.USER ? '' : ROUTES_ID.DATABASE_SEARCH,
    "/doc-search/app": ROUTES_ID.DOCUMENT_SEARCH,
    "/doc-search/config": ROUTES_ID.DOCUMENT_SEARCH,
    "/doc-search/new-llm": USER_ROLE.USER ? '' : ROUTES_ID.DOCUMENT_SEARCH,
    "/doc-search/edit-llm": USER_ROLE.USER ? '' : ROUTES_ID.DOCUMENT_SEARCH,
}

export const APP_ROUTE_PATHS = {
    [ROUTES_ID.DASHBOARD]: "/dashboard",
    [ROUTES_ID.SERVICE_PILOT]: "/service-pilot",
    [ROUTES_ID.DATABASE_SEARCH]: "/db-search",
    [ROUTES_ID.DOCUMENT_SEARCH]: "/doc-search",
    [ROUTES_ID.AUTHENTICATION]: "/auth",
    [ROUTES_ID.USER_ACCESS]: "/users"
}

export const APPS_ID = [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH];

export const CONFIG_TABS_INFO = {
    'my-documents': {
        route_id: ROUTES_ID.MY_DOCUMENTS
    },
    'my-databases': {
        route_id: ROUTES_ID.MY_DATABASES
    },
    'email-manager': {
        route_id: ROUTES_ID.EMAIL_MANAGER
    },
    'database-manager': {
        route_id: ROUTES_ID.DATABASE_MANAGER
    },
    'llm-manager': {
        route_id: ROUTES_ID.LLM_MANAGER
    },
    'integrations': {
        route_id: ROUTES_ID.INTEGRATIONS
    }
}

export const APPS = {
    [ROUTES_ID.SERVICE_PILOT]: 'Service Pilot',
    [ROUTES_ID.DATABASE_SEARCH]: 'Database Search',
    [ROUTES_ID.DOCUMENT_SEARCH]: 'Document Search'
}

export const TOOL_TIPS = {
    [ROUTES_ID.DASHBOARD]: "Dashboard provides an overview of application usage, and a list of recent chat queries to help you monitor and analyze activity",
    [ROUTES_ID.SERVICE_PILOT]: "Service Pilot combines Document Search capabilities and Large Language Model (LLM) technology to generate email summaries and data-driven responses for any inbound email",
    [ROUTES_ID.DATABASE_SEARCH]: "Database Search employs a Large Language Model (LLM) powered agent approach for advanced database querying, visualizations, and analysis, all through natural language prompts",
    [ROUTES_ID.DOCUMENT_SEARCH]: "Document Search employs generative AI technology for information retrieval, formatting, and analysis, all through natural language prompts",
    [ROUTES_ID.MY_DOCUMENTS]: "My Documents allows you to upload, share, filter, and delete files",
    [ROUTES_ID.MY_DATABASES]: "View and manage all your connected databases. Delete or edit them, review their processing status, and identify file types with ease",
    [ROUTES_ID.EMAIL_MANAGER]: "Email Manager allows you to configure email accounts through IMAP settings",
    [ROUTES_ID.DATABASE_MANAGER]: "Database Manager allows you to add, edit, and remove databases from files as well as remote database servers",
    [ROUTES_ID.LLM_MANAGER]: "LLM Manager allows you to add, edit, and delete LLMs from Iterate and various cloud providers",
    [ROUTES_ID.INTEGRATIONS]: "Integration allows you to import documents and data from various applications such as Google Drive, One Drive, etc",
    [ROUTES_ID.AUTHENTICATION]: "Manage user authentication with file-based, LDAP, and Auth0 methods",
    [ROUTES_ID.USER_ACCESS]: "Manage users and provide fine-grained access control for your applications"
}

export const SUB_PAGE_APPS = {
    [ROUTES_ID.MY_DOCUMENTS]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH]
    },
    [ROUTES_ID.MY_DATABASES]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.DATABASE_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.DATABASE_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.DATABASE_SEARCH]
    },
    [ROUTES_ID.EMAIL_MANAGER]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT],
        [USER_ROLE.USER]: []
    },
    [ROUTES_ID.DATABASE_MANAGER]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.DATABASE_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.DATABASE_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.DATABASE_SEARCH]
    },
    [ROUTES_ID.LLM_MANAGER]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.USER]: []
    },
    [ROUTES_ID.INTEGRATIONS]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH]
    }
}

export const INTEGRATION_TYPES = {
    'local': { id: 'local', label: 'Local Disk', icon: LOCAL_ICON },
    'google': { id: 'google', label: 'Google Drive', icon: GOOGLE_DRIVE_ICON, comingSoon: false },
    'onedrive': { id: 'onedrive', label: 'OneDrive', icon: ONEDRIVE_ICON, comingSoon: false },
    'mondaydotcom': { id: 'mondaydotcom', label: 'Monday.com', icon: MONDAY_DOT_COM_ICON, comingSoon: false },
    'scrapingbee': { id: 'scrapingbee', label: 'ScrapingBee', icon: SCRAPINGBEE_ICON, comingSoon: true },
    'confluence': { id: 'confluence', label: 'Confluence', icon: CONFLUENCE_ICON, comingSoon: true },
    'jira': { id: 'jira', label: 'Jira', icon: JIRA_ICON, comingSoon: true },
    'slack': { id: 'slack', label: 'Slack', icon: SLACK_ICON, comingSoon: false },
    'quickbooks': { id: 'quickbooks', label: 'Quickbooks', icon: QUICKBOOKS_ICON, comingSoon: true },
    'docusign': { id: 'docusign', label: 'Docusign', icon: DOCUSIGN_ICON, comingSoon: true },
}

export const INTEGRATION_CREDENTIALS = {
    'google': { clientId: '561575696175-0jhv79k78b0662kqv8afajjq4kil1hn9.apps.googleusercontent.com', clientSecret: 'AIzaSyCctBt6H725dp_1IFnEcU69paKEhJSC_rM', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'onedrive': { clientId: '08ded917-2450-4fed-acfe-889216be77a2', clientSecret: '', redirectURL: window.origin },
    'mondaydotcom': { clientId: 'c73046111c56e5ef10305e0d59e9526e', clientSecret: '064f3eb01e806e0dfc4565016ffe0f24', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'scrapingbee': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'confluence': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'jira': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'slack': { clientId: '7232927498548.7366123528772', clientSecret: '7fefb489ae2b33994cdd1e9d244a9a24', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'quickbooks': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'docusign': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
}

export const APP_SUPPORT_INTEGRATION_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [INTEGRATION_TYPES.local, INTEGRATION_TYPES.google, INTEGRATION_TYPES.onedrive],
    [ROUTES_ID.DOCUMENT_SEARCH]: [INTEGRATION_TYPES.local, INTEGRATION_TYPES.google, INTEGRATION_TYPES.onedrive, INTEGRATION_TYPES.slack, INTEGRATION_TYPES.mondaydotcom],
}

export const APP_SUPPORT_FILE_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [{ id: 'pdf', label: '.pdf' }, { id: 'csv', label: '.csv' }, { id: 'docx', label: '.docx' }, { id: 'xlsx', label: '.xlsx' }, { id: 'pptx', label: '.pptx' }, { id: 'txt', label: '.txt' }],
    [ROUTES_ID.DOCUMENT_SEARCH]: [{ id: 'pdf', label: '.pdf' }, { id: 'csv', label: '.csv' }, { id: 'docx', label: '.docx' }, { id: 'xlsx', label: '.xlsx' }, { id: 'pptx', label: '.pptx' }, { id: 'txt', label: '.txt' }],
}

export const FILE_STATUS = {
    initial: { id: 'Initial', label: 'Initial', labelClassName: 'text-status-gray' },
    processing: { id: 'Processing', label: 'Processing', labelClassName: 'text-status-blue' },
    completed: { id: 'Completed', label: 'Completed', labelClassName: 'text-status-green' },
    corrupted: { id: 'Corrupted', label: 'Corrupted', labelClassName: 'text-status-red' },
    not_found: { id: 'Not Found', label: 'Not Found', labelClassName: 'text-status-light-red' }
}

export const APP_SUPPORT_FILE_STATUS = {
    [ROUTES_ID.SERVICE_PILOT]: [FILE_STATUS.initial, FILE_STATUS.processing, FILE_STATUS.completed, FILE_STATUS.corrupted, FILE_STATUS.not_found],
    [ROUTES_ID.DOCUMENT_SEARCH]: [FILE_STATUS.initial, FILE_STATUS.processing, FILE_STATUS.completed, FILE_STATUS.corrupted, FILE_STATUS.not_found],
}

export const MODEL_TYPES = {
    'openai': { id: 'openai', value: 'OpenAI', label: 'OpenAI' },
    'cohere': { id: 'cohere', value: 'Cohere', label: 'Cohere' },
    'groq': { id: 'groq', value: 'Groq', label: 'Groq' },
    'mistral': { id: 'mistral', value: 'Mistral', label: 'Mistral' },
    'interplay': { id: 'interplay', value: 'Interplay', label: 'Interplay' }
}

export const APP_SUPPORT_MODEL_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [MODEL_TYPES.openai, MODEL_TYPES.cohere, MODEL_TYPES.groq, MODEL_TYPES.mistral, MODEL_TYPES.interplay],
    [ROUTES_ID.DATABASE_SEARCH]: [MODEL_TYPES.openai],
    [ROUTES_ID.DOCUMENT_SEARCH]: [MODEL_TYPES.openai, MODEL_TYPES.cohere, MODEL_TYPES.groq, MODEL_TYPES.mistral, MODEL_TYPES.interplay],
}

export const VECTOR_DB_TYPES = {
    'redis': { id: 'redis', label: 'Redis' },
    'chroma_db': { id: 'chroma', label: 'Chroma DB' }
}

export const APP_SUPPORT_VECTOR_DB_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [VECTOR_DB_TYPES.redis, VECTOR_DB_TYPES.chroma_db],
    [ROUTES_ID.DOCUMENT_SEARCH]: [VECTOR_DB_TYPES.redis, VECTOR_DB_TYPES.chroma_db],
}

export const WORKFLOW_CARD_BUNDLE_ID = "Enterprise"

export const PAGE_SIZES = [100, 50, 25, 10];

export const EMAIL_SERVER_OPTIONS = [
    { id: "imap.gmail.com", label: "Gmail", value: "imap.gmail.com" }
]

export const DATABASE_TYPES = [
    { id: 'csv', label: 'CSV' },
    { id: 'excel', label: 'Excel' },
    { id: 'mysql', label: 'MySQL' },
    { id: 'postgresql', label: 'PostgreSQL' },
    { id: 'redshift', label: 'Redshift' },
    { id: 'bigquery', label: 'BigQuery' },
    { id: 'snowflake', label: 'Snowflake' }
]

export const DATABASE_TYPES_DICT = {
    csv: DATABASE_TYPES[0].id,
    excel: DATABASE_TYPES[1].id,
    mysql: DATABASE_TYPES[2].id,
    postgresql: DATABASE_TYPES[3].id,
    redshift: DATABASE_TYPES[4].id,
    bigquery: DATABASE_TYPES[5].id,
    snowflake: DATABASE_TYPES[6].id
}

export const DATABASES_USER_GUIDES = {
    [DATABASE_TYPES_DICT.mysql]: 'https://dev.mysql.com/doc/refman/8.0/en/database-use.html',
    [DATABASE_TYPES_DICT.postgresql]: 'https://www.postgresql.org/docs/current/index.html',
    [DATABASE_TYPES_DICT.redshift]: 'https://docs.aws.amazon.com/redshift/latest/mgmt/python-configuration-options.html',
    [DATABASE_TYPES_DICT.bigquery]: 'https://cloud.google.com/bigquery/docs/remote-functions',
    [DATABASE_TYPES_DICT.snowflake]: 'https://docs.snowflake.com/en/developer-guide/python-connector/python-connector-api'
}

export const ACCEPTED_FILE_TYPE = {
    [DATABASE_TYPES_DICT.csv]: { 'text/csv': ['.csv'] },
    [DATABASE_TYPES_DICT.excel]: { 'application/vnd.ms-excel': ['.xls'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
    [DATABASE_TYPES_DICT.bigquery]: { 'application/json': ['.json'] }
}

export const EXPORT_TYPES = {
    PDF: 'pdf'
}

export const SUBSCRIPTION_ALERT_DAYS = [90, 30, 15];
export const SUBSCRIPTION_ALERT_DAYS_RANGE = { max: 3, min: 1 };
export const LICENSE_KEY = "LICENSE_KEY"
