import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { useStore } from '../../store/AppContext';
import UserGroupAccessModal from '../../components/DocSearch/UserGroupAccessModal';
import BottomBar from "../../components/Home/BottomBar";
import { useUserGroup } from "../../hooks/useUserGroup";
import ConfirmPopup from "../../components/ConfirmPopup";
import FileUpload from "../../components/DocSearch/FileUpload";
import { useCoPilot } from "../../hooks/useCoPilot";
import TooltipWrapper from "../../components/TooltipWrapper";
import { Button } from "../../components/Button";
import { CheckBox } from "../../components/CheckBox";
import { APP_SUPPORT_FILE_STATUS, APP_SUPPORT_FILE_TYPES, APP_SUPPORT_INTEGRATION_TYPES, ROUTES_ID } from '../../config';
import Cookies from "js-cookie";
import { InputWrapper } from '../../components/InputWrapper';
import FilterPopup from '../../components/FilterPopup';

const ServicePilotManageDocuments = () => {
	const coPilotHook = useCoPilot();
	const userGroupHook = useUserGroup();
	const { state } = useStore();
	const [isOpen, setIsOpen] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [fliterApplied, setFilterApplied] = useState(false);
	const [filterIntegrations, setFilterIntegrations] = useState([]);
	const [filterFileType, setFilterFileTypes] = useState([]);
	const [filterFileStatus, setFilterFileStatus] = useState([]);
	const [filterUserGroups, setFilterUserGroups] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [userGroups, setUserGroups] = useState([]);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [selectedResourceData, setSelectedResourceData] = useState({});
	const [selectedResourceList, setSelectedResourceList] = useState([]);
	const [selectedAll, setSelectedAll] = useState(false);
	const [isMultiDeleteConfirmOpen, setIsMultiDeleteConfirmOpen] = useState(false);

	const filterOptions = [
		{
			type: 'multi_select_check_box',
			id: 'integrations',
			title: 'Integrations',
			options: APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.SERVICE_PILOT],
			results: filterIntegrations,
			setResults: setFilterIntegrations
		},
		{
			type: 'multi_select_check_box',
			id: 'file_type',
			title: 'File Types',
			options: APP_SUPPORT_FILE_TYPES[ROUTES_ID.SERVICE_PILOT],
			results: filterFileType,
			setResults: setFilterFileTypes
		},
		{
			type: 'multi_select_check_box',
			id: 'file_status',
			title: 'File Status',
			options: APP_SUPPORT_FILE_STATUS[ROUTES_ID.DOCUMENT_SEARCH],
			results: filterFileStatus,
			setResults: setFilterFileStatus
		},
		{
			type: 'multi_select_drop_down',
			id: 'user_groups',
			title: 'User Groups',
			options: userGroups,
			results: filterUserGroups,
			setResults: setFilterUserGroups
		}
	]

	const onFilter = () => {
		let results = state.coPilotResourcesByUser;

		if (filterIntegrations.length > 0)
			results = results.filter(res => filterIntegrations.includes(res.integration));

		if (filterFileType.length > 0)
			results = results.filter(res => filterFileType.includes(res.fileName.split('.').pop()));

		if (filterFileStatus.length > 0)
			results = results.filter(res => filterFileStatus.includes(res.status));

		if (filterUserGroups.length > 0) {
			results = results.filter(res =>
				filterUserGroups.find(group => {
					const groupUsers = state.userGroupList[group.id] && state.userGroupList[group.id].users ? state.userGroupList[group.id].users : [];
					return res.user_ids && res.user_ids.some(user_id => groupUsers.findIndex(user => user.user_id === user_id) > -1);
				})
			);
		}

		setFilteredResults(results);
		setFilterApplied((filterIntegrations.length > 0) || (filterFileType.length > 0) || (filterFileStatus.length > 0) || (filterUserGroups.length > 0));
		setShowFilters(false);
	}

	const onClearFilter = () => {
		setFilterIntegrations([]);
		setFilterFileTypes([]);
		setFilterFileStatus([]);
		setFilterUserGroups([]);
		setFilterApplied(false);
	}

	useEffect(() => {
		userGroupHook.getAllUserGroupsWithUsersList();
	}, [])

	useEffect(() => {
		setFilteredResults(state.coPilotResourcesByUser);
		onFilter();
	}, [state.coPilotResourcesByUser])

	useEffect(() => {
		searchFunction(searchKey, filteredResults);
	}, [searchKey, filteredResults])

	useEffect(() => {
		let groups = []
		state.userGroupList && Object.keys(state.userGroupList).map(userGroup => {
			groups.push({ id: userGroup, value: userGroup, label: userGroup });
			return userGroup;
		})
		setUserGroups(groups);
	}, [state.userGroupList])

	const searchFunction = (keyWord, data) => {
		const lowerCaseQuery = keyWord ? keyWord.toLowerCase() : '';
		const results = data.filter(item => item.fileName && item.fileName.toLowerCase().includes(lowerCaseQuery));
		setSearchResult(results);
	}

	const onDelete = () => {
		const user = JSON.parse(Cookies.get('user'));
		coPilotHook.deleteDocuments({ file_info: [{ file_id: selectedResourceData.file_id, integration: selectedResourceData.integration }], user_id: user.user_id, inference_task: "service_pilot" });
		setIsDeleteConfirmOpen(false);
	}

	const onEdit = (data) => {
		setIsOpen(true);
		setSelectedResourceData(data);
	}

	const handleDelete = (data) => {
		setIsDeleteConfirmOpen(true);
		setSelectedResourceData(data);
	}

	const actionComponent = (data) => {
		return (
			<div className="flex gap-4 cursor-default">
				<button onClick={() => ((Object.keys(state.userGroupList).length > 0) && (data.owner_id && data.owner_id.includes(state.user.user_id))) && onEdit(data)} className={`${((Object.keys(state.userGroupList).length > 0) && (data.owner_id && data.owner_id.includes(state.user.user_id))) ? "text-gray-dark dark:text-white cursor-pointer" : "text-gray dark:text-gray cursor-default"} hover:text-blue hover:dark:text-blue font-bold self-center my-2`}>
					<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.4375 5.125C3.64453 5.125 2.93359 4.71484 2.52344 4.03125C2.14062 3.375 2.14062 2.52734 2.52344 1.84375C2.93359 1.1875 3.64453 0.75 4.4375 0.75C5.20312 0.75 5.91406 1.1875 6.32422 1.84375C6.70703 2.52734 6.70703 3.375 6.32422 4.03125C5.91406 4.71484 5.20312 5.125 4.4375 5.125ZM14.5 5.125C13.707 5.125 12.9961 4.71484 12.5859 4.03125C12.2031 3.375 12.2031 2.52734 12.5859 1.84375C12.9961 1.1875 13.707 0.75 14.5 0.75C15.2656 0.75 15.9766 1.1875 16.3867 1.84375C16.7695 2.52734 16.7695 3.375 16.3867 4.03125C15.9766 4.71484 15.2656 5.125 14.5 5.125ZM0.5 8.92578C0.5 7.3125 1.78516 6 3.39844 6H4.57422C5.01172 6 5.42188 6.10938 5.80469 6.27344C5.75 6.46484 5.75 6.68359 5.75 6.875C5.75 7.94141 6.1875 8.87109 6.92578 9.5H6.89844H1.07422C0.746094 9.5 0.5 9.25391 0.5 8.92578ZM11.5742 9.5H11.5469C12.2852 8.87109 12.75 7.94141 12.75 6.875C12.75 6.68359 12.7227 6.46484 12.6953 6.27344C13.0508 6.10938 13.4609 6 13.8984 6H15.0742C16.6875 6 18 7.3125 18 8.92578C18 9.25391 17.7266 9.5 17.3984 9.5H11.5742ZM9.25 5.5625C8.75781 5.5625 8.34766 5.83594 8.10156 6.21875C7.85547 6.62891 7.85547 7.14844 8.10156 7.53125C8.34766 7.94141 8.75781 8.1875 9.25 8.1875C9.71484 8.1875 10.125 7.94141 10.3711 7.53125C10.6172 7.14844 10.6172 6.62891 10.3711 6.21875C10.125 5.83594 9.71484 5.5625 9.25 5.5625ZM9.25 9.5C8.29297 9.5 7.44531 9.00781 6.95312 8.1875C6.48828 7.39453 6.48828 6.38281 6.95312 5.5625C7.44531 4.76953 8.29297 4.25 9.25 4.25C10.1797 4.25 11.0273 4.76953 11.5195 5.5625C11.9844 6.38281 11.9844 7.39453 11.5195 8.1875C11.0273 9.00781 10.1797 9.5 9.25 9.5ZM7.63672 11.6875C6.54297 11.6875 5.64062 12.4531 5.36719 13.4375H13.1055C12.832 12.4531 11.9297 11.6875 10.8359 11.6875H7.63672ZM7.63672 10.375H10.8359C12.8594 10.375 14.5 12.0156 14.5 14.0391C14.5 14.4492 14.1719 14.75 13.7617 14.75H4.71094C4.30078 14.75 4 14.4219 4 14.0391C4 12.0156 5.61328 10.375 7.63672 10.375Z" fill="currentColor" />
					</svg>
				</button>
				<button onClick={() => (data.owner_id && data.owner_id.includes(state.user.user_id)) && handleDelete(data)} className={`${(data.owner_id && data.owner_id.includes(state.user.user_id)) ? "text-gray-dark dark:text-white cursor-pointer" : "text-gray dark:text-gray cursor-default"} hover:text-red-500 hover:dark:text-red-500 font-bold self-center my-2`}>
					<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.33984 2.0625C5.25781 2.0625 5.20312 2.11719 5.14844 2.17188L4.62891 2.9375H8.59375L8.07422 2.17188C8.04688 2.11719 7.96484 2.0625 7.88281 2.0625H5.33984ZM10.1797 2.9375H10.5352H11.875H12.0938C12.4492 2.9375 12.75 3.23828 12.75 3.59375C12.75 3.97656 12.4492 4.25 12.0938 4.25H11.7656L11.1094 13.1367C11.0273 14.0664 10.2891 14.75 9.35938 14.75H3.86328C2.93359 14.75 2.19531 14.0664 2.11328 13.1367L1.45703 4.25H1.15625C0.773438 4.25 0.5 3.97656 0.5 3.59375C0.5 3.23828 0.773438 2.9375 1.15625 2.9375H1.375H2.6875H3.04297L4.05469 1.43359C4.32812 1.02344 4.82031 0.75 5.33984 0.75H7.88281C8.40234 0.75 8.89453 1.02344 9.16797 1.43359L10.1797 2.9375ZM10.4531 4.25H2.76953L3.42578 13.0547C3.45312 13.2734 3.64453 13.4375 3.86328 13.4375H9.35938C9.57812 13.4375 9.76953 13.2734 9.79688 13.0547L10.4531 4.25Z" fill="currentColor" />
					</svg>
				</button>
			</div>
		)
	}

	const closeModal = () => {
		setIsOpen(false);
		setSelectedResourceData({});
	}

	const onMultiDelete = () => {
		let selectedFilePaths = [];
		selectedResourceList.forEach(data => {
			selectedFilePaths.push({
				file_id: data.file_id,
				integration: data.integration
			});
		})
		const user = JSON.parse(Cookies.get('user'));
		coPilotHook.deleteDocuments({ file_info: selectedFilePaths, user_id: user.user_id, inference_task: "service_pilot" });
		setIsMultiDeleteConfirmOpen(false);
		setSelectedAll(false);
		setSelectedResourceList([]);
	}

	const setSelected = (data) => {
		const index = selectedResourceList.indexOf(data);
		if (index > -1)
			selectedResourceList.splice(index, 1);
		else
			selectedResourceList.push(data);
		setSelectedResourceList([...selectedResourceList]);
	}

	const checkboxComponent = (data) => {
		return (
			<CheckBox
				value={selectedResourceList.includes(data)}
				setValue={() => setSelected(data)}
				name={"isSelect"}
				label={''}
				className={'mt-3 mb-3 ml-3 mr-1'}
			/>
		)
	}

	const onSelectAll = () => {
		selectedAll ? setSelectedResourceList([]) : setSelectedResourceList([...filteredResults]);
		setSelectedAll(!selectedAll);
	}

	const selectAllCheckboxComponent = () => {
		return (
			<CheckBox
				value={selectedAll}
				setValue={onSelectAll}
				name={"isSelectAll"}
				disabled={filteredResults && (filteredResults.length === 0)}
				label={''}
				className='mt-1 mb-1 ml-3 mr-4'
			/>
		)
	}

	const columns = [
		{
			id: "CUSTOM_COMPONENT",
			titleComponent: selectAllCheckboxComponent,
			component: checkboxComponent,
			textAlign: 'center',
			flex: 0.05
		},
		{
			id: "fileName",
			title: "Name",
			flex: 0.7
		},
		{
			id: "status",
			title: "Status",
			flex: 0.1,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		},
		{
			id: "integration",
			title: "Integration",
			flex: 0.1,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Action",
			component: actionComponent,
			flex: 0.08,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		}
	]

	useEffect(() => {
		coPilotHook.getDocResourcesByUser();
	}, [])

	const onSave = (request) => {
		coPilotHook.updateDocUserPermission(request);
	}

	const onCloseConfirm = () => {
		setIsDeleteConfirmOpen(false);
		setIsMultiDeleteConfirmOpen(false);
	}

	return (
		<>
			<div className="w-full manage-doc-content-container">
				<main className="px-6">
					<div className='flex flex-row items-center justify-between mb-4'>
						<div className='flex flex-row items-center'>
							<h2 className='font-semibold text-xl text-gray-dark dark:text-white'>Document List</h2>
							<TooltipWrapper content={"Uploaded files list"} tootTipStyle={'w-max mt-[15px]'} autoHide={false} isShowArrow={true}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</div>
						<div className='flex flex-row items-center gap-[10px]'>
							{selectedResourceList && selectedResourceList.length > 0 &&
								<Button name={"Delete"} handleFunction={() => setIsMultiDeleteConfirmOpen(true)} className={'flex items-center w-24 h-[47px] bg-red-medium hover:bg-red hover:dark:bg-red dark:bg-red-medium border-red-medium dark:border-red-medium hover:border-red hover:dark:border-red dark:text-white'} />
							}
							<div>
								<InputWrapper containerStyle={"w-96"} value={searchKey} setValue={setSearchKey} label={"Search Documents"} name={"searchKey"} type={"text"}
									rightIcon={
										<div className="text-white-gray-dark-400 dark:text-gray">
											<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M11.5 6.5C11.5 4.71875 10.5312 3.09375 9 2.1875C7.4375 1.28125 5.53125 1.28125 4 2.1875C2.4375 3.09375 1.5 4.71875 1.5 6.5C1.5 8.3125 2.4375 9.9375 4 10.8438C5.53125 11.75 7.4375 11.75 9 10.8438C10.5312 9.9375 11.5 8.3125 11.5 6.5ZM10.5312 11.625C9.40625 12.5 8 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.03125 12.4688 9.4375 11.5938 10.5625L15.7812 14.7188C16.0625 15.0312 16.0625 15.5 15.7812 15.7812C15.4688 16.0938 15 16.0938 14.7188 15.7812L10.5312 11.625Z" fill="currentColor" />
											</svg>
										</div>
									}
								/>
							</div>
							<button onClick={() => setShowFilters(true)} className={`flex p-[14px] h-11 border-2 rounded-lg border-white-gray-dark-400 dark:border-gray ${fliterApplied ? 'text-blue dark:text-blue' : 'text-gray-dark dark:text-white'} cursor-pointer hover:text-blue hover:dark:text-blue hover:bg-white-gray-dark-400 hover:dark:bg-gray`}>
								<svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.5 1.75C0.5 1.0625 1.03125 0.5 1.71875 0.5H15.25C15.9375 0.5 16.5 1.0625 16.5 1.75C16.5 2.03125 16.375 2.3125 16.1875 2.53125L10.5 9.4375V13.4688C10.5 14.0312 10 14.5 9.4375 14.5C9.1875 14.5 8.96875 14.4375 8.78125 14.2812L6.875 12.8125C6.625 12.625 6.46875 12.3438 6.46875 12.0312V9.4375L0.78125 2.53125C0.59375 2.3125 0.5 2.03125 0.5 1.75ZM1.71875 1.5C1.59375 1.5 1.5 1.625 1.5 1.75C1.5 1.8125 1.5 1.84375 1.53125 1.90625L7.375 8.9375C7.4375 9.03125 7.5 9.15625 7.5 9.25V12.0312L9.40625 13.5C9.40625 13.5 9.40625 13.5 9.4375 13.5C9.46875 13.5 9.5 13.5 9.5 13.4688V9.25C9.5 9.15625 9.53125 9.03125 9.59375 8.9375L15.4375 1.90625C15.4688 1.84375 15.5 1.8125 15.5 1.75C15.5 1.625 15.375 1.5 15.25 1.5H1.71875Z" fill="currentColor" />
								</svg>
							</button>
						</div>
					</div>
					<Table columns={columns} data={searchKey ? searchResult : filteredResults} className={'manage-documents-content-container'} />
					<UserGroupAccessModal id={ROUTES_ID.SERVICE_PILOT} field_id={'file_id'} isOpen={isOpen} onClose={closeModal} onSave={onSave} data={selectedResourceData} userGroupList={state.userGroupList} />
				</main>
			</div>
			<BottomBar
				ButtonList={[]}
				Component={
					<div className='flex gap-4 w-max'>
						<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} folder={true} path={"co_pilot"} buttonType={'fill'} className={'w-max'} />
						<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} path={"co_pilot"} buttonType={'fill'} className={'w-max'} />
					</div>
				}
			/>
			<ConfirmPopup id={'sp_multi_delete_popup_div'} title={`Are you sure you want to delete ${selectedResourceList.length} file${selectedResourceList.length > 1 ? 's' : ''}?`} confirmLabel={"Delete"} isOpen={isMultiDeleteConfirmOpen} onConfirm={onMultiDelete} onClose={onCloseConfirm} />
			<ConfirmPopup id={'sp_delete_popup_div'} title={"Confirm Delete"} message={"Are you sure you want to delete this file?"} confirmLabel={"Delete"} isOpen={isDeleteConfirmOpen} onConfirm={onDelete} onClose={onCloseConfirm} />
			<FilterPopup id={'service_pilot_filter_popup'} title={'Filter Documents'} isOpen={showFilters} filterOptions={filterOptions} onFilter={onFilter} onClearFilter={onClearFilter} onClose={() => setShowFilters(false)} />
		</>
	)
}

export default ServicePilotManageDocuments;
