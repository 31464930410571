import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react"
import { InputWrapper } from '../components/InputWrapper';
import { useStore } from '../store/AppContext';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router';
import { Button } from '../components/Button';
import packageJson from '../../package.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useConfig } from '../hooks/useConfig';

const Login = () => {
	const authHook = useAuth();
	const navigate = useNavigate();
	const configHook = useConfig();
	const { state, dispatch } = useStore();
	const { loginWithRedirect, user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [viewPassword, setViewPassword] = useState('');
	const [error, setError] = useState('');

	const getAuth0UserData = async () => {
		const token = await getAccessTokenSilently({ detailedResponse: true });
		authHook.saveAuth0User(user, token.id_token);
	}
	
	useEffect(() => {
        configHook.getConfig('AUTH');
    }, [])

	useEffect(() => {
		if ((state.authConfig.type === "auth0") && isAuthenticated && user)
			getAuth0UserData();
	}, [isAuthenticated, user])

	useEffect(() => {
		if(state.isLoggedIn && state.isLicenseKeyFetched){
			console.log(JSON.stringify(state.licenseInfo))
			if (!state.licenseKey || !state.licenseInfo)
				username === 'super_admin' ? navigate('/subscription') : navigate('/renew-subscription')
			else {
				if(state.licenseInfo?.remainingDays === 0 || !state.licenseInfo?.valid)
					navigate('/renew-subscription')
				else if (state.licenseInfo?.remainingDays > 0 && state.licenseInfo?.valid)
					navigate((username === 'super_admin') && (!state.authConfig || (state.authConfig && !state.authConfig.type)) ? '/auth-config' : '/dashboard');
			}
		}
	}, [state.isLoggedIn, state.authConfig, state.isLicenseKeyFetched])

	const handleLogin = () => {
		setError(null);
		if ([null, undefined, ''].includes(username))
			setError('Username is required');
		else if ([null, undefined, ''].includes(password))
			setError('Password is required');
		else if (username === 'super_admin')
			authHook.login(username, password, 'super_admin');
		else if (![null, undefined, ''].includes([state.authConfig.type]))
			authHook.login(username, password, state.authConfig.type);
		else
			setError('Authentication is not configured!');
	}

	const toggleTheme = () => {
		dispatch({
			type: `TOGGLE_THEME`,
		})
	}

	const onEnterKey = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleLogin();
		}
	}

	const auth0Login = () => {
		setTimeout(async () => {
			await loginWithRedirect({
				authorizationParams: {
					version_number: packageJson.version,
					is_dark_mode: state.isDarkMode
				}
			});
		}, 500);
	}

	return (
		<div className="min-h-screen flex justify-center bg-white dark:bg-gray-dark">
			{state.authConfig.type === "auth0" ?
				(isLoading ?
					<div />
					:
					(isAuthenticated ?
						<div />
						:
						auth0Login()
					)
				)
				:
				<div className='w-full max-w-lg'>
					<div className='absolute flex flex-row mt-3 right-3 items-center'>
						<p className={`${state.isDarkMode ? "text-black dark:text-white font-bold" : "text-gray-light"} text-sm text-center mr-3`}>Dark Mode</p>
						<label className="relative inline-flex items-center cursor-pointer">
							<input type="checkbox" checked={!state.isDarkMode} onChange={toggleTheme} className="sr-only peer" />
							<div className="w-12 h-7 bg-blue peer-focus:outline-none rounded-full peer dark:bg-blue peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-dark-300"></div>
						</label>
						<p className={`${!state.isDarkMode ? "text-black dark:text-white font-bold" : "text-gray-light"} text-sm text-center ml-3`}>Light Mode</p>
					</div>
					<div className="w-full h-full flex flex-col relative justify-center">
						<div className="mb-8">
							<div className='flex flex-col items-center justify-center px-6'>
								<img src={state.isDarkMode ? "/assets/images/logo-generate-enterprise-stack-dark-mode.svg" : "/assets/images/logo-generate-enterprise-stack-light-mode.svg"} alt="interplay-logo" className='w-44 mb-0' />
								<p className='w-44 mt-2 text-center text-xs font-semibold text-black dark:text-white'>v{packageJson.version}</p>
							</div>
						</div>
						<form>
							<InputWrapper value={username} onEnterKey={onEnterKey} setValue={setUsername} label={"Username"} name={"email"} type={"text"} containerStyle={'mb-9'} />
							<InputWrapper value={password} onEnterKey={onEnterKey} setValue={setPassword} label={"Password"} name={"password"}  type={viewPassword ? "text" : "password"} rightIcon={password && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} containerStyle={'mb-9'} />
							<Button className={"w-full"} handleFunction={handleLogin} name={"Log In"} />
							{error && <p className='text-red text-center mt-6'>{error}</p>}
						</form>
					</div>
				</div>
			}
		</div>
	)
}

export default Login;
