import React, { useEffect, useState } from 'react';
import { useStore } from '../store/AppContext';

const Notification = () => {
	const { state, dispatch } = useStore();
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		state.showMessage && state.message && state.message.type && state.message.title && setIsVisible(true);

		(state.message.close !== "manual") && setTimeout(() => {
			setIsVisible(false);
			dispatch({
				type: "RESET_MESSAGE"
			})
		}, 10000);
	}, [state.showMessage])

	const handleClose = () => {
		setIsVisible(false);
		dispatch({
			type: "RESET_MESSAGE"
		})
	}

	return (
		<div className={`fixed top-4 right-4 w-4/12 p-4 rounded-lg bg-white-gray-500 dark:bg-gray-black text-gray-dark dark:text-white ${isVisible ? 'block' : 'hidden'}`}>
			<div className='flex items-start justify-between relative'>
				{state.message && state.message.type &&
					<img src={(state.message.type === 'success') ? "/assets/images/notification-green.svg" : "/assets/images/notification-red.svg"} className="w-[36px h-[36px]" alt="" />
				}
				<div className='w-full pl-4 pr-2'>
					<p className=''>{state.message ? state.message.title : ""}</p>
					<p className='text-gray'>
						{state.message && state.message.subtitle &&
							state.message.subtitle.split('\n').map((sentence, index) => (
								<React.Fragment key={index}>
									{sentence}
									<br />
								</React.Fragment>
							))
						}
					</p>
				</div>
				<button className="w-5 font-black text-2xl" onClick={handleClose}>
					<img src="/assets/images/close.svg" alt="" />
				</button>
			</div>
		</div>
	)
}

export default Notification;
