import React, { useEffect } from 'react';
import Select from "react-select";
import clsx from "clsx";
import { TextButton } from './Button';
import { CheckBox } from './CheckBox';

const FilterPopup = ({ id, isOpen, filterOptions, onClose, onFilter, onClearFilter, title, cancelLabel, confirmLabel }) => {
	const divId = id ? id : 'filter_popup_div';

	useEffect(() => {
		const popupDiv = document.getElementById(divId);
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	const onCheckboxChange = (filterOption, option) => {
		const index = filterOption.results.indexOf(option.id);
		if (index > -1) {
			let res = filterOption.results;
			res.splice(index, 1);
			filterOption.setResults([...res]);
		} else {
			filterOption.setResults([...filterOption.results, option.id]);
		}
	}

	const handleClose = () => {
		onClose();
	}

	const styles = {
		control: (css, { isDisabled }) => ({ ...css, cursor: isDisabled ? 'default' : 'pointer' }),
		option: (css, { isDisabled, isFocused }) => ({ ...css, cursor: isDisabled ? 'default' : 'pointer', backgroundColor: isFocused ? 'bg-white-gray-500 dark:bg-gray-dark-500' : 'bg-white-600 dark:bg-gray-black' }),
	}

	return (
		<div id={divId} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white-500 dark:bg-gray-dark w-1/2 drop-shadow-xl rounded-3xl z-10`}>
				<div className='font-light'>
					<p className='flex w-full justify-center text-2xl font-bold dark:text-white px-9 py-6'>{title}</p>
					<div className='px-9 pb-9 flex flex-col gap-6'>
						{filterOptions && filterOptions.map((filterOption, index) => {
							return (
								<div key={index}>
									{(filterOption.type === 'multi_select_check_box') &&
										<div>
											<p className='text-gray-dark dark:text-white font-bold pb-3'>{filterOption.title}</p>
											<div className='gap-6 w-full flex flex-wrap'>
												{filterOption.options.map((option, index) => {
													return (
														<div key={index} onClick={() => onCheckboxChange(filterOption, option)} className='flex items-center gap-2 cursor-pointer font-medium'>
															<CheckBox
																value={filterOption.results.includes(option.id)}
																id={option.id}
																name={option.id}
																label={option.label}
																className={'my-[0px]'}
																labelClassName={option.labelClassName ? option.labelClassName : ''}
															/>
														</div>
													)
												})}
											</div>
										</div>
									}
									{(filterOption.type === 'multi_select_drop_down') &&
										<div>
											<p className='text-gray-dark dark:text-white font-bold pb-3'>{filterOption.title}</p>
											<Select
												value={filterOption.results}
												onChange={filterOption.setResults}
												closeMenuOnSelect={false}
												isMulti={true}
												options={filterOption.options}
												maxMenuHeight={120}
												styles={styles}
												classNames={{
													control: ({ isFocused }) =>
														clsx(
															isFocused ? "border-primary-600 ring-1 ring-primary-500 bg-transparent dark:bg-transparent" : "border-gray-300 hover:border-primary-400 bg-transparent dark:bg-transparent",
															"p-[12px] py-[8px] border rounded-xl cursor-pointer bg-transparent dark:bg-transparent",
														),
													valueContainer: () => "cursor-pointer p-1 gap-2",
													input: () => 'dark:text-white',
													multiValue: () => 'text-gray-dark dark:text-gray-dark dark:bg-gray-900',
													multiValueLabel: () => 'text-gray-dark dark:text-gray-dark dark:bg-gray-900',
													multiValueRemove: () => 'text-gray-dark dark:text-gray-dark dark:bg-gray-900	',
													noOptionsMessage: () => "font-medium text-gray dark:bg-gray bg-white-600 dark:bg-gray-black",
													menu: () => 'bg-white-600 dark:bg-gray-black p-2 rounded-xl',
													menuList: () => `text-gray-dark dark:text-white bg-white-600 dark:bg-gray-black`,
													option: ({ isFocused }) => isFocused ? 'bg-white-gray-500 dark:bg-gray-dark-500' : 'hover:bg-white-gray-500 hover:dark:bg-gray-dark-500',
												}}
											/>
										</div>
									}
								</div>
							)
						})}
					</div>
					<div className="flex py-6 px-9 w-full items-center justify-between border-t border-white-gray-dark-400 dark:border-gray">
						<TextButton addHandleFunction={onClearFilter} label={"Clear Filters"} labelClassName={'hover:border-b-2 hover:border-blue hover:-mb-[2px]'} />
						<div className='flex items-center gap-6'>
							<button onClick={handleClose} className={`flex items-center h-11 border border-blue font-bold px-6 py-3 rounded-lg text-blue dark:text-blue hover:border-blue hover:bg-blue hover:text-white hover:dark:text-white`} >{cancelLabel || "Cancel"}</button>
							<button onClick={onFilter} className={`flex items-center h-11 border font-bold px-6 py-3 rounded-lg bg-blue border-blue text-white dark:text-white focus:outline-none hover:bg-blue-400 hover:border-blue-400`} >{confirmLabel || "Filter"}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FilterPopup;