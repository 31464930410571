import React, { useEffect, useState } from 'react';
import { InputRangeWrapper, InputWrapper2 } from './InputWrapper';
import DropdownWrapper from './DropdownWrapper';
import { TextButton } from './Button';
import { ROUTES_ID } from '../config';

const SelectModel = ({ appId, index, models, data, onDataChange, canDelete, onDelete }) => {
    const [selectedModel, setSelectedModel] = useState(data.model);
    const [tempature, setTempature] = useState(data.tempature ? data.tempature : '0.8');
    const [instruction, setInstruction] = useState(data.instruction);
    const [showInstruction, setShowInstruction] = useState(false);

    useEffect(() => {
        onDataChange(index, 'model', selectedModel);
    }, [selectedModel])

    useEffect(() => {
        onDataChange(index, 'tempature', tempature);
    }, [tempature])

    useEffect(() => {
        onDataChange(index, 'instruction', instruction);
    }, [instruction])

    return (
        <div key={index} className='w-full'>
            <div className='flex flex-row w-full items-center justify-between gap-3'>
                <DropdownWrapper setValue={setSelectedModel} value={data.model ? data.model.label : ''} placeholder={'LLM Name'} name={'model'} options={models} optionsClassName={'w-max'} />
                <InputRangeWrapper value={tempature || (tempature === 0) ? parseFloat(tempature) * 100 : 80} setValue={setTempature} label={"LLMs' Creativity (0 - 1)"} rangeSpanData={[0, 1]} name={"tempature"} containerStyle={'w-full font-bold'} />
                {canDelete &&
                    <button onClick={() => onDelete(index)} className='text-gray-dark dark:text-white hover:text-red-medium hover:dark:text-red-medium'>
                        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.22266 2.4375C6.11719 2.4375 6.04688 2.50781 5.97656 2.57812L5.30859 3.5625H10.4062L9.73828 2.57812C9.70312 2.50781 9.59766 2.4375 9.49219 2.4375H6.22266ZM12.4453 3.5625H12.9023H14.625H14.9062C15.3633 3.5625 15.75 3.94922 15.75 4.40625C15.75 4.89844 15.3633 5.25 14.9062 5.25H14.4844L13.6406 16.6758C13.5352 17.8711 12.5859 18.75 11.3906 18.75H4.32422C3.12891 18.75 2.17969 17.8711 2.07422 16.6758L1.23047 5.25H0.84375C0.351562 5.25 0 4.89844 0 4.40625C0 3.94922 0.351562 3.5625 0.84375 3.5625H1.125H2.8125H3.26953L4.57031 1.62891C4.92188 1.10156 5.55469 0.75 6.22266 0.75H9.49219C10.1602 0.75 10.793 1.10156 11.1445 1.62891L12.4453 3.5625ZM12.7969 5.25H2.91797L3.76172 16.5703C3.79688 16.8516 4.04297 17.0625 4.32422 17.0625H11.3906C11.6719 17.0625 11.918 16.8516 11.9531 16.5703L12.7969 5.25Z" fill="currentColor" />
                        </svg>
                    </button>
                }
            </div>
            {data.model && data.model.id && (appId !== ROUTES_ID.DATABASE_SEARCH) &&
                <div className='mt-3'>
                    {showInstruction ?
                        <>
                            <InputWrapper2 value={data.instruction ? data.instruction : ''} setValue={setInstruction} name={"dbInstructions"} type={"textarea"} placeholder={`Enter instructions here...`} rows={5} />
                            <p className='text-xs text-gray ml-2 mt-1'>Provide instructions to help guide the LLM in generating responses that meet your requirements</p>
                        </>
                        :
                        <TextButton
                            addHandleFunction={() => setShowInstruction(true)} label={"Add Instructions"}
                            labelClassName={'text-gray-dark dark:text-white hover:text-gray-dark hover:dark:text-white font-semibold hover:border-b-blue hover:dark:border-b-blue'}
                            icon={
                                <svg width="14" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="currentColor" />
                                </svg>
                            }
                        />
                    }
                </div>
            }
        </div>
    )
}

export default SelectModel;