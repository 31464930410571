import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/AppContext';
import { useNavigate } from 'react-router';
import { Button } from '../../components/Button';
import { InputWrapper } from '../../components/InputWrapper';
import { LICENSE_KEY } from '../../config/index';
import { useConfig } from '../../hooks/useConfig';
import Topbar from '../../components/Home/Topbar';

const Subscription = () => {
    const navigate = useNavigate();
    const configHook = useConfig();
    const { state } = useStore();
    const [licenseKey, setLicenseKey] = useState(null);
    const [validKey, setValidKey] = useState(null);

    const RIGHT_ICON = <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7812 0.469238C15.0625 0.781738 15.0625 1.25049 14.7812 1.53174L6.53125 9.78174C6.21875 10.0942 5.75 10.0942 5.46875 9.78174L1.21875 5.53174C0.90625 5.25049 0.90625 4.78174 1.21875 4.50049C1.5 4.18799 1.96875 4.18799 2.25 4.50049L5.96875 8.21924L13.7188 0.469238C14 0.187988 14.4688 0.187988 14.75 0.469238H14.7812Z" fill="#2BB86C" />
    </svg>;

    const WRONG_ICON = <button onClick={() => setLicenseKey(null)}>
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7812 1.53174L7.03125 5.28174L10.75 9.00049C11.0625 9.28174 11.0625 9.75049 10.75 10.0317C10.4688 10.3442 10 10.3442 9.71875 10.0317L5.96875 6.31299L2.25 10.0317C1.96875 10.3442 1.5 10.3442 1.21875 10.0317C0.90625 9.75049 0.90625 9.28174 1.21875 8.96924L4.9375 5.25049L1.21875 1.53174C0.90625 1.25049 0.90625 0.781738 1.21875 0.469238C1.5 0.187988 1.96875 0.187988 2.28125 0.469238L6 4.21924L9.71875 0.500488C10 0.187988 10.4688 0.187988 10.7812 0.500488C11.0625 0.781738 11.0625 1.25049 10.7812 1.53174Z" fill="#E45051" />
        </svg>
    </button>

    useEffect(() => {
        setValidKey(licenseKey ? (licenseKey.match(/^\d+-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/) ? 'valid' : 'invalid') : null);
    }, [licenseKey])

    const onEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubscription();
        }
    }

    const onSubscription = () => {
        configHook.updateConfig(LICENSE_KEY, { licenseKey: licenseKey }, true, navigate, '/doc-search/app')
    }

    return (
        <div className="flex flex-col relative h-screen items-center align-middle bg-white dark:bg-gray-dark">
            <div className='flex absolute h-full justify-center items-center'>
                <div className="flex h-full justify-between items-center bg-white dark:bg-gray-dark gap-20 px-60">
                    <div className='flex flex-col w-full items-center justify-center gap-10 '>
                        <img src={state.isDarkMode ? "/assets/images/logo-generate-enterprise-stack-dark-mode.svg" : "/assets/images/logo-generate-enterprise-stack-light-mode.svg"} alt="interplay-logo" />
                        <InputWrapper value={licenseKey || ''} validValue={validKey} rightIcon={validKey ? ((validKey === 'valid') ? RIGHT_ICON : WRONG_ICON) : null} onEnterKey={onEnterKey} setValue={setLicenseKey} label={"Enter License key"} name={"licenseKey"} type={"text"} containerStyle={'w-full'} />
                        <Button disabled={validKey !== 'valid'} className={"w-full py-[8px]"} handleFunction={onSubscription} name={"Activate"} />
                    </div>
                    <div className='flex w-full bg-white-gray dark:bg-gray-medium-400 rounded-2xl'>
                        <img src='/assets/images/generate-enterprise-branding.png' height={50} width={"100%"} style={{ borderRadius: 16 }} alt="img" />
                    </div>
                </div>
            </div>
            <Topbar />
        </div>
    )
}

export default Subscription;