import React, { useEffect, useState } from 'react';
import { useStore } from '../store/AppContext';
import { APP_ROUTE_PATHS, APP_SUPPORT_MODEL_TYPES, ROUTES_ID } from '../config';
import BottomBar from "./Home/BottomBar";
import ConfirmPopup from "./ConfirmPopup";
import { CheckBox } from "./CheckBox";
import { Button } from "./Button";
import TooltipWrapper from "./TooltipWrapper";
import Table from './Table';
import { InputWrapper } from './InputWrapper';
import FilterPopup from './FilterPopup';
import { useNavigate } from 'react-router-dom';
import { useLLMManager } from '../hooks/useLLMManager';

const LLMManager = ({ appId }) => {
	const navigate = useNavigate();
	const llmManagerHook = useLLMManager();
	const { state } = useStore();
	const [searchKey, setSearchKey] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [fliterApplied, setFilterApplied] = useState(false);
	const [filterModelType, setFilterModelType] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [selectedLLM, setSelectedLLM] = useState({});
	const [selectedLLMList, setSelectedLLMList] = useState([]);
	const [selectedAll, setSelectedAll] = useState(false);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [isMultiDeleteConfirmOpen, setIsMultiDeleteConfirmOpen] = useState(false);

	const filterOptions = [
		{
			type: 'multi_select_check_box',
			id: 'model_type',
			title: 'LLM Types',
			options: APP_SUPPORT_MODEL_TYPES[appId],
			results: filterModelType,
			setResults: setFilterModelType
		}
	]

	useEffect(() => {
		let llmModelsList = state.llmModels ? state.llmModels : [];
		llmModelsList = llmModelsList.filter(llmModel => llmModel.app_id === appId);
		setFilteredResults(llmModelsList);
	}, [state.llmModels])

	useEffect(() => {
		searchFunction(searchKey, filteredResults);
	}, [searchKey, filteredResults])

	const onFilter = () => {
		let results = state.llmModels;

		if (filterModelType.length > 0)
			results = results.filter(res => filterModelType.includes(res.model_type_id));

		setFilteredResults(results);
		setFilterApplied(filterModelType.length > 0);
		setShowFilters(false);
	}

	const onClearFilter = () => {
		setFilterModelType([]);
		setFilterApplied(false);
	}

	const searchFunction = (keyWord, data) => {
		const lowerCaseQuery = keyWord ? keyWord.toLowerCase() : '';
		const results = data.filter(item => item.model_name.toLowerCase().includes(lowerCaseQuery));
		setSearchResult(results);
	}

	const onDelete = () => {
		llmManagerHook.deleteModels([{ appId: appId, modelId: selectedLLM.model_id }]);
		setIsDeleteConfirmOpen(false);
	}

	const onEdit = (data) => {
		navigate(`${APP_ROUTE_PATHS[appId]}/edit-llm`, { state: { modelData: data } });
	}

	const handleDelete = (data) => {
		setIsDeleteConfirmOpen(true);
		setSelectedLLM(data);
	}

	const onMultiDelete = () => {
		let selectedLLMs = [];
		selectedLLMList.forEach(data => {
			selectedLLMs.push({
				appId: appId,
				modelId: data.model_id
			});
		})
		llmManagerHook.deleteModels(selectedLLMs);
		setIsMultiDeleteConfirmOpen(false);
		setSelectedAll(false);
		setSelectedLLMList([]);
	}

	const setSelected = (data) => {
		const index = selectedLLMList.indexOf(data);
		if (index > -1)
			selectedLLMList.splice(index, 1);
		else
			selectedLLMList.push(data);
		setSelectedLLMList([...selectedLLMList]);
	}

	const onSelectAll = () => {
		selectedAll ? setSelectedLLMList([]) : setSelectedLLMList([...filteredResults]);
		setSelectedAll(!selectedAll);
	}

	const onNewModel = () => {
		navigate(`${APP_ROUTE_PATHS[appId]}/new-llm`);
	}

	const onCloseConfirm = () => {
		setIsDeleteConfirmOpen(false)
		setIsMultiDeleteConfirmOpen(false);
	}

	const actionComponent = (data) => {
		return (
			<div className="flex gap-4 cursor-default">
				<button onClick={() => onEdit(data)} className={`text-gray-dark dark:text-white cursor-pointer font-bold self-center my-2`}>
					<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.5586 2.36328H12.5312C12.2852 2.11719 11.875 2.11719 11.6289 2.36328L10.8086 3.15625L12.0938 4.44141L12.8867 3.62109C13.1328 3.375 13.1328 2.96484 12.8867 2.69141L12.5586 2.36328ZM6.21484 7.77734C6.13281 7.83203 6.07812 7.94141 6.05078 8.05078L5.58594 9.63672L7.19922 9.19922C7.30859 9.17188 7.39062 9.11719 7.47266 9.03516L11.1641 5.34375L9.90625 4.08594L6.21484 7.77734ZM10.6992 1.43359C11.4648 0.667969 12.6953 0.667969 13.4609 1.43359L13.8164 1.78906C14.582 2.55469 14.582 3.78516 13.8164 4.55078L8.40234 9.96484C8.18359 10.1836 7.88281 10.3477 7.55469 10.457L4.82031 11.2227C4.60156 11.3047 4.35547 11.25 4.19141 11.0586C4 10.8945 3.94531 10.6484 4 10.4297L4.79297 7.69531C4.875 7.36719 5.06641 7.06641 5.28516 6.84766L10.6992 1.43359ZM2.90625 2.5H5.96875C6.32422 2.5 6.625 2.80078 6.625 3.15625C6.625 3.53906 6.32422 3.8125 5.96875 3.8125H2.90625C2.27734 3.8125 1.8125 4.30469 1.8125 4.90625V12.3438C1.8125 12.9727 2.27734 13.4375 2.90625 13.4375H10.3438C10.9453 13.4375 11.4375 12.9727 11.4375 12.3438V9.28125C11.4375 8.92578 11.7109 8.625 12.0938 8.625C12.4492 8.625 12.75 8.92578 12.75 9.28125V12.3438C12.75 13.6836 11.6562 14.75 10.3438 14.75H2.90625C1.56641 14.75 0.5 13.6836 0.5 12.3438V4.90625C0.5 3.59375 1.56641 2.5 2.90625 2.5Z" fill="currentColor" />
					</svg>
				</button>
				<button onClick={() => handleDelete(data)} className={`text-gray-dark dark:text-white cursor-pointer font-bold self-center my-2`}>
					<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.33984 2.0625C5.25781 2.0625 5.20312 2.11719 5.14844 2.17188L4.62891 2.9375H8.59375L8.07422 2.17188C8.04688 2.11719 7.96484 2.0625 7.88281 2.0625H5.33984ZM10.1797 2.9375H10.5352H11.875H12.0938C12.4492 2.9375 12.75 3.23828 12.75 3.59375C12.75 3.97656 12.4492 4.25 12.0938 4.25H11.7656L11.1094 13.1367C11.0273 14.0664 10.2891 14.75 9.35938 14.75H3.86328C2.93359 14.75 2.19531 14.0664 2.11328 13.1367L1.45703 4.25H1.15625C0.773438 4.25 0.5 3.97656 0.5 3.59375C0.5 3.23828 0.773438 2.9375 1.15625 2.9375H1.375H2.6875H3.04297L4.05469 1.43359C4.32812 1.02344 4.82031 0.75 5.33984 0.75H7.88281C8.40234 0.75 8.89453 1.02344 9.16797 1.43359L10.1797 2.9375ZM10.4531 4.25H2.76953L3.42578 13.0547C3.45312 13.2734 3.64453 13.4375 3.86328 13.4375H9.35938C9.57812 13.4375 9.76953 13.2734 9.79688 13.0547L10.4531 4.25Z" fill="currentColor" />
					</svg>
				</button>
			</div>
		)
	}

	const selectAllCheckboxComponent = () => {
		return (
			<CheckBox
				value={selectedAll}
				setValue={onSelectAll}
				name={"isSelectAll"}
				disabled={filteredResults.length === 0}
				label={''}
				className='mt-1 mb-1 ml-3 mr-4'
			/>
		)
	}

	const checkboxComponent = (data) => {
		return (
			<CheckBox
				value={selectedLLMList.includes(data)}
				setValue={() => setSelected(data)}
				name={"isSelect"}
				label={''}
				className={'mt-3 mb-3 ml-3 mr-1'}
			/>
		)
	}

	const columns = [
		{
			id: "CUSTOM_COMPONENT",
			titleComponent: selectAllCheckboxComponent,
			component: checkboxComponent,
			textAlign: 'center',
			flex: 0.05
		},
		{
			id: "model_name",
			title: "LLM Name",
			flex: 0.2
		},
		{
			id: "model_type_name",
			title: "LLM Type",
			flex: 0.1
		},
		{
			id: "tempature",
			title: "LLM's Creativity",
			flex: (appId !== ROUTES_ID.DATABASE_SEARCH) ? 0.15 : 0.65
		},
		(appId !== ROUTES_ID.DATABASE_SEARCH ?
			{
				id: "custom_instruction",
				title: "Custom Instruction",
				flex: 0.5
			}
			:
			null
		),
		{
			id: "CUSTOM_COMPONENT",
			title: "Action",
			component: actionComponent,
			flex: 0.08,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		}
	]

	return (
		<>
			<div className="w-full manage-doc-content-container">
				<main className="px-6">
					<div className='flex flex-row items-center justify-between mb-4'>
						<div className='flex flex-row items-center'>
							<h2 className='font-semibold text-xl text-gray-dark dark:text-white pr-1'>LLMs List</h2>
							<TooltipWrapper content={"Connected LLMs list"} tootTipStyle={'w-max mt-[15px]'} autoHide={false} isShowArrow={true}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</div>
						<div className='flex flex-row items-center gap-[10px]'>
							{selectedLLMList && selectedLLMList.length > 0 &&
								<Button name={"Remove"} handleFunction={() => setIsMultiDeleteConfirmOpen(true)} className={'flex items-center w-max h-[47px] bg-red-medium hover:bg-red hover:dark:bg-red dark:bg-red-medium border-red-medium dark:border-red-medium hover:border-red hover:dark:border-red dark:text-white'} />
							}
							<div>
								<InputWrapper containerStyle={"w-96"} value={searchKey} setValue={setSearchKey} label={"Search LLMs"} name={"searchKey"} type={"text"}
									rightIcon={
										<div className="text-white-gray-dark-400 dark:text-gray">
											<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M11.5 6.5C11.5 4.71875 10.5312 3.09375 9 2.1875C7.4375 1.28125 5.53125 1.28125 4 2.1875C2.4375 3.09375 1.5 4.71875 1.5 6.5C1.5 8.3125 2.4375 9.9375 4 10.8438C5.53125 11.75 7.4375 11.75 9 10.8438C10.5312 9.9375 11.5 8.3125 11.5 6.5ZM10.5312 11.625C9.40625 12.5 8 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.03125 12.4688 9.4375 11.5938 10.5625L15.7812 14.7188C16.0625 15.0312 16.0625 15.5 15.7812 15.7812C15.4688 16.0938 15 16.0938 14.7188 15.7812L10.5312 11.625Z" fill="currentColor" />
											</svg>
										</div>
									}
								/>
							</div>
							<button onClick={() => setShowFilters(true)} className={`flex h-11 p-[14px] border-2 rounded-lg border-white-gray-dark-400 dark:border-gray ${fliterApplied ? 'text-blue dark:text-blue' : 'text-gray-dark dark:text-white'} cursor-pointer hover:text-blue hover:dark:text-blue hover:bg-white-gray-dark-400 hover:dark:bg-gray`}>
								<svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.5 1.75C0.5 1.0625 1.03125 0.5 1.71875 0.5H15.25C15.9375 0.5 16.5 1.0625 16.5 1.75C16.5 2.03125 16.375 2.3125 16.1875 2.53125L10.5 9.4375V13.4688C10.5 14.0312 10 14.5 9.4375 14.5C9.1875 14.5 8.96875 14.4375 8.78125 14.2812L6.875 12.8125C6.625 12.625 6.46875 12.3438 6.46875 12.0312V9.4375L0.78125 2.53125C0.59375 2.3125 0.5 2.03125 0.5 1.75ZM1.71875 1.5C1.59375 1.5 1.5 1.625 1.5 1.75C1.5 1.8125 1.5 1.84375 1.53125 1.90625L7.375 8.9375C7.4375 9.03125 7.5 9.15625 7.5 9.25V12.0312L9.40625 13.5C9.40625 13.5 9.40625 13.5 9.4375 13.5C9.46875 13.5 9.5 13.5 9.5 13.4688V9.25C9.5 9.15625 9.53125 9.03125 9.59375 8.9375L15.4375 1.90625C15.4688 1.84375 15.5 1.8125 15.5 1.75C15.5 1.625 15.375 1.5 15.25 1.5H1.71875Z" fill="currentColor" />
								</svg>
							</button>
						</div>
					</div>
					<Table columns={columns.filter(column => column !== null)} data={searchKey ? searchResult : filteredResults} className={'manage-documents-content-container'} emptyResultMessage={searchKey ? 'No Search results found' : 'No LLMs connected!'} />
				</main>
			</div>
			<BottomBar
				ButtonList={[]}
				Component={
					<button onClick={onNewModel} className={`flex h-11 gap-2 justify-center items-center bg-blue round border-none text-white hover:text-white hover:bg-blue-300 font-bold px-6 py-[10px] rounded-lg disabled:bg-gray`}>
						<p className='font-bold text-white'>New LLMs</p>
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z" fill="white" />
						</svg>
					</button>
				}
			/>
			<ConfirmPopup id={'llm_multi_remove_popup_div'} title={`Are you sure you want to remove ${selectedLLMList.length} LLM${selectedLLMList.length > 1 ? 's' : ''}?`} confirmLabel={"Remove"} isOpen={isMultiDeleteConfirmOpen} onConfirm={onMultiDelete} onClose={onCloseConfirm} />
			<ConfirmPopup id={'llm_remove_popup_div'} title={"Confirm Remove"} message={"Are you sure you want to remove this LLM?"} confirmLabel={"Remove"} isOpen={isDeleteConfirmOpen} onConfirm={onDelete} onClose={onCloseConfirm} />
			<FilterPopup id={'llm_search_filter_popup'} title={'Filter LLMs'} isOpen={showFilters} filterOptions={filterOptions} onFilter={onFilter} onClearFilter={onClearFilter} onClose={() => setShowFilters(false)} />
		</>
	)
}

export default LLMManager;
