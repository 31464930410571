import React, { useState } from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipWrapper from './TooltipWrapper';

const AccordionList = ({ title, listData, textData, button, className, info }) => {
    const [opened, setOpened] = useState(true);

    const handleCollapse = () => {
        setOpened(!opened);
    }
    const formatText = (textData) => {
        textData = textData.replace("Subject:", "<strong>Subject:</strong>")
        return textData.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                <div dangerouslySetInnerHTML={{ __html: line }} className='mb-2' />
            </React.Fragment>
        ))
    }

    return (
        <div className={`bg-white-gray-500 dark:bg-gray-black rounded-xl py-4 px-4 mb-4`}>
            <div className='text-gray-dark dark:text-white text-lg font-semibold flex justify-between '>
                <div className='flex items-center'>
                    <h5>{title}</h5>
                    <TooltipWrapper content={info} tootTipStyle={'w-60 mt-2'} autoHide={false} isShowArrow={true}>
                        <div className='flex ml-1 items-center justify-center'>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
                            </svg>
                        </div>
                    </TooltipWrapper>
                </div>
                <FontAwesomeIcon onClick={handleCollapse} className='text-white dark:text-gray-dark cursor-pointer bg-blue px-1 py-1 rounded-md ' icon={opened ? faMinus : faPlus} />
            </div>
            {opened &&
                <>
                    <div className={`my-3 ${className} text-gray-dark dark:text-white overflow-auto`}>
                        {listData ?
                            <ul className='list-disc text-sm px-4'>
                                {listData.map((item, key) => (
                                    <li className='py-2' key={key}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            :
                            <div className='text-gray-dark-500 dark:text-white text-sm px-2'>
                                {textData ? formatText(textData) : "Response will be ready in a few minutes..."}
                            </div>
                        }
                    </div>
                    <div className='flex w-full items-center justify-end'>
                        {button}
                    </div>
                </>
            }
        </div>
    )
}

export default AccordionList;
