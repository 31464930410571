import React, { useEffect, useRef, useState } from 'react';

const DropdownWrapper = ({ setValue, value, name, options, placeholder, optionsClassName, className }) => {
	const dropdownRef = useRef(null);
	const dropdownOptionsRef = useRef(null);
	const [showOptions, setShowOptions] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownOptionsRef.current && !dropdownOptionsRef.current.contains(event.target)) {
				setShowOptions(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [dropdownRef, dropdownOptionsRef])

	const onDropdownClick = () => {
		setShowOptions(prev => !prev);
	}

	const onDropdownSelect = (option) => {
		setValue && setValue(option);
		setShowOptions(false);
	}

	return (
		<div className={`block relative input-container ${value ? 'focused' : ''} ${className}`}>
			<div ref={dropdownRef} name={name} onClick={onDropdownClick} className={`w-full h-11 flex flex-row items-center justify-between dark:border-2 border-2 px-[14px] rounded-lg focus:outline-none ${value ? 'py-[10.5px] text-gray-dark dark:text-white' : 'py-[18px] text-gray-600 dark:text-gray-600'} border-white-gray-dark-400 dark:border-gray bg-white dark:bg-gray-dark hover:border-gray-light-700 focus:border-blue-dark focus:dark:border-blue disabled:border-gray-light-600 disabled:pointer-events-none cursor-pointer`}>
				<label htmlFor={name} className={`block bg-none text-gray-600 ${value ? 'bg-white dark:bg-gray-dark' : ''}`}>
					{placeholder}
				</label>
				<p className={`text-base font-bold min-w-40 w-max pr-2`}>{value ? value : ''}</p>
				<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.46875 8.03125L0.46875 2.03125C0.15625 1.75 0.15625 1.28125 0.46875 0.96875C0.75 0.6875 1.21875 0.6875 1.53125 0.96875L7 6.46875L12.4688 1C12.75 0.6875 13.2188 0.6875 13.5312 1C13.8125 1.28125 13.8125 1.75 13.5312 2.03125L7.5 8.03125C7.21875 8.34375 6.75 8.34375 6.46875 8.03125Z" fill="#788493" />
				</svg>
			</div>
			{showOptions &&
				<div ref={dropdownOptionsRef} className={`block max-h-60 p-3 mt-[4px] z-10 absolute rounded-xl bg-white-gray-500 dark:bg-gray-black overflow-auto ${optionsClassName}`}>
					{options && options.map((option, index) => {
						return <div id={option.id} onClick={() => onDropdownSelect(option)} className={`text-gray-dark dark:text-white w-full flex cursor-pointer justify-start ${(index < options.length - 1) ? '' : ''} p-2 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.label}>{option.label}</div>
					})}
				</div>
			}
		</div>
	)
}

export default DropdownWrapper;
