import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/AppContext';
import Lottie from 'react-lottie';
import axios from 'axios';
import { API_KEY, API_URL, BASE_URL, WORKFLOW_CARD_BUNDLE_ID } from '../../config';
import * as animationData from '../../assets/animations/Workflow-dots.json';
import GetInput from './GetInput';
import PickIntegration from './PickIntegration';
import WriteFile from './WriteFile';
import WriteImage from './WriteImage';
import PickFile from './PickFile';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useWorkflow } from '../../hooks/useWorkflow';
import Cookies from "js-cookie";

const Workflow = ({ showWorkFlow, appId, isSidebarOpen, isSidePanelOpen, onResult, activeLLM }) => {
    const scrollContainerRef = useRef(null);
    const workflowCardHook = useWorkflow();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentStepInfo, setCurrentStepInfo] = useState(null);
    const [tool, setTool] = useState(null);
    const [result, setResult] = useState(null);
    const [scrollPosition, setScrollPosition] = useState('left');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const req = {
            filter: {
                bundle_id: state.licenseInfo && state.licenseInfo.bundleId && state.licenseInfo.bundleId ? state.licenseInfo.bundleId : WORKFLOW_CARD_BUNDLE_ID,
                app_id: appId,
                category: "General"
            }
        }

        console.log(state.licenseInfo)
        workflowCardHook.getCards(req);
    }, [])

    useEffect(() => {
        if (state.workflowCards && state.workflowCards.length > 0) {
            const appCards = state.workflowCards.filter((card) => card.app_id === appId);
            setCards(appCards);
        }
    }, [state.workflowCards])

    useEffect(() => {
        if (selectedCard && selectedCard.workflow && (currentStep > 0) && (currentStep <= selectedCard.workflow.length)) {
            setTimeout(() => {
                setCurrentStepInfo(selectedCard.workflow[currentStep - 1]);
                setTool(null);
            }, 500);
        }
    }, [currentStep])

    useEffect(() => {
        if (selectedCard && selectedCard.workflow && (currentStep > selectedCard.workflow.length) && result) {
            onResult && onResult({ output_format: selectedCard.output_format, result: result });
            setResult(null);
            onDiscard();
        }
    }, [currentStep, result])

    useEffect(() => {
        if (currentStepInfo) {
            if (currentStepInfo.input_mode === 'assistant') {
                dispatch({
                    type: "LOADING",
                    payload: true,
                })

                let currentStepData = currentStepInfo;
                currentStepData.inference_task = "workflow_tool"
                currentStepData.user_id = state.user.user_id
                currentStepData.userGroup = state.user.user_groups

                setLoading(true);
                if (currentStepData.tool === 'generate_llm_response') {
                    currentStepData.inference_sub_task = "generate";
                    currentStepData.streaming = true;
                    getWorkflowCardStreamResponse(currentStepData, true);
                }
                else {
                    currentStepData.streaming = false;
                    currentStepData.inference_sub_task = "other"
                    getworkflowcardResponse(currentStepData, true)
                }
            } else {
                setTimeout(() => {
                    setTool(currentStepInfo.tool);
                }, 1000);
            }
        }
    }, [currentStepInfo])

    useEffect(() => {
        !tool && setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [tool])

    const getworkflowcardResponse = (currentStepData, isStart) => {
        axios.post(
            `${API_URL}/application/workflow/execute-tools`,
            currentStepData,
            {
                headers: {
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY,
                }
            })
            .then((res) => {
                dispatch({
                    type: "LOADING",
                    payload: false,
                })

                setData(res.data.response);
                setResult(res.data.response);
                isStart && setTool(currentStepInfo.tool);
            })
            .catch((err) => {
                onDiscard();
                dispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        type: "fail",
                        title: "Execution Failed",
                        subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Your flow failed. Please try again"
                    }
                })
                dispatch({
                    payload: false,
                })
                setLoading(false);
            });
    }

    const getWorkflowCardStreamResponse = async (currentStepData, isStart) => {
        try {
            const response = await fetch(`${API_URL}/application/workflow/execute-tools`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY
                },
                body: JSON.stringify(currentStepData),
            })

            let text = '';
            let resData = ''
            const jsonRegex = /\{.*\}/;

            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                resData = String.fromCharCode.apply(null, value);

                if (done) {
                    setData(text);
                    setResult(text);
                    isStart && setTool(currentStepData.tool);
                    break;
                }

                dispatch({
                    type: "LOADING",
                    payload: false,
                })

                const match = resData.match(jsonRegex);
                if (match) {
                    try {
                        text += resData.replace(jsonRegex, '').trim();
                        onResult && onResult({ output_format: selectedCard.output_format, result: text });
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    text += resData;
                    onResult && onResult({ output_format: selectedCard.output_format, result: text });
                }
            }
        } catch (err) {
            onDiscard();
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Execution Failed",
                    subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Your flow failed. Please try again"
                }
            })
            dispatch({
                type: "LOADING",
                payload: false,
            })
            setLoading(false);
        }
    }

    const onSelectCard = (card) => {
        if (card.required === 'llm' && !activeLLM) {
            dispatch({
                type: "SET_NO_ACTIVE_LLM_ALERT",
                payload: true
            })
        } else {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: true
            })
            dispatch({
                type: "SET_WORKFLOW_CARDS_RUNNING",
                payload: true
            })
            setSelectedCard(JSON.parse(JSON.stringify(card)));
            setCurrentStep(1);
        }
    }

    const onNext = (parameters, label) => {
        if (parameters) {
            if (currentStepInfo.input_mode === 'user') {
                setData(parameters[label]);
            } else {
                dispatch({
                    type: "LOADING",
                    payload: true,
                })

                let currentStepData = currentStepInfo;
                currentStepData.parameters = parameters;
                currentStepData.inference_task = "workflow_tool"
                currentStepData.user_id = state.user.user_id
                currentStepData.userGroup = state.user.user_groups

                setLoading(true);
                if (currentStepData.tool === 'generate_llm_response') {
                    currentStepData.inference_sub_task = "generate";
                    currentStepData.streaming = true;
                    getWorkflowCardStreamResponse(currentStepData, false);
                }
                else {
                    currentStepData.streaming = false;
                    currentStepData.inference_sub_task = "other"
                    getworkflowcardResponse(currentStepData, false)
                }
            }
        }
    }

    const setData = (output) => {
        let card = selectedCard;
        let workflow = card.workflow;

        let newWorkflow = workflow.map((step, index) => {
            if (index >= currentStep) {
                let parameters = step.parameters;

                Object.keys(parameters).forEach(parameter => {
                    const targetPattern = new RegExp(`\\$${currentStep}\\.output`, 'g');
                    let data = parameters[parameter]
                    if (targetPattern.test(data)) {
                        data = data.replace(targetPattern, output);
                        parameters[parameter] = data;
                    }
                })

                step.parameters = parameters;
            }
            return step;
        });

        card.workflow = newWorkflow;
        setSelectedCard(card);
        setCurrentStep(prevStep => prevStep += 1);
    }

    const onDiscard = () => {
        setCurrentStep(0);
        setCurrentStepInfo(null);
        setSelectedCard(null);
        dispatch({
            type: "SET_WORKFLOW_CARDS_RUNNING",
            payload: false
        })
        dispatch({
            type: "SET_DOC_SEARCH_STREAMING",
            payload: false
        })
    }

    const onScroll = (direction) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -260 : 260,
                behavior: 'smooth'
            });
        }
    };

    const onScrollCard = () => {
        if (scrollContainerRef.current && (scrollContainerRef.current.scrollLeft <= 0))
            setScrollPosition('left');
        else if (scrollContainerRef.current && ((scrollContainerRef.current.scrollLeft + scrollContainerRef.current.clientWidth) >= scrollContainerRef.current.scrollWidth))
            setScrollPosition('right');
        else
            setScrollPosition('middle');
    }

    return (
        showWorkFlow ?
            <div className='flex flex-col w-full py-8 items-center justify-center'>
                <div className='flex flex-row w-full justify-between items-center'>
                    {cards.length > 2 &&
                        <button disabled={scrollPosition === 'left'} className={`flex text-gray-dark dark:text-white disabled:text-gray-light disabled:dark:text-gray-light`} onClick={() => onScroll('left')} >
                            <FontAwesomeIcon className='w-8 h-8' icon={faChevronLeft} />
                        </button>
                    }
                    <div ref={scrollContainerRef} className={`${isSidebarOpen ? (isSidePanelOpen ? 'workflow-with-side-bar-and-side-panel-container' : 'workflow-with-side-bar-container') : (isSidePanelOpen ? 'workflow-with-side-panel-container' : 'workflow-container')} flex items-center pb-2 overflow-x-auto hide-scrollbar`} onScroll={onScrollCard}>
                        {cards.length > 0 &&
                            <div className="flex space-x-6" >
                                {cards.map((card, index) => {
                                    return (
                                        <div key={index} onClick={() => !(state.isStreaming || (selectedCard && (card.id !== selectedCard.id))) && onSelectCard(card)} className={`w-60 p-6 ${(state.isStreaming || (selectedCard && (card.id === selectedCard.id))) ? 'cursor-default text-gray-500 dark:text-gray-500 bg-white-gray-700 dark:bg-gray-black' : (selectedCard ? 'cursor-default' : 'cursor-pointer hover:bg-white-gray-700 hover:dark:bg-gray-black')} rounded-lg border border-white-gray-400 dark:border-gray-400`}>
                                            <img src={BASE_URL + card.icon} width={100} height={100} alt="" />
                                            <p className={`pt-3 pb-2 ${(state.isStreaming || (selectedCard && (card.id !== selectedCard.id))) ? 'text-gray-500 dark:text-gray-500' : 'text-gray-light dark:text-gray-light'} text-[13px] font-normal`}>{card.category}</p>
                                            <p className={`${(state.isStreaming || (selectedCard && (card.id !== selectedCard.id))) ? 'text-gray-500 dark:text-gray-500' : 'text-gray-dark dark:text-white'} text-base font-medium`}>{card.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    {cards.length > 2 &&
                        <button disabled={(scrollPosition === 'right') || (scrollContainerRef && scrollContainerRef.current && (scrollContainerRef.current.scrollWidth === scrollContainerRef.current.offsetWidth))} className={`flex text-gray-dark dark:text-white disabled:text-gray-light disabled:dark:text-gray-light`} onClick={() => onScroll('right')} >
                            <FontAwesomeIcon className='w-8 h-8' icon={faChevronRight} />
                        </button>
                    }
                </div>
                {currentStepInfo &&
                    <>
                        {!tool &&
                            <div className={`flex w-full items-center justify-start bg-white-gray-600 dark:bg-white-gray-dark-200 rounded-xl mt-4 px-4 py-3`}>
                                <div className='flex items-center justify-center w-[25px] mr-4'>
                                    <Lottie options={defaultOptions} height={25} width={25} />
                                </div>
                                <p className='text-gray-dark dark:text-white'>{`Step ${currentStep} - ${currentStepInfo.message}`}</p>
                            </div>
                        }
                        <GetInput isOpen={tool === 'get_user_input'} parameters={currentStepInfo.parameters} onSubmit={onNext} onClose={onDiscard} />
                        <PickIntegration loading={loading} isOpen={tool === 'pick_integration'} appId={appId} parameters={currentStepInfo.parameters} onSubmit={onNext} onClose={onDiscard} />
                        <WriteFile loading={loading} isOpen={tool === 'write_file'} parameters={currentStepInfo.parameters} onSubmit={onNext} onClose={onDiscard} />
                        <PickFile loading={loading} isOpen={tool === 'pick_file'} parameters={currentStepInfo.parameters} onSubmit={onNext} onClose={onDiscard} />
                        <WriteImage loading={loading} isOpen={tool === 'write_image'} parameters={currentStepInfo.parameters} onSubmit={onNext} onClose={onDiscard} />
                    </>
                }
            </div>
            :
            ''
    )
}

export default Workflow;
