import React, { } from 'react';
import Topbar from '../components/Home/Topbar';
import ApplicationsPermissionWrapper from '../components/ApplicationsPermissionWrapper';

const ApplicationsPermission = () => {
	return (
		<div className="h-screen bg-white dark:bg-gray-dark">
			<Topbar showHeaderIcon={true} />
			<main className="w-full content-container mx-auto">
				<div className='px-6'>
					<h4 className='text-gray-dark dark:text-white-600 text-lg font-bold '>Select User Group Permissions</h4>
					<p className="text-gray-dark dark:text-white text-sm mb-3">Choose which user groups and users have access to each feature.</p>
				</div>
				<ApplicationsPermissionWrapper isAuthStack={true} />
			</main>
		</div>
	)
}

export default ApplicationsPermission;
