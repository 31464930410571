import React, { useEffect } from 'react';
import { Button } from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const AlertPopup = ({ id, isOpen, onButtonAction, onClose, title, message, buttonLabel, buttonLoading, buttonIcon, buttonClassName }) => {
	const divId = id ? id : 'alert_popup_div';

	useEffect(() => {
		const popupDiv = document.getElementById(divId);
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	return (
		<div id={divId} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} w-[600px] bg-white-500 dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray drop-shadow-xl rounded-lg z-10`}>
				<div className='font-light p-8'>
					<div className='flex flex-row items-start justify-between'>
						<div className='mb-6 max-h-[400px] pr-6 overflow-y-auto'>
							<p className='flex w-full justify-center text-gray-dark dark:text-white text-xl font-semibold'>{title}</p>
							<p className='text-gray-dark dark:text-white text-justify mt-2'>{message}</p>
						</div>
						{onClose && <button className="flex items-center text-gray-dark dark:text-white" onClick={onClose} >
							<FontAwesomeIcon className='w-6 h-6' icon={faClose} />
						</button>}
					</div>
					<div className='flex gap-8 justify-evenly w-full'>
						<Button loading={buttonLoading} className={`self-center py-[8px] min-h-11 gap-2 flex flex-row items-center justify-center content-center ${buttonClassName}`} handleFunction={onButtonAction || onClose} name={buttonLabel || "Ok"} icon={buttonIcon} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default AlertPopup;