
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useStore } from '../store/AppContext';

const ThemeChange = ({ isOpen, onClose }) => {
	const { state, dispatch } = useStore()

	useEffect(() => {
		const popupDiv = document.getElementById('theme_popup_div');
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	const handleClose = () => {
		onClose();
	}

	const toggleDark = () => {
		dispatch({
			type: `TOGGLE_DARK`,
		})
	}
	const toggleLight = () => {
		dispatch({
			type: `TOGGLE_LIGHT`,
		})
	}

	return (
		<div id={'theme_popup_div'} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} w-[400px] bg-white-500 dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray drop-shadow-xl p-9 rounded-3xl z-10`}>
				<div className='flex flex-row items-center justify-between mb-6'>
					<h3 className='flex w-full justify-center text-2xl dark:text-white'>Theme</h3>
					<button className="flex items-center text-gray-dark dark:text-white" onClick={handleClose} >
						<FontAwesomeIcon className='w-6 h-6' icon={faClose} />
					</button>
				</div>
				<div className='font-light'>
					<div className='flex justify-around gap-6 w-full'>
						<button onClick={toggleLight} className={`flex items-center justify-center w-full h-11 rounded-lg font-bold py-2 ${state.isDarkMode ? "border-2 border-blue text-blue " : "bg-blue text-white "} focus:outline-none focus:border-blue-dark focus:dark:border-blue`}>
							<svg className='mr-2' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.25 0.75V2.75C9.25 3.1875 8.90625 3.5 8.5 3.5C8.0625 3.5 7.75 3.1875 7.75 2.75V0.75C7.75 0.34375 8.0625 0 8.5 0C8.90625 0 9.25 0.34375 9.25 0.75ZM14.1562 3.40625L12.7188 4.84375C12.4375 5.125 11.9688 5.125 11.6562 4.84375C11.375 4.53125 11.375 4.0625 11.6562 3.78125L13.0938 2.34375C13.375 2.0625 13.8438 2.0625 14.1562 2.34375C14.4375 2.65625 14.4375 3.125 14.1562 3.40625ZM3.875 2.34375L5.3125 3.78125C5.59375 4.0625 5.59375 4.53125 5.3125 4.84375C5 5.125 4.53125 5.125 4.25 4.84375L2.84375 3.40625C2.53125 3.125 2.53125 2.65625 2.84375 2.34375C3.125 2.0625 3.59375 2.0625 3.875 2.34375ZM1.25 7.25H3.25C3.65625 7.25 4 7.59375 4 8C4 8.4375 3.65625 8.75 3.25 8.75H1.25C0.8125 8.75 0.5 8.4375 0.5 8C0.5 7.59375 0.8125 7.25 1.25 7.25ZM13.75 7.25H15.75C16.1562 7.25 16.5 7.59375 16.5 8C16.5 8.4375 16.1562 8.75 15.75 8.75H13.75C13.3125 8.75 13 8.4375 13 8C13 7.59375 13.3125 7.25 13.75 7.25ZM5.3125 12.25L3.875 13.6562C3.59375 13.9688 3.125 13.9688 2.84375 13.6562C2.53125 13.375 2.53125 12.9062 2.84375 12.625L4.25 11.1875C4.53125 10.9062 5 10.9062 5.3125 11.1875C5.59375 11.5 5.59375 11.9688 5.3125 12.25ZM12.7188 11.1875L14.1562 12.625C14.4375 12.9062 14.4375 13.375 14.1562 13.6562C13.8438 13.9688 13.375 13.9688 13.0938 13.6562L11.6562 12.25C11.375 11.9688 11.375 11.5 11.6562 11.1875C11.9688 10.9062 12.4375 10.9062 12.7188 11.1875ZM9.25 13.25V15.25C9.25 15.6875 8.90625 16 8.5 16C8.0625 16 7.75 15.6875 7.75 15.25V13.25C7.75 12.8438 8.0625 12.5 8.5 12.5C8.90625 12.5 9.25 12.8438 9.25 13.25ZM10.5 8C10.5 7.3125 10.0938 6.65625 9.5 6.28125C8.875 5.9375 8.09375 5.9375 7.5 6.28125C6.875 6.65625 6.5 7.3125 6.5 8C6.5 8.71875 6.875 9.375 7.5 9.75C8.09375 10.0938 8.875 10.0938 9.5 9.75C10.0938 9.375 10.5 8.71875 10.5 8ZM5 8C5 6.75 5.65625 5.625 6.75 5C7.8125 4.34375 9.15625 4.34375 10.25 5C11.3125 5.625 12 6.75 12 8C12 9.28125 11.3125 10.4062 10.25 11.0312C9.15625 11.6875 7.8125 11.6875 6.75 11.0312C5.65625 10.4062 5 9.28125 5 8Z" fill="currentColor" />
							</svg>
							<p className='text-base font-semibold'>Light</p>
						</button>
						<button onClick={toggleDark} className={`flex items-center justify-center w-full h-11 rounded-lg font-bold py-2 ${state.isDarkMode ? "bg-blue text-white " : "border-2 border-blue text-blue "} focus:outline-none focus:border-blue-dark focus:dark:border-blue`}>
							<svg className='mr-2' width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.5 2.09375C2.71875 3 1.5 4.875 1.5 7C1.5 10.0625 3.9375 12.5 6.96875 12.5C7.375 12.5 7.78125 12.4688 8.1875 12.375C5.4375 11.4375 3.46875 8.8125 3.46875 5.75C3.46875 4.40625 3.84375 3.15625 4.5 2.09375ZM6.4375 0.03125C6.53125 0.03125 6.59375 0.03125 6.65625 0.03125C6.78125 0.03125 6.875 0.03125 6.96875 0.03125C7.0625 0.03125 7.15625 0.03125 7.21875 0.03125C7.34375 0.03125 7.4375 0.03125 7.5625 0.03125C7.78125 0.0625 7.96875 0.21875 8 0.4375C8.0625 0.65625 7.96875 0.875 7.78125 0.96875C7.65625 1.03125 7.5625 1.09375 7.46875 1.15625C7.34375 1.25 7.21875 1.34375 7.09375 1.4375C7.03125 1.46875 7 1.5 6.96875 1.53125C5.75 2.53125 4.96875 4.0625 4.96875 5.78125C4.96875 8.8125 7.4375 11.25 10.4375 11.2812C10.4688 11.2812 10.4688 11.2812 10.4688 11.2812C10.5 11.2812 10.5312 11.2812 10.5625 11.2812C10.75 11.25 10.9062 11.25 11.0625 11.25C11.1562 11.2188 11.2812 11.2188 11.4062 11.1875C11.625 11.1562 11.8438 11.25 11.9375 11.4375C12.0312 11.6562 12 11.875 11.8438 12.0312C11.75 12.125 11.6875 12.1875 11.5938 12.25C11.4375 12.375 11.3125 12.5 11.1562 12.625C11.0938 12.6562 11.0312 12.7188 10.9688 12.75C9.84375 13.5312 8.46875 14 6.96875 14C3.125 14 0 10.875 0 7C0 3.3125 2.84375 0.3125 6.4375 0.03125Z" fill="currentColor" />
							</svg>
							<p className='text-base font-semibold'>Dark</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ThemeChange;
