import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DocUserPermissionCheckbox } from '../CheckBox';
import { Button } from '../Button';

const UserGroupAccessModal = ({ id, isOpen, onClose, field_id, onSave, data, userGroupList }) => {
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const divId = id ? id + '_user_group_popup_div' : 'user_group_popup_div';

	useEffect(() => {
		const popupDiv = document.getElementById(divId);
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	const handleClose = () => {
		setSelectedGroups([]);
		setSelectedUsers([]);
		onClose();
	}

	useEffect(() => {
		if (isOpen && data && data.user_ids) {
			let groupList = [];
			Object.keys(userGroupList).map(group_id => {
				const usersList = userGroupList[group_id] && userGroupList[group_id].users && userGroupList[group_id].users ? JSON.parse(JSON.stringify(userGroupList[group_id].users)) : [];
				if (usersList.every(user => data.user_ids.includes(user.user_id)))
					groupList.push(group_id);

				return group_id;
			})
			setSelectedUsers(data.user_ids);
			setSelectedGroups(groupList);
		}
	}, [data, isOpen])

	const handleSave = () => {
		onSave({ [field_id]: data[field_id], user_ids: selectedUsers });
		handleClose();
	}

	const handleGroupCheckboxChange = (option) => {
		console.log(option)
		if (selectedGroups.some((selected) => selected === option.group_id)) {
			setSelectedGroups(selectedGroups.filter((group_id) => group_id !== option.group_id));
			setSelectedUsers([...selectedUsers.filter((user_id) => !userGroupList[option.group_id].users.find(user => user.user_id === user_id))]);
		} else {
			setSelectedGroups([...selectedGroups, option.group_id]);
			setSelectedUsers([...selectedUsers, ...userGroupList[option.group_id].users.map(user => selectedUsers.includes(user.user_id) ? null : user.user_id).filter((user) => user)]);
		}
	}

	const handleUserCheckboxChange = (option) => {
		console.log(option)
		const usersList = userGroupList[option.group_id] && userGroupList[option.group_id].users && userGroupList[option.group_id].users ? JSON.parse(JSON.stringify(userGroupList[option.group_id].users)) : [];
		if (selectedUsers.some((selected) => selected === option.user_id)) {
			const newList = selectedUsers.filter((user_id) => user_id !== option.user_id);
			setSelectedUsers(newList);
			setSelectedGroups(prev => {
				if (prev.includes(option.group_id) && !usersList.every(user => newList.includes(user.user_id)))
					prev = prev.filter(group_id => group_id !== option.group_id);

				return prev;
			});
		} else {
			const newList = [...selectedUsers, option.user_id];
			setSelectedUsers(newList);
			setSelectedGroups(prev => {
				if (!prev.includes(option.group_id) && usersList.every(user => newList.includes(user.user_id)))
					prev.push(option.group_id);

				return prev;
			});
		}
	}

	return (
		<div id={divId} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-black ${isOpen ? 'popup-ease-in-opacity' : 'popup-ease-out-opacity'} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in' : 'popup-ease-out'} text-gray-dark dark:text-white bg-white-500 dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray p-4 w-[400px] drop-shadow-xl rounded-lg z-10`}>
				<div className='flex flex-row justify-between'>
					<h4 className='flex w-full justify-center text-xl'>Edit User Group Access</h4>
					<button className="flex items-start text-2xl focus:outline-none" onClick={handleClose} >
						<FontAwesomeIcon icon="xmark" />
					</button>
				</div>
				<div className='max-h-80 relative overflow-auto my-4 ml-4'>
					{Object.keys(userGroupList).map((group_id, index) => {
						return (
							<div key={index}>
								<DocUserPermissionCheckbox options={[{ group_id: group_id, name: userGroupList[group_id].group_name }]} field={'group_id'} selectedOptions={selectedGroups} onChange={handleGroupCheckboxChange} type={'checkbox'} />
								<div className='ml-10'>
									<DocUserPermissionCheckbox options={userGroupList[group_id].users} field={'user_id'} selectedOptions={selectedUsers} onChange={handleUserCheckboxChange} type={'checkbox'} />
								</div>
							</div>
						)
					}
					)}
				</div>
				<div className='flex w-full items-center justify-center gap-5 inset-x-0 bottom-4'>
					<Button handleFunction={handleClose} className={`bg-gray dark:bg-gray border-gray dark:border-gray py-[10px]`} name={"Cancel"} />
					<Button handleFunction={handleSave} className={'ml-5 py-[10px]'} name={"Save"} />
				</div>
			</div>
		</div>
	)
}

export default UserGroupAccessModal;
