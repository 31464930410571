import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useStore } from '../../store/AppContext';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import DashboardPage from '../../pages/DashboardPage';
import CoPilotPage from '../../pages/co-pilot';
import DocSearchPage from '../../pages/doc-search';
import DbSearchPage from '../../pages/db-search';
import Authentication from '../../pages/Authendication';
import { useIntegration } from '../../hooks/useIntegration';
import { SUBSCRIPTION_ALERT_DAYS, SUBSCRIPTION_ALERT_DAYS_RANGE, USER_ROLE } from '../../config';
import ApplicationsPermissionWrapper from '../ApplicationsPermissionWrapper';
import { useLLMManager } from '../../hooks/useLLMManager';
import Cookies from 'js-cookie';
import AlertPopup from '../AlertPopup';
import TooltipWrapper from '../TooltipWrapper';

const Home = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const integrationHook = useIntegration();
	const llmManagerHook = useLLMManager();
	const { state, dispatch } = useStore();
	const [isSidebarOpen, setSidebarOpen] = useState(true);
	const [sectionTitle, setSectionTitle] = useState("");
	const [activeVDB, setActiveVDB] = useState("");
	const [activeLLM, setActiveLLM] = useState("");
	const [activeTabId, setActiveTabId] = useState("");
	const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);

	useEffect(() => {
		llmManagerHook.getModelsData();
		llmManagerHook.getModels();
	}, [])

	useEffect(() => {
		state.showNoLLMAlert && setTimeout(() => {
			dispatch({
				type: "SET_NO_ACTIVE_LLM_ALERT",
				payload: false
			})
		}, 5000);
	}, [state.showNoLLMAlert])

	useEffect(() => {
		dispatch({
			type: "SET_NO_ACTIVE_LLM_ALERT",
			payload: false
		})
	}, [activeTabId])

	useEffect(() => {
		if (state.isLoggedIn)
			state.user?.user_id && integrationHook.getIntegration();
	}, [state.user?.user_id])

	useEffect(() => {
		if (state.isLicenseKeyFetched && (state.userRole === USER_ROLE.SUPER_ADMIN) && state.licenseInfo)
			setShowSubscriptionAlert((
				SUBSCRIPTION_ALERT_DAYS.includes(state.licenseInfo?.remainingDays) ||
				isBetween(SUBSCRIPTION_ALERT_DAYS_RANGE, state.licenseInfo?.remainingDays)) &&
				Cookies.get("renewSubscriptionShownDay") !== state.licenseInfo?.remainingDays
			);
	}, [state.licenseInfo, state.userRole]);

	const isBetween = (range, value) => {
		return range.max >= value && range.min <= value;
	}

	const handleToggleSidebar = () => {
		setSidebarOpen(!isSidebarOpen);
	}

	const onCloseAlert = () => {
		Cookies.set("renewSubscriptionShownDay", state.licenseInfo?.remainingDays)
		setShowSubscriptionAlert(false);
	}

	const onRenew = () => {
		Cookies.set("renewSubscriptionShownDay", state.licenseInfo?.remainingDays)
		navigate('/renew-subscription')
	}

	return (
		<div className="flex h-screen bg-white dark:bg-gray-dark">
			<Sidebar isOpen={isSidebarOpen} onToggleSidebar={handleToggleSidebar} setActiveTabId={setActiveTabId} setSectionTitle={setSectionTitle} setActiveVDB={setActiveVDB} setActiveLLM={setActiveLLM} activeTabId={activeTabId} />
			<div className={`flex-1 ${isSidebarOpen ? 'pl-80' : 'pl-0'} transition-all duration-300 w-full`}>
				<Topbar isOpen={isSidebarOpen} onToggleSidebar={handleToggleSidebar} activeTabId={activeTabId} sectionTitle={sectionTitle} activeVDB={activeVDB} activeLLM={activeLLM} />
				<main className="w-full xl:max-w-full mx-auto">
					<Routes>
						<Route path="/" element={<DashboardPage />} />
						<Route path="/dashboard" element={<DashboardPage />} />
						<Route path="/service-pilot/*" element={<CoPilotPage activeLLM={activeLLM} />} />
						<Route path="/db-search/*" element={<DbSearchPage activeLLM={activeLLM} />} />
						<Route path="/doc-search/*" element={<DocSearchPage isSidebarOpen={isSidebarOpen} activeTabId={activeTabId} activeLLM={activeLLM} />} />
						{[USER_ROLE.SUPER_ADMIN].includes(state.userRole) &&
							<>
								<Route path="/auth/*" element={<Authentication />} />
								<Route path="/users/*" element={<ApplicationsPermissionWrapper />} />
							</>
						}
					</Routes>
				</main>
				{showSubscriptionAlert && <AlertPopup id={'subscription_alert_popup_div'} title={"Your Subscription is Ending Soon!"} message={`Your subscription will expire in ${state.licenseInfo?.remainingDays} days. Don't miss out on uninterrupted access! Renew now to continue enjoying all the benefits.`} buttonLabel={"Renew Subscription"} buttonClassName={'w-[235px] px-[4px]'} isOpen={showSubscriptionAlert} onButtonAction={onRenew} onClose={onCloseAlert} />}
				{(location.pathname.includes('/app') || location.pathname.includes('/email')) && 
					<div className='fixed flex w-max h-max z-50 bottom-3 right-5'>
						<TooltipWrapper content={"The responses provided by Generate are for informational purposes only and should not be construed as, nor substituted for, legal, medical, financial, or any other professional advice. Users are encouraged to consult with qualified professionals for advice tailored to their specific circumstances"} tootTipStyle={'w-[400px] -ml-[350px] -mt-[129px] text-justify'} arrowClassName={'-ml-2'} onTop={true} autoHide={false} isShowArrow={true}>
							<div className='flex items-center justify-center'>
								<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
								</svg>
							</div>
						</TooltipWrapper>
					</div>
				}
			</div>
		</div>
	)
}

export default Home;
