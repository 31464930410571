import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useApplication } from '../../hooks/useApplication';
import { Button } from '../Button';

const FileUpload = ({ disabled, children, folder, path, name, allowMultiple, acceptFiles, appId, dbConfig, setDBConfig, buttonType, setLoading, loading, className }) => {
    const applicationHook = useApplication();
    const DEFAULT_ACCEPT_FILES = {
        'application/pdf': ['.pdf'],
        'text/csv': ['.csv'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'text/plain': ['.txt'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            let formData = new FormData();
            acceptedFiles.forEach(file => {
                formData.append('files', file);
            });
            formData.append('appId', appId);
            formData.append('path', path);
            setLoading && setLoading(true);
            applicationHook.uploadFile(formData, dbConfig, setDBConfig, setLoading);
        }
    }, [])

    const onDropRejected = useCallback(reject => {
        console.log(reject)
    }, [])

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, onDropRejected, noClick: true, accept: acceptFiles ? acceptFiles : DEFAULT_ACCEPT_FILES, multiple: allowMultiple, maxFiles: 1000, useFsAccessApi: false });

    return (
        <div className={`${className ? '' : 'w-full'}`}>
            <div {...getRootProps()}>
                {folder ?
                    <input {...getInputProps()} directory="" webkitdirectory="" type="file" />
                    :
                    <input {...getInputProps()} />
                }
                {children ?
                    <button className='flex items-center justify-center' onClick={open}>
                        {children}
                    </button>
                    :
                    <Button loading={loading} disabled={disabled || false} name={`${name ? name : (folder ? "Upload Folder" : "Upload Documents")}`} type={buttonType ? buttonType : "outline"} handleFunction={open} className={`py-[10px] ${className ? className : 'w-full'}`} />
                }
            </div>
        </div>
    )
}

export default FileUpload