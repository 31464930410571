import { createContext, useContext, useEffect, useReducer } from 'react';
import { INTEGRATION_TYPES, ROUTES_ID } from '../config';
import moment from 'moment';

const initialState = {
	isDarkMode: (localStorage.theme !== 'light'),
	loading: false,
	subtitle: null,
	showMessage: false,
	message: { type: null, title: '', subtitle: '', close: '' },
	selectedIntegration: {
		[ROUTES_ID.SERVICE_PILOT]: null,
		[ROUTES_ID.DOCUMENT_SEARCH]: INTEGRATION_TYPES.local,
		[ROUTES_ID.DATABASE_SEARCH]: INTEGRATION_TYPES.local
	},
	allConfig: {},
	authConfig: {},
	integrations: {},
	authConfigChanged: false,
	userAccessApplications: { [ROUTES_ID.SERVICE_PILOT]: '', [ROUTES_ID.DATABASE_SEARCH]: '', [ROUTES_ID.DOCUMENT_SEARCH]: '' },
	appsPermissionUsers: { [ROUTES_ID.SERVICE_PILOT]: [], [ROUTES_ID.DATABASE_SEARCH]: [], [ROUTES_ID.DOCUMENT_SEARCH]: [] },
	appsPermissionUserGroups: { [ROUTES_ID.SERVICE_PILOT]: [], [ROUTES_ID.DATABASE_SEARCH]: [], [ROUTES_ID.DOCUMENT_SEARCH]: [] },
	isCoPilotStreaming: false,
	isDocSearchStreaming: false,
	coPilotConfig: {},
	docSearchConfig: {},
	dbSearchConfig: {},
	dbSearchSampleQuery: [],
	isDBSearchStreaming: false,
	coPilotEmailsInfo: { emails: [], total: 0 },
	mainChatList: [],
	dbSearchResult: [],
	docSearch: { sources: [], history: [], groupedHistory: {}, suggestions: [] },
	docSearchChat: null,
	docSearchSummary: null,
	docSearchCurrentConversation: null,
	docSearchChatHistory: null,
	docSearchPDFChatResponse: null,
	docSearchInternetSearch: false,
	isChatLoading: false,
	isPDFChatLoading: false,
	showNoLLMAlert: false,
	llmModels: [],
	llmModelsData: {},
	workflowCards: [],
	isWorkFlowRunning: false,
	isLoggedIn: false,
	userRole: null,
	user: null,
	token: null,
	dashboardSummary: {},
	dashboardQueries: {},
	coPilotSummary: {},
	coPilotResponseEmail: { highlights: "" },
	copilotInference: '',
	resourcesByUser: [],
	coPilotResourcesByUser: [],
	dbResourcesByUser: [],
	llmConfig: {},
	userGroupList: {},
	userGroupInfo: { groups: [], total: 0 },
	userInfo: { users: [], total: 0 },
	licenseKey: null,
	licenseInfo: null,
	isLicenseKeyFetched:false,
}

const storeReducer = (state, action) => {
	switch (action.type) {
		case 'LOADING':
			return {
				...state,
				loading: action.payload
			}
		case 'SET_THEME':
			return {
				...state,
				isDarkMode: action.payload
			}
		case 'TOGGLE_THEME':
			return {
				...state,
				isDarkMode: !state.isDarkMode
			}
		case 'TOGGLE_DARK':
			return {
				...state,
				isDarkMode: true
			}
		case 'TOGGLE_LIGHT':
			return {
				...state,
				isDarkMode: false
			}
		case 'CHAT_LOADING':
			return {
				...state,
				isChatLoading: action.payload
			}
		case 'INTERNET_SEARCH':
			return {
				...state,
				isChatLoading: action.payload
			}
		case 'SECTION_SUBTITLE':
			return {
				...state,
				subtitle: action.payload
			}
		case 'PDF_CHAT_LOADING':
			return {
				...state,
				isPDFChatLoading: action.payload
			}
		case 'SET_ALL_CONFIG': {
			return {
				...state,
				allConfig: action.payload,
			}
		}
		case 'SET_SELECTED_INTEGRATION':
			return {
				...state,
				selectedIntegration: action.payload
			}
		case 'SET_AUTH_CONFIG': {
			return {
				...state,
				authConfig: action.payload,
				authConfigChanged: false
			}
		}
		case 'SET_INTEGRATION': {
			return {
				...state,
				integrations: action.payload,
			}
		}
		case 'SET_NO_ACTIVE_LLM_ALERT': {
			return {
				...state,
				showNoLLMAlert: action.payload
			}
		}
		case 'SET_LLM_MODELS': {
			return {
				...state,
				llmModels: action.payload
			}
		}
		case 'SET_LLM_MODELS_DATA': {
			return {
				...state,
				llmModelsData: action.payload
			}
		}
		case 'SET_CO_PILOT_CONFIG': {
			return {
				...state,
				coPilotConfig: action.payload,
			}
		}
		case 'SET_LICENSE_KEY_CONFIG': {
			return {
				...state,
				licenseKey: action.payload?.licenseKey,
			}
		}
		case 'SET_DOCUMENT_SEARCH_CONFIG':
			return {
				...state,
				docSearchConfig: action.payload,
			}
		case 'SET_DB_SEARCH_CONFIG':
			return {
				...state,
				dbSearchConfig: action.payload,
			}
		case 'SET_DB_RESOURCES_BY_USER': {
			return { ...state, dbResourcesByUser: action.payload }
		}
		case 'SET_DB_SEARCH_CONNECT':
			return {
				...state,
				dbSearchSampleQuery: action.payload.sample_query,
			}
		case 'SET_CO_PILOT_EMAILS': {
			return {
				...state,
				coPilotEmailsInfo: action.payload,
			}
		}
		case 'SET_CO_PILOT_SUMMARY': {
			return {
				...state,
				coPilotSummary: action.payload,
			}
		}
		case 'SET_CO_PILOT_INFERENCE': {
			return {
				...state,
				copilotInference: action.payload
			}
		}
		case 'UPDATE_CO_PILOT_SUMMARY': {
			return {
				...state,
				coPilotSummary: { ...state.coPilotSummary, finalResponse: action.payload },
			}
		}
		case 'SET_CO_PILOT_RESPONSE_EMAIL': {
			return {
				...state,
				coPilotResponseEmail: action.payload,
			}
		}
		case 'SET_CO_PILOT_RESPONSE_EMAIL_HIGHLIGHTS': {
			return {
				...state,
				coPilotResponseEmail: {
					...state.coPilotResponseEmail,
					highlights: action.payload
				}
			}
		}
		case 'SET_CO_PILOT_RESPONSE_EMAIL_RESPONSE': {
			return {
				...state,
				coPilotResponseEmail: {
					...state.coPilotResponseEmail,
					response_email: action.payload
				}
			}
		}
		case 'CLEAR_CO_PILOT_DATA': {
			return {
				...state,
				coPilotSummary: {},
				coPilotResponseEmail: {}
			}
		}
		case 'SET_DB_SEARCH_RESPONSE': {
			return {
				...state,
				dbSearchResult: action.payload.dbs_response,
			}
		}
		case 'SET_MESSAGE': {
			return {
				...state,
				showMessage: true,
				message: action.payload
			}
		}
		case 'SET_DOC_RESOURCES': {
			return {
				...state,
				docSearch: {
					...state.docSearch,
					sources: action.payload
				}
			}
		}
		case 'SET_DOC_SUGGESTIONS': {
			return {
				...state,
				docSearch: {
					...state.docSearch,
					suggestions: action.payload
				}
			}
		}
		case 'SET_WORKFLOW_CARDS': {
			return {
				...state,
				workflowCards: action.payload.cards_info
			}
		}
		case 'SET_WORKFLOW_CARDS_RUNNING': {
			return {
				...state,
				isWorkFlowRunning: action.payload
			}
		}
		case 'SET_DOC_HISTORY': {
			let sectionKey = ['today', 'yesterday', 'previousDays', 'PreviousMonth'];
			let sectionData = {
				today: {
					title: 'Today',
					data: []
				},
				yesterday: {
					title: 'Yesterday',
					data: []
				},
				previousDays: {
					title: 'Previous 30 Days',
					data: []
				},
				PreviousMonth: {
					title: 'Previous Months',
					data: []
				}

			};

			action.payload.forEach(r => {
				let startTime = moment(r.created, "YYYY-MM-DDThh:mm:ss.000+z");
				let endTime = moment().endOf('day');
				let days = moment.duration(endTime.diff(startTime)).asDays();

				if (days <= 1) {
					sectionData['today']['data'].push(r);
				} else if (days <= 2) {
					sectionData['yesterday']['data'].push(r);
				} else if (days <= 30) {
					sectionData['previousDays']['data'].push(r);
				} else {
					sectionData['PreviousMonth']['data'].push(r);
				}
			})

			return {
				...state,
				docSearch: {
					...state.docSearch,
					history: action.payload,
					groupedHistory: {
						hasData: action.payload && (action.payload.length > 0),
						sectionKey: sectionKey,
						sectionData: sectionData
					}
				}
			}
		}
		case 'SET_DOC_CHAT_HISTORY': {
			return {
				...state,
				docSearchChatHistory: action.payload.chatArray,
				docSearchCurrentConversation: action.payload.convID
			}
		}
		case 'SET_DOC_CHAT_HISTORY_LIST': {
			return {
				...state,
				mainChatList: action.payload
			}
		}
		case 'UPDATE_DOC_CHAT_HISTORY': {
			let groupedHistory = state.docSearch.groupedHistory;
			const todayHistory = groupedHistory.sectionData['today'] ? groupedHistory.sectionData['today']['data'] : [];
			const newTodayHistory = [action.payload, ...todayHistory];
			groupedHistory.sectionData['today'] = {
				title: 'Today',
				data: newTodayHistory
			}

			return {
				...state,
				docSearch: {
					...state.docSearch,
					history: [action.payload, ...state.docSearch.history],
					groupedHistory: groupedHistory
				}
			}
		}
		case 'SET_DOC_CONVO_ID': {
			return {
				...state,
				docSearchCurrentConversation: action.payload
			}
		}
		case 'SET_DOC_CHAT': {
			return {
				...state,
				docSearchChat: action.payload
			}
		}
		case 'UPDATE_SOURCE_DOC_CHAT': {
			return {
				...state,
				docSearchChat: { ...state.docSearchChat, sources: action.payload }
			}
		}
		case 'SET_DOC_SUMMERY': {
			return {
				...state,
				docSearchSummary: action.payload
			}
		}
		case 'SET_DOC_PDF_RESPONSE': {
			return {
				...state,
				docSearchPDFChatResponse: action.payload
			}
		}
		case 'SET_DOC_SEARCH_STREAMING': {
			return {
				...state,
				isDocSearchStreaming: action.payload
			}
		}
		case 'SET_DB_SEARCH_STREAMING': {
			return {
				...state,
				isDBSearchStreaming: action.payload
			}
		}
		case 'UPDATE_DB_USER_PERMISSION': {
			return { ...state, dbResourcesByUser: state.dbResourcesByUser.map(resource => resource.db_id === action.payload.db_id ? { ...resource, ...action.payload } : resource) }
		}
		case 'SET_CO_PILOT_STREAMING': {
			return {
				...state,
				isCoPilotStreaming: action.payload
			}
		}
		case 'RESET_MESSAGE': {
			return {
				...state,
				showMessage: false,
				message: { type: null, title: '', subtitle: '', close: '' }
			}
		}
		case 'UPDATE_AUTH_CONFIG': {
			return {
				...state,
				authConfig: action.payload,
				authConfigChanged: true
			}
		}
		case 'LOGIN':
			return {
				...state,
				isLoggedIn: ![null, undefined, ''].includes(action.payload.token),
				user: action.payload.user,
				userRole: action.payload.user.user_role,
				token: action.payload.token
			}
		case 'SET_USER':
			return {
				...state,
				user: action.payload
			}
		case 'LOGOUT':
			return {
				isDarkMode: state.isDarkMode,
				loading: false,
				subtitle: null,
				showMessage: false,
				message: { type: null, title: '', subtitle: '', close: '' },
				selectedIntegration: {
					[ROUTES_ID.SERVICE_PILOT]: null,
					[ROUTES_ID.DOCUMENT_SEARCH]: INTEGRATION_TYPES.local,
					[ROUTES_ID.DATABASE_SEARCH]: INTEGRATION_TYPES.local
				},
				allConfig: {},
				authConfig: {},
				authConfigChanged: false,
				userAccessApplications: { [ROUTES_ID.SERVICE_PILOT]: '', [ROUTES_ID.DATABASE_SEARCH]: '', [ROUTES_ID.DOCUMENT_SEARCH]: '' },
				coPilotConfig: {},
				docSearchConfig: {},
				dbSearchConfig: {},
				coPilotEmailsInfo: { emails: [], total: 0 },
				dbSearchResult: [],
				docSearch: { sources: [], history: [], groupedHistory: {}, suggestions: [] },
				docSearchChat: null,
				docSearchSummary: null,
				docSearchCurrentConversation: null,
				docSearchChatHistory: null,
				docSearchPDFChatResponse: null,
				docSearchInternetSearch: false,
				isChatLoading: false,
				isPDFChatLoading: false,
				showNoLLMAlert: false,
				llmModels: [],
				llmModelsData: {},
				isLoggedIn: false,
				userRole: null,
				user: null,
				token: null,
				dashboardSummary: {},
				dashboardQueries: {},
				coPilotSummary: {},
				coPilotResponseEmail: {},
				appsPermissionUsers: { [ROUTES_ID.SERVICE_PILOT]: [], [ROUTES_ID.DATABASE_SEARCH]: [], [ROUTES_ID.DOCUMENT_SEARCH]: [] },
				appsPermissionUserGroups: { [ROUTES_ID.SERVICE_PILOT]: [], [ROUTES_ID.DATABASE_SEARCH]: [], [ROUTES_ID.DOCUMENT_SEARCH]: [] },
				userGroupList: {},
				userGroupInfo: { groups: [], total: 0 },
				userInfo: { users: [], total: 0 },
				licenseKey: state.licenseKey,
				licenseInfo: state.licenseInfo,
				isLicenseKeyFetched: state.isLicenseKeyFetched,
			}
		case 'SET_APPS_PERMISSION':
			return {
				...state,
				appsPermissionUsers: action.payload.permissionUsers,
				appsPermissionUserGroups: action.payload.permissionUserGroups
			}
		case 'SET_APPLICATIONS_USERS':
			return {
				...state,
				userAccessApplications: action.payload
			}
		case 'SET_DASHBOARD_SUMMARY':
			return {
				...state,
				dashboardSummary: action.payload
			}
		case 'SET_DASHBOARD_QUERIES':
			return {
				...state,
				dashboardQueries: action.payload
			}
		case 'SET_DASHBOARD_QUERIES_PAGE':
			let queriesData = state.dashboardQueries[action.payload.db];
			let newQueriesList = queriesData.queries.concat(action.payload.data.queries);

			let newQueriesData = action.payload.data;
			newQueriesData.queries = newQueriesList;

			return {
				...state,
				dashboardQueries: {
					...state.dashboardQueries,
					[action.payload.db]: newQueriesData
				}
			}
		case 'SET_USER_GROUP_LIST': {
			return { ...state, userGroupList: action.payload }
		}
		case 'SET_USER_GROUP_INFO': {
			return { ...state, userGroupInfo: action.payload }
		}
		case 'SET_RESOURCES_BY_USER': {
			return { ...state, resourcesByUser: action.payload }
		}
		case 'UPDATE_DOC_USER_PERMISSION': {
			return { ...state, resourcesByUser: state.resourcesByUser.map(resource => resource.file_id === action.payload.file_id ? { ...resource, ...action.payload } : resource) }
		}
		case 'UPDATE_DOCUMENT_SEARCH_DOC_STATUS': {
			let resourcesByUser = state.resourcesByUser;
			const index = resourcesByUser.findIndex((resource => resource.file_id === action.payload.file_id));

			if (index > -1)
				resourcesByUser[index] = { ...resourcesByUser[index], status: action.payload.status };
			else
				resourcesByUser = [action.payload, ...state.resourcesByUser];
			
			return {
				...state,
				resourcesByUser: resourcesByUser
			}
		}
		case 'DELETE_DOC_RESOURCES': {
			return {
				...state,
				resourcesByUser: state.resourcesByUser.filter(source => (action.payload.findIndex(deletedFile => deletedFile.file_id === source.file_id) === -1))
			}
		}
		case 'DELETE_DB_RESOURCES': {
			return {
				...state,
				dbResourcesByUser: state.dbResourcesByUser.filter(source => (action.payload.findIndex(deletedFile => deletedFile.db_id === source.db_id) === -1))
			}
		}
		case 'SET_CO_PILOT_RESOURCES_BY_USER': {
			return { ...state, coPilotResourcesByUser: action.payload }
		}
		case 'UPDATE_CO_PILOT_DOC_USER_PERMISSION': {
			return { ...state, coPilotResourcesByUser: state.coPilotResourcesByUser.map(resource => resource.file_id === action.payload.file_id ? { ...resource, ...action.payload } : resource) }
		}
		case 'UPDATE_CO_PILOT_DOC_STATUS': {
			let coPilotResourcesByUser = state.coPilotResourcesByUser;
			const index = coPilotResourcesByUser.findIndex((resource => resource.file_id === action.payload.file_id));

			if (index > -1) {
				coPilotResourcesByUser[index] = { ...coPilotResourcesByUser[index], status: action.payload.status };
			} else {
				coPilotResourcesByUser = [action.payload, ...state.coPilotResourcesByUser];
			}

			return {
				...state,
				coPilotResourcesByUser: coPilotResourcesByUser
			}
		}
		case 'DELETE_CO_PILOT_DOC_RESOURCES': {
			return {
				...state,
				coPilotResourcesByUser: state.coPilotResourcesByUser.filter(source => (action.payload.findIndex(deletedFile => deletedFile.file_id === source.file_id) === -1))
			}
		}
		case 'SET_LLM_CONFIG': {
			return {
				...state,
				llmConfig: action.payload,
			}
		}
		case 'GET_ALL_USERS': {
			return {
				...state,
				userInfo: action.payload,
			}
		}
		case 'SET_LICENSE_INFO': {
			return {
				...state,
				licenseInfo: action.payload,
			}
		}
		case 'SET_LICENSE_INFO_FETCHED': {
			return {
				...state,
				isLicenseKeyFetched: action.payload,
			}
		}
		default:
			return state;
	}
}

const AppContext = createContext();

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = useReducer(storeReducer, initialState);

	useEffect(() => {
		document.documentElement.classList.toggle('dark', state.isDarkMode);

		localStorage.theme = state.isDarkMode ? 'dark' : 'light';
	}, [state.isDarkMode])

	return (
		<AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
	)
}

export const useStore = () => {
	return useContext(AppContext);
}
