/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from 'react'
import { InputWrapper } from '../InputWrapper'
import { faAngleDown, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import TooltipWrapper from '../TooltipWrapper'
import { useDocSearch } from '../../hooks/useDocSearch'
import { useStore } from '../../store/AppContext'
import { APP_SUPPORT_INTEGRATION_TYPES, ROUTES_ID } from '../../config'
import { CheckBox } from '../CheckBox'
import { getFileTypeIcon } from './utils'
import FileUpload from './FileUpload'

export const SidePanelDocSearch = ({ isOpen, setIsOpen, handleNewChat, handleSummarize, deleteChatHistory, selectedChatId, setSelectedChatId, selectedResourceList, setSelectedResourceList }) => {
    const docSearchHook = useDocSearch();
    const integrationDropdownRef = useRef(null);
    const integrationDropdownOptionsRef = useRef(null);
    const { state, dispatch } = useStore();
    const [sourceList, setSourceList] = useState([])
    const [searchList, setSearchList] = useState([])
    const [sourceTab, setSourceTab] = useState('all');
    const [groupedChatHistoryArray, setGroupedChatHistoryArray] = useState([])
    const [searchText, setSearchText] = useState('');
    const [hoveredIndex, setHoveredIndex] = useState(null)
    const [showIntegrations, setShowIntegrations] = useState(false);
    const [showHistoryDeleteId, setShowHistoryDeleteId] = useState(null);

    useEffect(() => {
        if (state.resourcesByUser && state.resourcesByUser.length > 0) {
            state.resourcesByUser && setSourceList(state.resourcesByUser)
        }
    }, [state.resourcesByUser])

    useEffect(() => {
        if (state.docSearch.groupedHistory) {
            state.docSearch.groupedHistory && setGroupedChatHistoryArray(state.docSearch.groupedHistory)
        }
    }, [state.docSearch.groupedHistory])

    useEffect(() => {
        const handleClickOutsideIntegrations = (event) => {
            if (integrationDropdownRef.current && !integrationDropdownRef.current.contains(event.target) && integrationDropdownOptionsRef.current && !integrationDropdownOptionsRef.current.contains(event.target)) {
                setShowIntegrations(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutsideIntegrations);
        return () => document.removeEventListener("mousedown", handleClickOutsideIntegrations);
    }, [integrationDropdownRef, integrationDropdownOptionsRef])

    useEffect(() => {
        if (!state.resourcesByUser || state.resourcesByUser.length === 0) {
            docSearchHook.getDocResourcesByUser();
        }
    }, [state.user])

    useEffect(() => {
        if (state.docSearchChatHistory == null) {
            setTimeout(() => {
                docSearchHook.getDocSearchHistory();
            }, 300);
        }
    }, [])

    useEffect(() => {
        searchFunction(searchText, sourceList)
    }, [searchText])

    const handleHistory = (convID) => {
        setSelectedChatId(convID)
        docSearchHook.getDocSearchChatHistory(convID)
    }

    const onIntegrationsDropdownSelect = (option) => {
        let integrations = state.selectedIntegration;
        integrations[ROUTES_ID.DOCUMENT_SEARCH] = option;

        dispatch({
            type: 'SET_SELECTED_INTEGRATION',
            payload: integrations
        })
        setShowIntegrations(false);
    }

    const searchFunction = (query, data) => {
        const lowerCaseQuery = query.toLowerCase();
        const searchResults = data.filter(item => {
            return (
                item.fileName.toLowerCase().includes(lowerCaseQuery)
            );
        });
        setSearchList(searchResults);

        return searchResults;
    };

    const setSelected = (source) => {
        const index = selectedResourceList.indexOf(source);
        if (index > -1) {
            selectedResourceList.splice(index, 1);
        } else
            selectedResourceList.push(source);
        setSelectedResourceList([...selectedResourceList]);
    }

    const onClearSelectedFiles = () => {
        setSelectedResourceList([])
    }

    const sourcesToRender = sourceTab === 'all' && searchText.length > 0 ? searchList : sourceList;

    return (
        <div className={`bg-white dark:bg-gray-dark border-white-gray-dark-500 dark:border-gray-medium text-white ${isOpen ? 'px-4 translate-x-0 border-r-2' : '-translate-x-full border-r-0   px-0 '} transition-all ease-in-out duration-300`}>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>
                <div className={`text-gray-dark dark:text-white`}>
                    <div className='flex items-center mb-3 justify-start'>
                        <button disabled={state.isDocSearchStreaming} className={`flex h-11 justify-center items-center bg-blue round border-none text-white hover:text-white hover:bg-blue-300 font-bold px-6 py-[10px] rounded-lg disabled:bg-gray w-full mr-6`} onClick={handleNewChat}>
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="currentColor" />
                            </svg>
                            <div className='ml-2 text-base font-bold'>
                                {'New Chat'}
                            </div>
                        </button>
                        <button className={`text-gray-dark dark:text-white ${!isOpen && 'hidden'} w-6`} onClick={() => setIsOpen(!isOpen)}>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.75 2H7.875V15.5H15.75C16.3477 15.5 16.875 15.0078 16.875 14.375V3.125C16.875 2.52734 16.3477 2 15.75 2ZM2.25 2C1.61719 2 1.125 2.52734 1.125 3.125V14.375C1.125 15.0078 1.61719 15.5 2.25 15.5H6.75V2H2.25ZM2.25 0.875H15.75C16.9805 0.875 18 1.89453 18 3.125V14.375C18 15.6406 16.9805 16.625 15.75 16.625H2.25C0.984375 16.625 0 15.6406 0 14.375V3.125C0 1.89453 0.984375 0.875 2.25 0.875ZM2.8125 3.125H5.0625C5.34375 3.125 5.625 3.40625 5.625 3.6875C5.625 4.00391 5.34375 4.25 5.0625 4.25H2.8125C2.49609 4.25 2.25 4.00391 2.25 3.6875C2.25 3.40625 2.49609 3.125 2.8125 3.125ZM2.25 5.9375C2.25 5.65625 2.49609 5.375 2.8125 5.375H5.0625C5.34375 5.375 5.625 5.65625 5.625 5.9375C5.625 6.25391 5.34375 6.5 5.0625 6.5H2.8125C2.49609 6.5 2.25 6.25391 2.25 5.9375ZM2.8125 7.625H5.0625C5.34375 7.625 5.625 7.90625 5.625 8.1875C5.625 8.50391 5.34375 8.75 5.0625 8.75H2.8125C2.49609 8.75 2.25 8.50391 2.25 8.1875C2.25 7.90625 2.49609 7.625 2.8125 7.625Z" fill="currentColor" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="mt-6">
                    <div className='flex w-full justify-start gap-4 '>
                        <button onClick={() => setSourceTab("all")} className={`w-full flex justify-center border-b-2 ] pb-1 text-sm hover:text-gray-700 hover:border-gray-700 hover:dark:text-gray-700 hover:dark:border-gray-700 ${sourceTab === 'all' ? "text-gray-dark dark:text-white font-semibold border-gray-dark dark:border-white" : "text-white-gray-300 dark:text-gray border-b-white dark:border-b-gray-dark"}`}>
                            My Files
                        </button>
                        <button onClick={() => setSourceTab("history")} className={`w-full flex justify-center border-b-2 pb-1 text-sm hover:text-gray-700 hover:border-gray-700 hover:dark:text-gray-700 hover:dark:border-gray-700 ${sourceTab === 'history' ? "text-gray-dark dark:text-white font-semibold border-gray-dark dark:border-white" : "text-white-gray-300 dark:text-gray border-b-white dark:border-b-gray-dark"}`}>
                            History
                        </button>
                    </div>
                    <nav id={'doc_search_sources_view'} className='w-[254px] relative mt-2'>
                        {sourceTab === 'all' ?
                            <div className='flex flex-col gap-3'>
                                <div className='w-full flex flex-col gap-3 py-3 relative border-b-2 border-white-gray-dark-700 dark:border-gray-medium'>
                                    <p className="absolute top-2 px-1 z-10 ml-4 bg-white-500 dark:bg-gray-dark text-gray-dark-700 dark:text-white-400 text-[12px] font-normal">Integration</p>
                                    <div ref={integrationDropdownRef} className='flex flex-row gap-1 p-[10px] mt-1 items-center justify-between cursor-pointer border-2 rounded-lg border-white-gray-dark-700 dark:border-gray-medium' onClick={() => { setShowIntegrations(!showIntegrations) }}>
                                        <div className={`relative flex gap-2 items-center`}>
                                            <div className='flex w-4 h-4 items-center justify-center'>{state.selectedIntegration && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH] && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id ? state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].icon : ''}</div>
                                            <p className='w-[65%] text-nowrap text-gray-dark dark:text-white text-sm'>{state.selectedIntegration && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH] && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id ? state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].label : 'Select an Integration'}</p>
                                        </div>
                                        <FontAwesomeIcon className="w-4 h-4 text-gray-dark dark:text-white" icon={showIntegrations ? faAngleUp : faAngleDown} />
                                    </div>
                                    {showIntegrations &&
                                        <div ref={integrationDropdownOptionsRef} className={`block max-h-36 w-full pt-1 z-10 absolute left-0 top-[60px] bg-gray-900 dark:bg-gray-black overflow-auto rounded-lg`}>
                                            {APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH] && APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH].map((option, index) => !option.comingSoon &&
                                                <div id={option.id} onClick={() => onIntegrationsDropdownSelect(option)} className={`flex flex-row gap-2 p-2 m-2 cursor-pointer dark:text-white items-center justify-start ${(index < APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH].length - 1) ? '' : ''} hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.label}>
                                                    <div className={`relative md:flex w-5 h-5 items-center`}>{option.icon}</div>
                                                    <p className='text-nowrap text-gray-dark dark:text-white self-center text-sm'>{option.label}</p>
                                                </div>
                                            )}
                                        </div>
                                    }
                                    <InputWrapper value={searchText} setValue={setSearchText} name={"search"} label={"Search"} rightIcon={<FontAwesomeIcon icon={faSearch} className='py-[8px] text-gray-dark-700 dark:text-white' />} />
                                </div>
                                {sourcesToRender && sourcesToRender.length > 0 ?
                                    <>
                                        <div className='flex-row pb-3 border-b mx-2 dark:border-gray'>
                                            <div className='flex justify-between py-2 pb-3'>
                                                <p className='text-sm text-gray-dark dark:text-white'>Items Selected: {selectedResourceList.length}</p>
                                                <button disabled={state.isDocSearchStreaming || selectedResourceList.length === 0} onClick={onClearSelectedFiles} className={`text-sm ${state.isDocSearchStreaming || selectedResourceList.length === 0 ? 'text-gray cursor-default' : 'text-blue-dark'}`}>
                                                    Clear
                                                </button>
                                            </div>
                                            <div className='flex justify-start gap-4'>
                                                <button disabled={state.isDocSearchStreaming || selectedResourceList.length !== 1} onClick={handleSummarize} className={`text-xs ${state.isDocSearchStreaming || selectedResourceList.length !== 1 ? 'text-gray cursor-default' : 'text-blue-dark'}`}>
                                                    Summarize
                                                </button>
                                            </div>
                                        </div>
                                        <div className='doc-search-side-panel-source-container overflow-y-auto'>
                                            {sourcesToRender.map((source, key) => {
                                                return (
                                                    source.status === 'Completed' && source.integration === state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id &&
                                                    <div key={key} onMouseEnter={() => setHoveredIndex(key)} onMouseLeave={() => setHoveredIndex(null)} onClick={() => (hoveredIndex === key || selectedResourceList.includes(source)) && setSelected(source)} className={`flex h-11 my-2 items-center w-[250px] px-3 outline-none rounded-lg text-left text-sm text-gray-dark dark:text-white hover:bg-white-gray-500 hover:dark:bg-gray-black ${(hoveredIndex === key || selectedResourceList.includes(source)) ? 'cursor-pointer' : 'cursor-default'}`}>
                                                        <div className='w-6 flex items-center justify-center'>
                                                            {(hoveredIndex === key || selectedResourceList.includes(source)) ?
                                                                <CheckBox
                                                                    key={key}
                                                                    value={selectedResourceList.includes(source)}
                                                                    disabled={state.isDocSearchStreaming}
                                                                    className='py-0'
                                                                />
                                                                :
                                                                <FontAwesomeIcon className='py-4 pr-1 h-6 w-6' icon={getFileTypeIcon(source.fileName)} />
                                                            }
                                                        </div>
                                                        <p className={`flex items-center w-max mx-2 py-1 font-light overflow-hidden text-ellipsis text-nowrap rounded-lg text-left text-sm text-gray-dark dark:text-white hover:bg-white-gray-500 hover:dark:bg-gray-black hover:overflow-auto`}>{source.fileName}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                    :
                                    (searchText ?
                                        <div className="w-full flex flex-col items-center my-6 text-gray-dark dark:text-white">
                                            <p className='text-gray-dark dark:text-white'>No results found!</p>
                                        </div>
                                        :
                                        <div className="w-full flex flex-col items-center my-6 text-gray-dark dark:text-white">
                                            <svg width="49" height="42" viewBox="0 0 49 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M47 36.0001V12.0939C47 9.56262 44.9375 7.59387 42.5 7.59387H28.7188C27.5 7.59387 26.375 7.03137 25.5312 6.18762L22.1562 2.90637C21.3125 2.06262 20.1875 1.50012 18.9688 1.50012H6.5C3.96875 1.50012 2 3.56262 2 6.00012V36.0001C2 38.5314 3.96875 40.5001 6.5 40.5001H42.5C44.9375 40.5001 47 38.5314 47 36.0001ZM6.5 42.0001C3.125 42.0001 0.5 39.3751 0.5 36.0001V6.00012C0.5 2.71887 3.125 0.00012207 6.5 0.00012207H18.9688C20.5625 0.00012207 22.0625 0.656372 23.1875 1.78137L26.5625 5.15637C27.125 5.71887 27.875 6.00012 28.7188 6.00012H42.5C45.7812 6.00012 48.5 8.71887 48.5 12.0001V36.0001C48.5 39.3751 45.7812 42.0001 42.5 42.0001H6.5ZM25.25 33.7501C25.25 34.2189 24.875 34.5001 24.5 34.5001C24.0312 34.5001 23.75 34.2189 23.75 33.7501V16.1251L16.7188 23.0626C16.4375 23.3439 15.9688 23.3439 15.6875 23.0626C15.4062 22.7814 15.4062 22.3126 15.6875 22.0314L23.9375 13.7814C24.2188 13.5001 24.6875 13.5001 24.9688 13.7814L33.2188 22.0314C33.5 22.3126 33.5 22.7814 33.2188 23.0626C32.9375 23.3439 32.4688 23.3439 32.1875 23.0626L25.25 16.1251V33.7501Z" fill="currentColor" />
                                            </svg>
                                            <p className='text-center my-6'>Get started by adding your documents to Generate</p>
                                            <FileUpload appId={ROUTES_ID.DOCUMENT_SEARCH} allowMultiple={true} folder={false} path={'doc_search'} />
                                        </div>
                                    )
                                }
                            </div>
                            :
                            (sourceTab === 'history' ?
                                <div className='doc-search-side-panel-container mt-5 border-t-2 border-white-gray-dark-700 dark:border-gray-medium overflow-y-auto'>
                                    {groupedChatHistoryArray.sectionKey && groupedChatHistoryArray.hasData ?
                                        groupedChatHistoryArray.sectionKey.map((section, key) => {
                                            let sectionData = groupedChatHistoryArray.sectionData[section];
                                            if (sectionData && sectionData.data.length > 0) {
                                                return (
                                                    <div key={key} className='mt-4'>
                                                        <p className={`w-full text-gray-dark dark:text-white text-xs ${(key === 0) ? '' : 'pt-0'} pb-1 font-semibold`}>{sectionData.title}</p>
                                                        {sectionData.data.map((history, key) => {
                                                            return (
                                                                <TooltipWrapper content={history.conversation_name ? history.conversation_name : "Chat 1"} key={section + '_' + key}>
                                                                    <div onMouseLeave={() => setShowHistoryDeleteId(null)} onMouseEnter={() => setShowHistoryDeleteId(history.conversation_id)} className={`flex flex-row items-center w-full rounded-lg my-1 hover:bg-white-gray-400 hover:dark:bg-gray-black-400 hover:disabled:bg-none hover:disabled:dark:bg-none ${selectedChatId === history.conversation_id ? 'bg-white-gray-500 dark:bg-gray-black' : 'bg-transparent'}`}>
                                                                        <button disabled={state.isDocSearchStreaming} onClick={() => handleHistory(history.conversation_id)} className={`flex items-center h-11 py-2 px-3 font-light outline-none rounded-lg text-left text-sm text-gray-dark dark:text-white disabled:text-gray-light disabled:dark:text-gray-light w-full`} key={key}>
                                                                            <div className={`w-full overflow-hidden text-ellipsis text-nowrap`}>
                                                                                <FontAwesomeIcon className='mr-3' icon={faMessage} />
                                                                                {history.conversation_name}
                                                                            </div>
                                                                            {(showHistoryDeleteId === history.conversation_id) && !state.isStreaming && !state.isWorkFlowRunning &&
                                                                                <div onClick={() => deleteChatHistory(history.conversation_id)} className='py-2 pl-2 w-max justify-self-center text-gray-dark dark:text-white hover:text-red-500 hover:dark:text-red-500 cursor-pointer'>
                                                                                    <svg width="15" height="15" viewBox="0 0 13 15" fill='currentColor' xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.33984 2.0625C5.25781 2.0625 5.20312 2.11719 5.14844 2.17188L4.62891 2.9375H8.59375L8.07422 2.17188C8.04688 2.11719 7.96484 2.0625 7.88281 2.0625H5.33984ZM10.1797 2.9375H10.5352H11.875H12.0938C12.4492 2.9375 12.75 3.23828 12.75 3.59375C12.75 3.97656 12.4492 4.25 12.0938 4.25H11.7656L11.1094 13.1367C11.0273 14.0664 10.2891 14.75 9.35938 14.75H3.86328C2.93359 14.75 2.19531 14.0664 2.11328 13.1367L1.45703 4.25H1.15625C0.773438 4.25 0.5 3.97656 0.5 3.59375C0.5 3.23828 0.773438 2.9375 1.15625 2.9375H1.375H2.6875H3.04297L4.05469 1.43359C4.32812 1.02344 4.82031 0.75 5.33984 0.75H7.88281C8.40234 0.75 8.89453 1.02344 9.16797 1.43359L10.1797 2.9375ZM10.4531 4.25H2.76953L3.42578 13.0547C3.45312 13.2734 3.64453 13.4375 3.86328 13.4375H9.35938C9.57812 13.4375 9.76953 13.2734 9.79688 13.0547L10.4531 4.25Z" fill='currentColor' />
                                                                                    </svg>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </TooltipWrapper>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return '';
                                            }
                                        })
                                        :
                                        <p className="w-full h-[50%] flex items-center justify-center text-center text-gray-dark dark:text-white text-sm mt-4">It looks like you haven’t started a conversation with Generate yet.</p>
                                    }
                                </div>
                                :
                                ''
                            )
                        }
                    </nav>
                </div>
            </div>
        </div>
    )
}

