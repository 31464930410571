import React, { useEffect, useState } from 'react';
import { TextButton } from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../store/AppContext';
import { CONFIG_TABS_INFO, ROUTES_ID, SUB_PAGE_APPS } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Integrations from '../../components/Integrations';
import ServicePilotManageDocuments from './ServicePilotManageDocuments';
import LLMManager from '../../components/LLMManager';
import EmailManager from '../../components/EmailManager';

const EmailConfig = () => {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();
	const navigate = useNavigate();
	const { state } = useStore();
	const [tab, setTab] = useState('my-documents');

	useEffect(() => {
		const tabId = query.get('tabId');
		if (tabId && Object.keys(CONFIG_TABS_INFO).includes(tabId) && CONFIG_TABS_INFO[tab] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id][state.userRole] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id][state.userRole].includes(ROUTES_ID.SERVICE_PILOT)) {
			setTab(tabId);
		} else {
			navigate('/service-pilot/app');
		}
	}, [query]);

	const initialNav = () => {
		navigate('/service-pilot/app')
	}

	return (
		<div className="flex">
			<main className="w-full mx-auto justify-center content-center items-center place-items-center">
				<div className="flex w-full items-center mt-3 mb-6 pr-6">
					<button onClick={initialNav} className="flex flex-row fixed text-gray-dark dark:text-white justify-center ml-6 mr-2 items-center">
						<FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
						<p className="text-base font-medium">Back</p>
					</button>
					<div className='flex flex-row items-center justify-center w-full'>
						{SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS] && SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS][state.userRole].includes(ROUTES_ID.SERVICE_PILOT) &&
							<>
								<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=my-documents')} label={"My Documents"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'my-documents') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
								<span className='w-6' />
							</>
						}
						{SUB_PAGE_APPS[ROUTES_ID.EMAIL_MANAGER] && SUB_PAGE_APPS[ROUTES_ID.EMAIL_MANAGER][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.EMAIL_MANAGER][state.userRole].includes(ROUTES_ID.SERVICE_PILOT) &&
							<>
								<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=email-manager')} label={"Email Manager"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'email-manager') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
								<span className='w-6' />
							</>
						}
						{SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER] && SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER][state.userRole].includes(ROUTES_ID.SERVICE_PILOT) &&
							<>
								<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=llm-manager')} label={"LLM Manager"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'llm-manager') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
								<span className='w-6' />
							</>
						}
						{SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS] && SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS][state.userRole].includes(ROUTES_ID.SERVICE_PILOT) &&
							<TextButton addHandleFunction={() => navigate('/service-pilot/config?tabId=integrations')} label={"Integrations"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'integrations') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
						}
					</div>
				</div>
				{(tab === 'my-documents') &&
					<ServicePilotManageDocuments />
				}
				{(tab === 'email-manager') &&
					<EmailManager />
				}
				{(tab === 'llm-manager') &&
					<LLMManager appId={ROUTES_ID.SERVICE_PILOT} />
				}
				{(tab === 'integrations') &&
					<Integrations appId={ROUTES_ID.SERVICE_PILOT} integrationsList={[{ id: 'google', comingSoon: false }, { id: 'onedrive', comingSoon: false }]} />
				}
			</main>
		</div>
	)
}

export default EmailConfig;
