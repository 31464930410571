import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextButton } from '../../components/Button';
import { useConfig } from '../../hooks/useConfig';
import { useStore } from '../../store/AppContext';
import { CONFIG_TABS_INFO, ROUTES_ID, SUB_PAGE_APPS } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Integrations from '../../components/Integrations';
import DocSearchManageDocuments from './DocSearchManageDocuments';
import LLMManager from '../../components/LLMManager';

const DocSearchConfigPage = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const [tab, setTab] = useState('my-documents');
    const navigate = useNavigate();
    const configHook = useConfig();
    const { state } = useStore();

    useEffect(() => {
        const tabId = query.get('tabId');
        if (tabId && Object.keys(CONFIG_TABS_INFO).includes(tabId) && CONFIG_TABS_INFO[tab] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id][state.userRole] && SUB_PAGE_APPS[CONFIG_TABS_INFO[tab].route_id][state.userRole].includes(ROUTES_ID.DOCUMENT_SEARCH)) {
            setTab(tabId);
        } else {
            navigate('/doc-search/app');
        }
    }, [query]);

    useEffect(() => {
        configHook.getConfig(ROUTES_ID.DOCUMENT_SEARCH);
    }, [])

    const initialNav = () => {
        navigate('/doc-search/app')
    }

    return (
        <div className="flex">
            <main className="w-full mx-auto justify-center content-center items-center place-items-center">
                <div className="flex w-full items-center mt-3 mb-6 pr-6">
                    <button onClick={initialNav} className="flex flex-row fixed text-gray-dark dark:text-white justify-center ml-6 mr-2 items-center">
                        <FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
                        <p className="text-base font-medium">Back</p>
                    </button>
                    <div className='flex flex-row items-center justify-center w-full'>
                        {SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS] && SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.MY_DOCUMENTS][state.userRole].includes(ROUTES_ID.DOCUMENT_SEARCH) &&
                            <>
                                <TextButton addHandleFunction={() => navigate('/doc-search/config?tabId=my-documents')} label={"My Documents"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'my-documents') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
                                <span className='w-6' />
                            </>
                        }
                        {SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER] && SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.LLM_MANAGER][state.userRole].includes(ROUTES_ID.DOCUMENT_SEARCH) &&
                            <>
                                <TextButton addHandleFunction={() => navigate('/doc-search/config?tabId=llm-manager')} label={"LLM Manager"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'llm-manager') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
                                <span className='w-6' />
                            </>
                        }
                        {SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS] && SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS][state.userRole] && SUB_PAGE_APPS[ROUTES_ID.INTEGRATIONS][state.userRole].includes(ROUTES_ID.DOCUMENT_SEARCH) &&
                            <TextButton addHandleFunction={() => navigate('/doc-search/config?tabId=integrations')} label={"Integrations"} labelClassName={`pb-3 text-lg font-bold ${(tab === 'integrations') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-white dark:border-gray-dark '}`} />
                        }
                    </div>
                </div>
                {(tab === 'my-documents') &&
                    <DocSearchManageDocuments />
                }
                {(tab === 'llm-manager') &&
                    <LLMManager appId={ROUTES_ID.DOCUMENT_SEARCH} />
                }
                {(tab === 'integrations') &&
                    <Integrations appId={ROUTES_ID.DOCUMENT_SEARCH} integrationsList={[{ id: 'google', comingSoon: false }, { id: 'onedrive', comingSoon: false }, { id: 'slack', comingSoon: false }, { id: 'mondaydotcom', comingSoon: false }, { id: 'scrapingbee', comingSoon: true }, { id: 'confluence', comingSoon: true }, { id: 'jira', comingSoon: true }, { id: 'quickbooks', comingSoon: true }, { id: 'docusign', comingSoon: true }]} />
                }
            </main>
        </div>
    )
}

export default DocSearchConfigPage;
