import React, { useEffect, useRef, useState } from 'react';
import BottomBar from './Home/BottomBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMAIL_SERVER_OPTIONS, ROUTES_ID } from '../config';
import { useStore } from '../store/AppContext';
import { useConfig } from '../hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { InputWrapper } from './InputWrapper';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const EmailManager = () => {
    const navigate = useNavigate();
    const configHook = useConfig();
    const emailServerDropdownOptionsRef = useRef(null);
    const emailServerDropdownRef = useRef(null);
    const { state } = useStore();
    const [loading, setLoading] = useState(false);
    const [coPilotConfig, setCoPilotConfig] = useState(state.coPilotConfig ? state.coPilotConfig : {});
    const [emailConfig, setEmailConfig] = useState({});
    const [showEmailServers, setShowEmailServers] = useState('')
    const [viewPassword, setViewPassword] = useState('');
    const [canConnect, setCanConnect] = useState(false);

    useEffect(() => {
        const handleClickOutsideEmailServers = (event) => {
            if (emailServerDropdownRef.current && !emailServerDropdownRef.current.contains(event.target) && emailServerDropdownOptionsRef.current && !emailServerDropdownOptionsRef.current.contains(event.target)) {
                setShowEmailServers(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutsideEmailServers);
        return () => document.removeEventListener("mousedown", handleClickOutsideEmailServers);
    }, [emailServerDropdownRef, emailServerDropdownOptionsRef])

    useEffect(() => {
        configHook.getConfig(ROUTES_ID.SERVICE_PILOT);
    }, [])

    useEffect(() => {
        if (state.coPilotConfig && Object.keys(state.coPilotConfig).length > 0) {
            setCoPilotConfig(state.coPilotConfig);
            const emailConfigTemp = state.coPilotConfig.emailConfig || {};
            setEmailConfig({ ...emailConfigTemp, emailServer: emailConfigTemp.emailServer || EMAIL_SERVER_OPTIONS[0].value });
        }
    }, [state.coPilotConfig])

    useEffect(() => {
        setCanConnect(emailConfig && emailConfig.emailServer && emailConfig.emailPassword && emailConfig.emailId && validateEmail(emailConfig.emailId));
        setCoPilotConfig((prevData) => ({ ...prevData, emailConfig: emailConfig }));
    }, [emailConfig])

    const onEmailConfigChange = (e) => {
        console.log(e.target.value)
        setEmailConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const onEmailManagerSave = () => {
        if (canConnect) {
            const configData = {
                ...coPilotConfig,
                user_id: state.user ? state.user.user_id : null
            }

            setLoading(true);
            configHook.updateConfig(ROUTES_ID.SERVICE_PILOT, configData, true, navigate, '/service-pilot/app', null, setLoading);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onEmailManagerSave();
        }
    };

    const onEmailServerChange = (option) => {
        setShowEmailServers(false);
        setEmailConfig((prevData) => ({ ...prevData, emailServer: option.value }));
    }

    const ButtonList = [
        {
            "name": "Connect",
            "handleFunction": onEmailManagerSave,
            "disabled": !canConnect,
            "loading": loading
        }
    ]

    const RIGHT_ICON = <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7812 0.469238C15.0625 0.781738 15.0625 1.25049 14.7812 1.53174L6.53125 9.78174C6.21875 10.0942 5.75 10.0942 5.46875 9.78174L1.21875 5.53174C0.90625 5.25049 0.90625 4.78174 1.21875 4.50049C1.5 4.18799 1.96875 4.18799 2.25 4.50049L5.96875 8.21924L13.7188 0.469238C14 0.187988 14.4688 0.187988 14.75 0.469238H14.7812Z" fill="#2BB86C" />
    </svg>;

    const WRONG_ICON = <button onClick={() => setEmailConfig((prevData) => ({ ...prevData, emailId: '' }))}>
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7812 1.53174L7.03125 5.28174L10.75 9.00049C11.0625 9.28174 11.0625 9.75049 10.75 10.0317C10.4688 10.3442 10 10.3442 9.71875 10.0317L5.96875 6.31299L2.25 10.0317C1.96875 10.3442 1.5 10.3442 1.21875 10.0317C0.90625 9.75049 0.90625 9.28174 1.21875 8.96924L4.9375 5.25049L1.21875 1.53174C0.90625 1.25049 0.90625 0.781738 1.21875 0.469238C1.5 0.187988 1.96875 0.187988 2.28125 0.469238L6 4.21924L9.71875 0.500488C10 0.187988 10.4688 0.187988 10.7812 0.500488C11.0625 0.781738 11.0625 1.25049 10.7812 1.53174Z" fill="#E45051" />
        </svg>
    </button>

    return (
        <div className="flex-col w-full items-center">
            <div className={`px-6 relative overflow-auto hide-scrollbar email-manager-content-container`}>
                <main className="w-full mx-auto mt-6">
                    <div className='max-w-lg mx-auto'>
                        <h5 className='text-gray-dark dark:text-white text-md font-bold'> Email IMAP Configuration</h5>
                        <h6 className='text-gray-light dark:text-gray py-2'>Enter your credentials below to configure your email account</h6>
                        <div>
                            <div className='relative mt-4'>
                                <div ref={emailServerDropdownRef} className={`relative h-11 flex items-center justify-between border-2 py-1 px-2 border-white-gray-dark-700 dark:border-gray-medium rounded-lg cursor-pointer`} onClick={() => setShowEmailServers(!showEmailServers)}>
                                    <>
                                        <label htmlFor="emailServer" className={`absolute left-2 top-2 z-10 font-medium text-gray-600 transition-all duration-200 transform ${emailConfig.emailServer ? 'translate-y-[-1.25rem] scale-75 bg-white dark:bg-gray-dark px-1' : 'translate-y-0 scale-100 bg-none pl-1 mt-1 cursor-pointer'}`}> {emailConfig.emailServer ? "Email Server" : 'Select Email Server'}</label>
                                        <p className="h-9 px-[4px] py-[6px] text-gray-dark dark:text-white text-base text-center font-normal">{emailConfig.emailServer ? EMAIL_SERVER_OPTIONS.find(option => option.id === emailConfig.emailServer)?.label : ''}</p>
                                    </>
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.60156 6.39844L1.10156 1.89844C0.867188 1.6875 0.867188 1.33594 1.10156 1.10156C1.3125 0.890625 1.66406 0.890625 1.89844 1.10156L6 5.22656L10.1016 1.125C10.3125 0.890625 10.6641 0.890625 10.8984 1.125C11.1094 1.33594 11.1094 1.6875 10.8984 1.89844L6.375 6.39844C6.16406 6.63281 5.8125 6.63281 5.60156 6.39844Z" fill="#788493" />
                                    </svg>
                                </div>
                                {showEmailServers &&
                                    <div ref={emailServerDropdownOptionsRef} className={`w-full max-h-52 p-2 z-10 absolute right-0 rounded-lg bg-gray-900 dark:bg-gray-black hide-scrollbar overflow-auto`}>
                                        {EMAIL_SERVER_OPTIONS.map((option, index) => {
                                            return <div id={option.id} onClick={() => onEmailServerChange(option)} className={`py-2 px-2 text-gray-dark cursor-pointer dark:text-white w-full flex justify-start hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.value}>{option.label}</div>
                                        })}
                                    </div>
                                }
                            </div>
                            <form onKeyDown={handleKeyDown} onChange={onEmailConfigChange}>
                                <InputWrapper containerStyle={"mt-6"} value={emailConfig.emailId ? emailConfig.emailId : ''} validValue={emailConfig.emailId && validateEmail(emailConfig.emailId)} rightIcon={emailConfig.emailId ? (validateEmail(emailConfig.emailId) ? RIGHT_ICON : WRONG_ICON) : null} name={"emailId"} label={"Email Address"} type={"text"} />
                                <InputWrapper containerStyle={"mt-6"} value={emailConfig.emailPassword ? emailConfig.emailPassword : ''} label={"Password"} name={"emailPassword"} type={viewPassword ? "text" : "password"} autoComplete={"new-password"} rightIcon={emailConfig.emailPassword && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} />
                            </form>
                            <p className='text-gray text-sm'>For more information on getting your IMAP authentication password, please visit the following <a href='https://knowledge.workspace.google.com/kb/how-to-create-app-passwords-000009237' target="_blank" rel='noreferrer' className='text-blue underline'>link</a>.</p>
                        </div>
                    </div>
                </main>
            </div>
            <BottomBar ButtonList={ButtonList} />
        </div>
    )
}

export default EmailManager;
