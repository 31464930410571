import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useIntegration = () => {
    const { state, dispatch } = useStore();

    return {
        getIntegration: () => {
            axios
                .get(`${API_URL}/integration/` + state.user.user_id,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_INTEGRATION`,
                        payload: res.data,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateIntegration: (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(`${API_URL}/integration/save`, data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_INTEGRATION`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Authorized Successfully",
                            subtitle: "Click again to embedded your data"
                        }
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Authorization Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        saveOauth: (req) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(`${API_URL}/integration/oauth`, req,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_INTEGRATION`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Authorized Successfully",
                            subtitle: "Click again to embedded your data"
                        }
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Authorization Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        storeIntegrationData: (integration, oauth_key, data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/integration/${integration}/store`, data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            oauth_key: oauth_key
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Data Captured",
                            subtitle: "Embedding process is started"
                        }
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data Capture Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        revokeIntegrationData: (req, setEnableRevokeIcon) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            const data = { ...req, user_id: state.user.user_id }
            axios
                .post(`${API_URL}/integration/revoke`, data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_INTEGRATION`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Revoked Successfully",
                            subtitle: "Auth information revoked from database"
                        }
                    })
                    setEnableRevokeIcon && setEnableRevokeIcon(false);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Revoke Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
