import React, { useEffect, useState } from 'react';
import { InputWrapper } from './InputWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import DropdownWrapper from './DropdownWrapper';
import { APP_SUPPORT_MODEL_TYPES, MODEL_TYPES, ROUTES_ID } from '../config';
import { Button } from './Button';
import SelectModel from './SelectModel';
import { useLLMManager } from '../hooks/useLLMManager';
import ConfirmPopup from './ConfirmPopup';
import { useStore } from '../store/AppContext';

const SelectModelType = ({ index, appId, fetchedModelsData, data, onDataChange, canDelete, onDelete }) => {
    const llmManagerHook = useLLMManager();
    const { state } = useStore();
    const [selectedModelType, setSelectedModelType] = useState(data.modelType);
    const [models, setModels] = useState([]);
    const [apiKey, setApiKey] = useState('');
    const [viewApiKey, setViewApiKey] = useState(false);
    const [addedModels, setAddedModels] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState(-1);

    useEffect(() => {
        let newModels = fetchedModelsData[data.modelType?.id] ? fetchedModelsData[data.modelType?.id].models : [];
        newModels = newModels.filter(model => !state.llmModels.find(llmModel => (llmModel.model_id === model.id) && (llmModel.app_id === appId)));

        if (appId === ROUTES_ID.DATABASE_SEARCH)
            newModels = newModels.filter(model => model.id && (model.id === 'gpt-4o'));
        setModels(newModels);

        let newApiKey = fetchedModelsData[data.modelType?.id] ? fetchedModelsData[data.modelType?.id].apiKey : (data.modelType?.id === 'interplay' ? '3fj72XGcE9PK48LnMvY2QT6WRkb5A1oZ' : '');
        setApiKey(newApiKey);
    }, [fetchedModelsData, data.modelType])

    useEffect(() => {
        onDataChange(index, 'modelType', selectedModelType);
        selectedModelType && setAddedModels([{}]);
    }, [selectedModelType])

    useEffect(() => {
        onDataChange(index, 'apiKey', apiKey);
    }, [apiKey])

    useEffect(() => {
        onDataChange(index, 'models', addedModels);
    }, [addedModels])

    const onFetchModel = () => {
        llmManagerHook.fetchModels({ modelType: selectedModelType.id, apiKey: apiKey });
    }

    const onModelDataChange = (index, fieldName, value) => {
        let modelsData = addedModels[index];
        if (modelsData) {
            modelsData[fieldName] = value;
            addedModels[index] = modelsData;
            setAddedModels([...addedModels]);
        }
    }

    const onModelDelete = () => {
        if (addedModels[deleteIndex]) {
            addedModels.splice(deleteIndex, 1);
            setAddedModels([...addedModels]);
            setDeleteIndex(-1);
        }
    }

    return (
        <div key={index} className='w-full mt-2'>
            <div className='flex flex-row w-full justify-between gap-3'>
                <div className='flex items-center h-fit gap-2'>
                    <DropdownWrapper setValue={setSelectedModelType} value={data.modelType ? data.modelType.label : ''} placeholder={'LLM Type'} name={'modelType'} options={APP_SUPPORT_MODEL_TYPES[appId]} optionsClassName={'w-full h-fit'} />
                </div>
                <div className='flex w-full flex-col items-center'>
                    <InputWrapper disabled={data.modelType && (data.modelType.id === MODEL_TYPES.interplay.id)} value={apiKey ? apiKey : ''} setValue={setApiKey} label={"API Key"} name={"apiKey"} type={viewApiKey ? "text" : "password"} rightIcon={apiKey && <FontAwesomeIcon icon={viewApiKey ? faEye : faEyeSlash} onClick={() => setViewApiKey(!viewApiKey)} className="text-gray-dark dark:text-white cursor-pointer" />} containerStyle={'w-full font-bold'} autoComplete={"new-password"} />
                    {data.modelType &&
                        <>
                            {(models && models.length > 0) ?
                                <div className='w-full mt-4'>
                                    <div className='flex flex-col items-center relative w-full gap-6'>
                                        {data.models && data.models.map((model, index) => {
                                            return (
                                                <SelectModel appId={appId} key={index} index={index} models={models} data={model} onDataChange={onModelDataChange} canDelete={data.models.length > 1} onDelete={(deleteIndex) => setDeleteIndex(deleteIndex)} />
                                            )
                                        })}
                                    </div>
                                    {data.models && (models.length > data.models.length) &&
                                        <div className='w-full pt-4 mt-4 border-t border-white-gray-dark-400 dark:border-gray'>
                                            <button disabled={models.length === 0} onClick={() => setAddedModels(addedModels.concat({}))} className={`flex h-11 gap-2 justify-center items-center bg-white dark:bg-gray-dark border border-blue dark:border-blue hover:bg-blue hover:dark:bg-blue font-bold text-blue dark:text-blue hover:text-white hover:dark:text-white px-6 py-3 rounded-lg disabled:text-gray-dark disabled:dark:text-white disabled:border-gray-light hover:disabled:border-gray-light disabled:dark:border-gray-light hover:disabled:dark:border-gray-light disabled:bg-gray-light disabled:dark:bg-gray-light`}>
                                                <p>{`Connect ${data.models && (data.models.length > 0) ? 'Another' : ''} LLM`}</p>
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z" fill="currentColor" />
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                </div>
                                :
                                <div className='w-full mt-4'>
                                    <p className='text-gray-dark dark:text-white font-semibold'>Oops! No LLMs are available for the selected LLM type.</p>
                                    <p className='text-gray-dark dark:text-white text-sm'>Possible reasons:</p>
                                    <p className='ml-5 text-gray-dark dark:text-white text-sm'>- The provided API key might be incorrect or lack a valid subscription.</p>
                                    <p className='ml-5 text-gray-dark dark:text-white text-sm'>- Failed to fetch LLMs.</p>
                                    <p className='ml-5 text-gray-dark dark:text-white text-sm'>- All available LLMs are already connected to this LLM type.</p>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className='flex items-center h-fit gap-2'>
                    <Button disabled={!(data.modelType && apiKey)} name={`Fetch LLMs`} type={"outline"} handleFunction={onFetchModel} className={'w-max h-fit px-6 py-[10px]'} />
                    {canDelete &&
                        <div className='flex h-fit'>
                            <button onClick={() => onDelete(index)} className='h-11 text-gray-dark dark:text-white hover:text-red-medium hover:dark:text-red-medium'>
                                <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.22266 2.4375C6.11719 2.4375 6.04688 2.50781 5.97656 2.57812L5.30859 3.5625H10.4062L9.73828 2.57812C9.70312 2.50781 9.59766 2.4375 9.49219 2.4375H6.22266ZM12.4453 3.5625H12.9023H14.625H14.9062C15.3633 3.5625 15.75 3.94922 15.75 4.40625C15.75 4.89844 15.3633 5.25 14.9062 5.25H14.4844L13.6406 16.6758C13.5352 17.8711 12.5859 18.75 11.3906 18.75H4.32422C3.12891 18.75 2.17969 17.8711 2.07422 16.6758L1.23047 5.25H0.84375C0.351562 5.25 0 4.89844 0 4.40625C0 3.94922 0.351562 3.5625 0.84375 3.5625H1.125H2.8125H3.26953L4.57031 1.62891C4.92188 1.10156 5.55469 0.75 6.22266 0.75H9.49219C10.1602 0.75 10.793 1.10156 11.1445 1.62891L12.4453 3.5625ZM12.7969 5.25H2.91797L3.76172 16.5703C3.79688 16.8516 4.04297 17.0625 4.32422 17.0625H11.3906C11.6719 17.0625 11.918 16.8516 11.9531 16.5703L12.7969 5.25Z" fill="currentColor" />
                                </svg>
                            </button>
                        </div>
                    }
                </div>
            </div>
            <ConfirmPopup id={'new_llm_delete_popup_div'} title={"Confirm Delete"} message={"Are you sure you want to delete this LLM Type?"} confirmLabel={"Delete"} isOpen={deleteIndex > -1} onConfirm={onModelDelete} onClose={() => setDeleteIndex(-1)} />
        </div>
    )
}

export default SelectModelType;