import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { PrivateRoute } from '../components/PrivateRoute';
import Login from '../pages/Login';
import Home from '../components/Home';
import AuthConfig from '../components/AuthConfig';
import { useStore } from '../store/AppContext';
import { ROUTES, USER_ROLE } from '../config';
import ApplicationsPermission from '../pages/ApplicationsPermission';
import Subscription from '../pages/license/Subscription';
import RenewSubscription from '../pages/license/RenewSubscription';

function AppRoute() {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = useStore();

    useEffect(() => {
        if (state.isLoggedIn && state.isLicenseKeyFetched) {
            let path = '';
            if (!state.licenseKey || !state.licenseInfo)
                path = (state.userRole === USER_ROLE.SUPER_ADMIN) ? '/subscription' : '/renew-subscription';
            else if (state.licenseKey && state.licenseInfo && (state.licenseInfo?.remainingDays === 0 || !state.licenseInfo?.valid))
                path = '/renew-subscription';

            path && navigate(path);
        }
    }, [state.isLoggedIn, state.isLicenseKeyFetched])

    useEffect(() => {
        let pathName = location.pathname;

        if (location.pathname.endsWith('/') && location.pathname !== '/')
            pathName = location.pathname.slice(0, -1);

        if ((pathName !== '/dashboard') && (pathName !== '/renew-subscription') && ![USER_ROLE.SUPER_ADMIN].includes(state.userRole) && state.isLoggedIn && (state.user && !state.user.allowed_applications.includes(ROUTES[pathName])))
            (state.licenseKey && state.licenseInfo && (state.licenseInfo?.remainingDays > 0 || state.licenseInfo?.valid)) && navigate('/dashboard');
    }, [location])

    return (
        <div>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <Login />
                    }
                />
                <Route
                    path="/auth-config"
                    element={
                        <AuthConfig />
                    }
                />
                <Route
                    path="/user-access"
                    element={
                        <ApplicationsPermission />
                    }
                />
                <Route
                    path="/subscription"
                    element={
                        <Subscription />
                    }
                />
                <Route
                    path="/renew-subscription"
                    element={
                        <RenewSubscription />
                    }
                />
                <Route
                    path='/*'
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </div>
    )
}

export default AppRoute;
