import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';
import moment from "moment";

export const useDocSearch = () => {
    const { dispatch, state } = useStore();

    const setChatTitle = async (request) => {
        let reqData = {
            "user_id": request.user_id,
            "conversation_id": request.conversation_id,
            "text": request.text
        }
        axios
            .post(`${API_URL}/application/doc_search/chat_name`, reqData, {
                headers: {
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY,
                }
            })
            .then(() => {
                dispatch({
                    type: "UPDATE_DOC_CHAT_HISTORY",
                    payload: {
                        conversation_id: request.conversation_id,
                        conversation_name: request.text,
                        created: moment().format("YYYY-MM-DDThh:mm:ss.000+z")
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        type: "fail",
                        title: "Update Failed",
                        subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                    }
                })
            })
    }

    const getGeneralQAInStream = async (request, totalChat) => {
        try {
            const response = await fetch(`${API_URL}/application/doc_search/response`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY
                },
                body: JSON.stringify(request),
            })

            let text = '';
            let resData = ''
            const jsonRegex = /\{.*\}/;
            let links = null
            let FinalData = {}

            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                resData = String.fromCharCode.apply(null, value);

                if (done) {
                    FinalData = {
                        totalChat: totalChat,
                        mssg: text,
                        human_feedback: 0,
                        isStreaming: false
                    }

                    if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                        dispatch({
                            type: "SET_DOC_CHAT",
                            payload: FinalData
                        })
                    }
                    dispatch({
                        type: "CHAT_LOADING",
                        payload: false
                    })
                    dispatch({
                        type: "SET_DOC_SEARCH_STREAMING",
                        payload: false
                    })
                    break;
                }

                const match = resData.match(jsonRegex);
                if (match) {
                    const jsonString = match[0];
                    try {
                        text += resData.replace(jsonRegex, '').trim();
                        links = JSON.parse(jsonString);
                        FinalData = {
                            totalChat: totalChat,
                            mssg: text,
                            human_feedback: 0,
                            isStreaming: false,
                            sources: links.sources
                        }
                        if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                            dispatch({
                                type: "SET_DOC_CHAT",
                                payload: FinalData
                            })
                            dispatch({
                                type: "UPDATE_SOURCE_DOC_CHAT",
                                payload: links.sources
                            })
                        }
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    text += resData
                    FinalData = {
                        totalChat: totalChat,
                        mssg: text,
                        human_feedback: 0,
                        isStreaming: true
                    }

                    if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                        dispatch({
                            type: "SET_DOC_CHAT",
                            payload: FinalData
                        })
                    }
                    dispatch({
                        type: "CHAT_LOADING",
                        payload: false
                    })
                }
            }
        } catch (err) {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: false
            })
            dispatch({
                type: "CHAT_LOADING",
                payload: false
            })
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Stream Failed",
                    subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                }
            })
        }
    }

    const getSingleQAInStream = async (request, totalChat, inPopup) => {
        dispatch({
            type: inPopup ? "PDF_CHAT_LOADING" : "CHAT_LOADING",
            payload: true
        })

        try {
            const response = await fetch(`${API_URL}/application/doc_search/pdf_chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY,
                },
                body: JSON.stringify(request),
            })

            let text = '';
            let resData = ''
            const jsonRegex = /\{.*\}/;
            let links = null
            let FinalData = {}

            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                resData = String.fromCharCode.apply(null, value);

                if (done) {
                    FinalData = {
                        totalChat: totalChat,
                        mssg: text,
                        human_feedback: 0,
                        isStreaming: false
                    }
                    if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                        dispatch({
                            type: inPopup ? "SET_DOC_PDF_RESPONSE" : "SET_DOC_CHAT",
                            payload: FinalData
                        })
                    }
                    dispatch({
                        type: "SET_DOC_SEARCH_STREAMING",
                        payload: false
                    })
                    break;
                }

                const match = resData.match(jsonRegex);
                if (match) {
                    const jsonString = match[0];
                    try {
                        text += resData.replace(jsonRegex, '').trim();
                        if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                            links = JSON.parse(jsonString);
                            dispatch({
                                type: "UPDATE_SOURCE_DOC_CHAT",
                                payload: links.sources
                            })
                        }
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    text += resData

                    FinalData = {
                        totalChat: totalChat,
                        mssg: text,
                        human_feedback: 0,
                        isStreaming: true
                    }

                    if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                        dispatch({
                            type: inPopup ? "SET_DOC_PDF_RESPONSE" : "SET_DOC_CHAT",
                            payload: FinalData
                        })
                    }
                    dispatch({
                        type: inPopup ? "PDF_CHAT_LOADING" : "CHAT_LOADING",
                        payload: false
                    })
                }
            }
        } catch (err) {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: false
            })
            dispatch({
                type: inPopup ? "PDF_CHAT_LOADING" : "CHAT_LOADING",
                payload: false
            })
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Stream Failed",
                    subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                }
            })
        }
    }

    const getSummaryInStream = async (request, totalChat) => {
        dispatch({
            type: "CHAT_LOADING",
            payload: true
        })

        try {
            const response = await fetch(`${API_URL}/application/doc_search/summery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get("token"),
                    apikey: API_KEY,
                },
                body: JSON.stringify(request),
            })

            const reader = response.body.getReader();
            let text = '';
            let resData = '';

            while (true) {
                const { done, value } = await reader.read();
                resData = String.fromCharCode.apply(null, value)

                if (done) {
                    if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                        dispatch({
                            type: "SET_DOC_SUMMERY",
                            payload: { mssg: text, totalChat: totalChat, isStreaming: false }
                        })
                    }
                    dispatch({
                        type: "SET_DOC_SEARCH_STREAMING",
                        payload: false
                    })
                    break;
                }
                text += resData

                if (!state.docSearchCurrentConversation || (state.docSearchCurrentConversation === request.conversation_id)) {
                    dispatch({
                        type: "SET_DOC_SUMMERY",
                        payload: { mssg: text, totalChat: totalChat, isStreaming: true }
                    })
                }
                dispatch({
                    type: "CHAT_LOADING",
                    payload: false
                })
            }
        } catch (err) {
            console.log(err)
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: false
            })
            dispatch({
                type: "CHAT_LOADING",
                payload: false
            })
            dispatch({
                type: "SET_MESSAGE",
                payload: {
                    type: "fail",
                    title: "Stream Failed",
                    subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                }
            })
        }
    }

    const getDocSearchHistory = () => {
        dispatch({
            type: "LOADING",
            payload: true,
        })
        let user = JSON.parse(Cookies.get("user"))
        axios
            .post(
                `${API_URL}/application/doc_search/history`,
                {
                    "user_id": user.user_id,
                },
                {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    }
                }
            )
            .then((res) => {
                dispatch({
                    type: "SET_DOC_HISTORY",
                    payload: res.data
                })
                dispatch({
                    type: "LOADING",
                    payload: false,
                })
            })
            .catch((err) => {
                dispatch({
                    type: "LOADING",
                    payload: false,
                })
                dispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        type: "fail",
                        title: "Data Fetch Failed",
                        subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                    }
                })
            })
    }

    return {
        getGeneralQA: (request, totalChat) => {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: true
            })

            if (request.conversation_id !== null) {
                getGeneralQAInStream(request, totalChat)
            } else {
                axios
                    .post(`${API_URL}/application/doc_search/new_chat`, { "user_id": request.user_id }, {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    })
                    .then(async (res) => {
                        request.conversation_id = res.data.conversation_id;
                        dispatch({
                            type: "SET_DOC_CONVO_ID",
                            payload: request.conversation_id
                        })

                        await setChatTitle(request);
                        await getGeneralQAInStream(request, totalChat);
                    })
                    .catch((err) => {
                        dispatch({
                            type: "SET_DOC_SEARCH_STREAMING",
                            payload: false
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Create Failed",
                                subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                            }
                        })
                    })
            }
        },
        getSingleQA: async (request, totalChat, inPopup) => {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: true
            })

            if (request.conversation_id !== null) {
                getSingleQAInStream(request, totalChat, inPopup)
            } else {
                axios
                    .post(`${API_URL}/application/doc_search/new_chat`, { "user_id": request.user_id }, {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    })
                    .then(async (res) => {
                        request.conversation_id = res.data.conversation_id;
                        dispatch({
                            type: "SET_DOC_CONVO_ID",
                            payload: request.conversation_id
                        })

                        await setChatTitle(request);
                        await getSingleQAInStream(request, totalChat, inPopup);
                    })
                    .catch((err) => {
                        dispatch({
                            type: "SET_DOC_SEARCH_STREAMING",
                            payload: false
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Create Failed",
                                subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                            }
                        })
                    })
            }
        },
        getSummary: async (request, totalChat) => {
            dispatch({
                type: "SET_DOC_SEARCH_STREAMING",
                payload: true
            })

            if (request.conversation_id !== null) {
                getSummaryInStream(request, totalChat)
            } else {
                axios
                    .post(`${API_URL}/application/doc_search/new_chat`, { "user_id": request.user_id }, {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    })
                    .then(async (res) => {
                        request.conversation_id = res.data.conversation_id;
                        dispatch({
                            type: "SET_DOC_CONVO_ID",
                            payload: request.conversation_id
                        })

                        await setChatTitle(request);
                        await getSummaryInStream(request, totalChat);
                    })
                    .catch((err) => {
                        dispatch({
                            type: "SET_DOC_SEARCH_STREAMING",
                            payload: false
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Create Failed",
                                subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                            }
                        })
                    })
            }
        },
        updateDocSearchUserFeedback: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/application/doc_search/feedback`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getDocSearchHistory: () => {
            getDocSearchHistory();
        },
        getDocSearchChatHistory: (convID) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            let user = JSON.parse(Cookies.get("user"))
            axios
                .post(
                    `${API_URL}/application/doc_search/history/` + convID,
                    {
                        "user_id": user.user_id,
                    },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    try {
                        let chatData = res.data
                        let chatArray = []
                        chatData.forEach(element => {
                            chatArray.push({
                                'user': {
                                    'mssg': element['human_input']
                                }
                            })

                            let validJsonString = element["sources"].replace(/'/g, '"');
                            let sources = ''

                            try {
                                if (validJsonString !== "None") {
                                    sources = JSON.parse(validJsonString)
                                }
                            } catch (e) {
                                console.log(e);
                            }

                            chatArray.push({
                                'bot': {
                                    'mssg': element['output'],
                                    'sources': sources,
                                    'human_feedback': element['human_feedback']
                                }
                            })
                        })

                        dispatch({
                            type: "SET_DOC_CHAT_HISTORY",
                            payload: {
                                chatArray: chatArray,
                                convID: convID
                            }
                        })
                    } catch (error) {
                        dispatch({
                            type: "SET_DOC_CHAT_HISTORY",
                            payload: {
                                chatArray: [],
                                convID: convID
                            }
                        })
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data Fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getDocResourcesByUser: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(
                    `${API_URL}/application/doc_search/get_pdf_user_details`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: "SET_RESOURCES_BY_USER",
                            payload: res.data,
                        })
                    }
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data Fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateDocUserPermission: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/application/doc_search/update_document_permission`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Updated Successfully",
                            subtitle: "Document permission updated successfully"
                        }
                    })
                    dispatch({
                        type: "UPDATE_DOC_USER_PERMISSION",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })

                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteDocuments: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/application/doc_search/delete_document`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data && res.data.status === 'success') {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Deleted Successfully",
                                subtitle: "Your document deleted successfully"
                            }
                        })
                        dispatch({
                            type: "DELETE_DOC_RESOURCES",
                            payload: res.data.file_info,
                        })
                    }

                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Delete Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteChatHistory: (id) => {
            let user = JSON.parse(Cookies.get("user"))
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/application/doc_search/history/delete/${id}`, { user_id: user.user_id, }, {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    }
                })
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Deleted Successfully",
                                subtitle: "Chat history has beed deleted successfully"
                            }
                        })
                        getDocSearchHistory();
                    }

                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Delete Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
