import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { APP_ROUTE_PATHS, APP_SUPPORT_MODEL_TYPES, MODEL_TYPES } from '../config';
import BottomBar from './Home/BottomBar';
import SelectModelType from './SelectModelType';
import { useStore } from '../store/AppContext';
import { useLLMManager } from '../hooks/useLLMManager';
import ConfirmPopup from './ConfirmPopup';

const NewLLM = ({ appId }) => {
    const navigate = useNavigate();
    const llmManagerHook = useLLMManager();
    const { state } = useStore();
    const [fetchedModelsData, setFetchedModelsData] = useState(state.llmModelsData);
    const [addedModelsData, setAddedModelsData] = useState([{}])
    const [deleteIndex, setDeleteIndex] = useState(-1);
    const [canConnect, setCanConnect] = useState(false);

    useEffect(() => {
        setFetchedModelsData(state.llmModelsData);
    }, [state.llmModelsData])

    useEffect(() => {
        let connect = true;
        addedModelsData.forEach(addedModelData => {
            if (addedModelData.modelType) {
                addedModelData.models.forEach(model => {
                    if (!(model && model.model))
                        connect = false;
                })
            } else {
                connect = false;
            }
        })
        setCanConnect(connect);
    }, [addedModelsData])

    const initialNav = () => {
        navigate(`${APP_ROUTE_PATHS[appId]}/config?tabId=llm-manager`);
    }

    const onConnectLLM = () => {
        setAddedModelsData(addedModelsData.concat({}));
    }

    const onDataChange = (index, fieldName, value) => {
        let data = addedModelsData[index];
        data[fieldName] = value;
        addedModelsData[index] = data;
        setAddedModelsData([...addedModelsData]);
    }

    const onDelete = () => {
        addedModelsData.splice(deleteIndex, 1);
        setAddedModelsData([...addedModelsData]);
        setDeleteIndex(-1);
    }

    const onConnect = () => {
        llmManagerHook.saveModels({ appId: appId, modelsData: addedModelsData }, initialNav);
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex w-full items-center px-6 mt-3 mb-3">
                <button onClick={initialNav} className="flex flex-row text-gray-dark dark:text-white justify-centerS items-center">
                    <FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
                    <p className="text-base font-medium">Back</p>
                </button>
            </div>
            <main className="w-full mx-auto px-6 pb-3 justify-center add-llm-container overflow-auto items-center place-items-center">
                <div className='flex flex-col items-center relative w-full mb-6 gap-8'>
                    {addedModelsData.map((addedModelType, index) => {
                        return (
                            <SelectModelType key={index} index={index} appId={appId} fetchedModelsData={fetchedModelsData} data={addedModelType} onDataChange={onDataChange} canDelete={addedModelsData.length > 1} onDelete={(deleteIndex) => setDeleteIndex(deleteIndex)} />
                        )
                    })}
                </div>
                {(addedModelsData.length < Object.keys(MODEL_TYPES).length) && (APP_SUPPORT_MODEL_TYPES[appId].length > 1) &&
                    <div className='w-full pt-6 border-t border-white-gray-dark-400 dark:border-gray'>
                        <button onClick={onConnectLLM} className={`flex h-11 gap-2 justify-center items-center bg-white dark:bg-gray-dark border border-blue dark:border-blue hover:bg-blue hover:dark:bg-blue font-bold text-blue dark:text-blue hover:text-white hover:dark:text-white px-6 py-3 rounded-lg`}>
                            <p className=''>Connect Another LLM Type</p>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z" fill="currentColor" />
                            </svg>
                        </button>
                    </div>
                }
            </main>
            <BottomBar
                ButtonList={[]}
                Component={
                    <button disabled={!canConnect} onClick={onConnect} className={`flex h-11 gap-2 justify-center items-center bg-blue round  border-none text-white hover:text-white hover:bg-blue-300 font-bold px-6 py-3 rounded-lg disabled:bg-gray-700`}>
                        <p className='font-bold text-white'>Connect</p>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.75 7.5625L8.25 12.8125C7.9375 13.0938 7.46875 13.0938 7.1875 12.7812C6.90625 12.4688 6.90625 12 7.21875 11.7188L11.375 7.75H0.75C0.3125 7.75 0 7.4375 0 7C0 6.59375 0.3125 6.25 0.75 6.25H11.375L7.21875 2.3125C6.90625 2.03125 6.90625 1.53125 7.1875 1.25C7.46875 0.9375 7.96875 0.9375 8.25 1.21875L13.75 6.46875C13.9062 6.625 14 6.8125 14 7C14 7.21875 13.9062 7.40625 13.75 7.5625Z" fill="white" />
                        </svg>
                    </button>
                }
            />
            <ConfirmPopup id={'llm_type_delete_popup_div'} title={"Confirm Delete"} message={"Are you sure you want to delete this LLM Type?"} confirmLabel={"Delete"} isOpen={deleteIndex > -1} onConfirm={onDelete} onClose={() => setDeleteIndex(-1)} />
        </div>
    )
}

export default NewLLM;
