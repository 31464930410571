import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EmailConfig from './EmailConfig';
import EmailCoPilot from './EmailCoPilot';
import SingleEmail from './SingleEmail';
import NewLLM from '../../components/NewLLM';
import { ROUTES_ID } from '../../config';
import EditLLM from '../../components/EditLLM';

const CoPilotPage = ({ activeLLM }) => {
	return (
		<Routes>
			<Route path='/app' element={<EmailCoPilot activeLLM={activeLLM} />} />
			<Route path='/config' element={<EmailConfig />} />
			<Route path='/email' element={<SingleEmail activeLLM={activeLLM} />} />
			<Route path='/new-llm' element={<NewLLM appId={ROUTES_ID.SERVICE_PILOT} />} />
			<Route path='/edit-llm' element={<EditLLM appId={ROUTES_ID.SERVICE_PILOT} />} />
		</Routes>
	)
}

export default CoPilotPage;
