import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from '../Button';
import { APP_SUPPORT_INTEGRATION_TYPES } from '../../config';

const PickIntegration = ({ appId, parameters, loading, isOpen, onClose, onSubmit }) => {
    const [integration, setIntegration] = useState(null);

    useEffect(() => {
        const popupDiv = document.getElementById('workflow_pick_integration_popup_div');
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    const onEnter = () => {
        parameters.integration = integration
        onSubmit(parameters, 'integration');
        setTimeout(() => {
            setIntegration(null)
        }, 500);
    }

    const handleClose = () => {
        onClose();
        setIntegration(null)
    }

    return (
        <div id={'workflow_pick_integration_popup_div'} className={`absolute inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} -mt-16 bg-white-500 dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray drop-shadow-xl p-8 rounded-3xl z-10`}>
                <div className='flex flex-row justify-between'>
                    <p className='flex w-full justify-center font-medium text-gray-dark dark:text-white pb-4'>Pick your integration</p>
                    <button disabled={loading} className="flex text-gray-dark dark:text-white" onClick={handleClose} >
                        <FontAwesomeIcon className='w-6 h-6' icon={faClose} />
                    </button>
                </div>
                <div>
                    <div className="grid grid-cols-3 gap-4 py-[1px] max-h-[250px] overflow-y-auto pr-1">
                        {APP_SUPPORT_INTEGRATION_TYPES[appId].map((appIntegration, index) =>
                            !appIntegration.comingSoon &&
                            <div key={index} className='grid grid-cols-1'>
                                <button onClick={() => setIntegration(appIntegration.id)} className={`p-3 flex flex-col justify-center items-center rounded-xl ${(integration === appIntegration.id) ? 'bg-white-gray-700 dark:bg-gray-black' : ''} border border-white-gray-400 dark:border-gray-400`}>
                                    <div className={`relative md:flex w-8 h-12 justify-center items-center`}>
                                        {appIntegration.icon}
                                    </div>
                                    <p className='text-sm font-bold text-gray-dark dark:text-white pt-1'>{appIntegration.label}</p>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='flex w-full justify-end'>
                        <Button disabled={!integration} loading={loading} className={"self-center mt-4 py-[8px] gap-2 flex flex-row items-center justify-center content-center"} handleFunction={onEnter} name={"Next"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickIntegration;