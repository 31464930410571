import React from 'react';
import './../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const CheckBox = ({ setValue, value, label, name, className, labelClassName, disabled, icon }) => {
	return (
		<div className={`flex flex-row items-center my-4 ${className}`}>
			<label className="flex relative">
				<input
					type="checkbox"
					id={name}
					name={name}
					checked={value ? value : false}
					disabled={disabled}
					onChange={() => setValue && setValue(!value)}
					className={`peer shrink-0 rounded appearance-none w-6 h-6 border-gray-700 dark:border-gray bg-white-gray dark:bg-gray checked:bg-blue checked:dark:bg-blue disabled:checked:bg-gray-light disabled:checked:dark:bg-gray-light border cursor-pointer disabled:cursor-default focus:outline-none focus:ring-offset-0`}
				/>
				<FontAwesomeIcon className="absolute w-4 h-4 px-1 pointer-events-none text-white-gray fill-none mt-1 hidden peer-checked:block" icon={icon ? icon : faCheck} />
			</label>
			{label && (
				<span className={`text-gray-dark-500 dark:text-white ml-3 flex-1 overflow-hidden text-ellipsis whitespace-nowrap ${labelClassName}`}>
					{label}
				</span>
			)}
		</div>
	);
}

export const SourceCheckbox = ({ options, selectedOption, selectedOptionIndex, onChange, className, type }) => {
	return (
		<div>
			{options.map((option, index) => (
				<div className='flex gap-2 my-3' key={index}>
					{console.log(selectedOption ? `${index}_${selectedOption.file_id}` === `${index}_${option.file_id}` : false)}
					<input
						type={type ? type : "checkbox"}
						id={option.file_id}
						checked={selectedOption ? (`${selectedOptionIndex}_${selectedOption.file_id}` === `${index}_${option.file_id}`) : false}
						onChange={() => onChange(option, index)}
						className={`w-5 h-5 mt-[1.5px] ${className}`}
					/>
					<label className="text-gray-dark dark:text-white">{option.file_name}</label>
				</div>
			))}
		</div>
	)
}

export const DocUserPermissionCheckbox = ({ options, field, selectedOptions, onChange, className, labelClassName, type }) => {
	return (
		<div>
			{options.map((option, key) => (
				<div className='flex gap-1 my-3' key={key}>
					<label className="flex relative">
						<input
							type={type ? type : "checkbox"}
							id={option.name}
							checked={selectedOptions && selectedOptions.includes(option[field])}
							onChange={() => onChange(option)}
							className={`peer shrink-0 rounded appearance-none w-6 h-6 border-gray-700 dark:border-gray bg-white-gray dark:bg-gray checked:bg-blue checked:dark:bg-blue disabled:checked:bg-gray-light disabled:checked:dark:bg-gray-light border cursor-pointer disabled:cursor-default focus:outline-none focus:ring-offset-0 ${className}`}
						/>
						<FontAwesomeIcon className="absolute w-4 h-4 px-1 pointer-events-none text-white-gray fill-none mt-1 hidden peer-checked:block" icon={faCheck} />
					</label>
					<span className={`text-gray-dark-500 dark:text-white ml-3 flex-1 overflow-hidden text-ellipsis whitespace-nowrap ${labelClassName}`}>
						{option.name}
					</span>
					
				</div>
			))}
		</div>
	)
}
