import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { APP_ROUTE_PATHS } from '../config';
import BottomBar from './Home/BottomBar';
import { useLLMManager } from '../hooks/useLLMManager';
import { InputRangeWrapper, InputWrapper, InputWrapper2 } from './InputWrapper';

const EditLLM = ({ appId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const llmManagerHook = useLLMManager();
    const [modelData, setModelData] = useState(location.state && location.state.modelData ? location.state.modelData : {});
    const [viewApiKey, setViewApiKey] = useState(false);
    const [apiKey, setApiKey] = useState(modelData.api_key ? modelData.api_key : '');
    const [tempature, setTempature] = useState(modelData.tempature ? modelData.tempature : '');
    const [instruction, setInstruction] = useState(modelData.custom_instruction ? modelData.custom_instruction : '');

    useEffect(() => {
        setModelData({ ...modelData, api_key: apiKey, tempature: tempature, custom_instruction: instruction });
    }, [apiKey, tempature, instruction])

    const initialNav = () => {
        navigate(`${APP_ROUTE_PATHS[appId]}/config?tabId=llm-manager`);
    }

    const onUpdate = () => {
        llmManagerHook.updateModels(modelData, initialNav);
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex w-full items-center px-6 mt-3 mb-3">
                <button onClick={initialNav} className="flex flex-row text-gray-dark dark:text-white justify-centerS items-center">
                    <FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
                    <p className="text-base font-medium">Back</p>
                </button>
            </div>
            <main className="w-full mx-auto px-6 pt-3 add-llm-container overflow-auto place-items-center">
                <div className='flex flex-col w-full mb-6 gap-4'>
                    <div className='flex w-full items-center gap-4'>
                        <InputWrapper disabled value={modelData.model_type_name ? modelData.model_type_name : ''} label={"LLM Type"} name={"modet_type"} type={"text"} containerStyle={'w-full font-bold'} />
                        <InputWrapper disabled value={modelData.model_name ? modelData.model_name : ''} label={"LLM Name"} name={"model_name"} type={"text"} containerStyle={'w-full font-bold'} />
                    </div>
                    <div className='py-2'>
                        <InputRangeWrapper value={tempature ? parseFloat(tempature) * 100 : 50} setValue={setTempature} label={"LLMs' Creativity (0 - 1)"} rangeSpanData={[0, 1]} name={"tempature"} containerStyle={'w-full font-bold'} />
                    </div>
                    <div className='w-full'>
                        <InputWrapper2 value={instruction ? instruction : ''} setValue={setInstruction} name={"instructions"} type={"textarea"} placeholder={`Enter instructions here...`} rows={5} />
                        <p className='text-xs text-gray ml-2 mt-1'>Configure rules for generated responses from uploaded documents</p>
                    </div>
                </div>
            </main>
            <BottomBar
                ButtonList={[]}
                Component={
                    <button onClick={onUpdate} className={`flex h-11 gap-2 justify-center items-center bg-blue round  border-none text-white hover:text-white hover:bg-blue-400 font-bold px-6 py-3 rounded-lg disabled:bg-gray`}>
                        <p className='font-bold text-white'>Update</p>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.75 7.5625L8.25 12.8125C7.9375 13.0938 7.46875 13.0938 7.1875 12.7812C6.90625 12.4688 6.90625 12 7.21875 11.7188L11.375 7.75H0.75C0.3125 7.75 0 7.4375 0 7C0 6.59375 0.3125 6.25 0.75 6.25H11.375L7.21875 2.3125C6.90625 2.03125 6.90625 1.53125 7.1875 1.25C7.46875 0.9375 7.96875 0.9375 8.25 1.21875L13.75 6.46875C13.9062 6.625 14 6.8125 14 7C14 7.21875 13.9062 7.40625 13.75 7.5625Z" fill="white" />
                        </svg>
                    </button>
                }
            />
        </div>
    )
}

export default EditLLM;
