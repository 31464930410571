import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from '../CheckBox';

const ContentPicker = ({ integrationId, fieldId, fieldName, data, handlePick, isOpen, onClose }) => {
    const [pickedContents, setPickedContents] = useState([]);

    useEffect(() => {
        const popupDiv = document.getElementById(`${integrationId}_contents_picker_popup_div`);
        if (isOpen) {
            setPickedContents([]);
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    const handleClose = () => {
        onClose();
    }

    const setSelected = (data) => {
        const index = pickedContents.indexOf(data);
        if (index > -1)
            pickedContents.splice(index, 1);
        else
            pickedContents.push(data);

        setPickedContents([...pickedContents]);
    }

    const onPick = (type) => {
        let finalContents = [];

        if (type === 'ALL') {
            data.contents.forEach(c => {
                finalContents.push(c[fieldId]);
            });
        } else {
            pickedContents.forEach(p => {
                finalContents.push(p[fieldId]);
            })
        }

        handlePick(finalContents);
    }

    return (
        <div id={`${integrationId}_contents_picker_popup_div`} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '}text-gray-dark dark:text-white bg-white-500 dark:bg-gray-dark border border-white-gray-400 dark:border-gray max-w-[600px] drop-shadow-xl p-6 rounded-2xl z-10`}>
                <div className='font-light relative p-4'>
                    <button className="fixed right-8 top-4 text-2xl focus:outline-none" onClick={handleClose} >
                        <FontAwesomeIcon icon="xmark" />
                    </button>
                    <div className='flex flex-col items-center justify-center mb-3'>
                        {data && data.logo ? data.logo : ''}
                        <h4 className='flex w-full justify-center text-xl mt-4'>{data && data.title ? data.title : ''}</h4>
                    </div>
                    <div className='mt-4 min-w-[350px] max-h-[300px] overflow-auto hide-scrollbar'>
                        {data && data.contents && data.contents.map((d, index) =>
                            <div key={index} className={`flex flex-row items-center ${(index > 0) ? ' mt-2 ' : ' '}`}>
                                <CheckBox
                                    value={pickedContents.includes(d)}
                                    setValue={() => setSelected(d)}
                                    name={"isSelectAll"}
                                    label={''}
                                    className='mt-1 mb-1 ml-3 mr-4'
                                />
                                <p key={index}>{d[fieldName]}</p>
                            </div>
                        )}
                    </div>
                    <div className='gap-6 mt-6 flex justify-center'>
                        <Button className={"w-full"} handleFunction={() => onPick('ALL')} disabled={!(data && data.contents && data.contents.length > 0)} name={"Pick All"} />
                        <Button className={"w-full"} handleFunction={() => onPick('SELECTED')} disabled={pickedContents.length === 0} name={"Pick Selected"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentPicker;
