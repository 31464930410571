import React, { useEffect, useState } from 'react';
import { InputWrapper } from './InputWrapper';
import DropdownWrapper from './DropdownWrapper';
import FileUpload from './DocSearch/FileUpload';
import { ACCEPTED_FILE_TYPE, DATABASE_TYPES, DATABASE_TYPES_DICT, DATABASES_USER_GUIDES, ROUTES_ID } from '../config';
import { Button } from './Button';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DBConfigWrapper = ({ index, dbConfig, setDBConfig, onSave, loading, setLoading }) => {
    const [databaseType, setDatabaseType] = useState({});
    const [config, setConfig] = useState([]);
    const [databaseName, setDatabaseName] = useState('');
    const [enableFileUpload, setEnableFileUpload] = useState(true)
    const [enableSaveButton, setEnableSaveButton] = useState(true);
    const [viewPassword, setViewPassword] = useState('');

    useEffect(() => {
        if (config && Object.keys(config).length > 0) {
            let prevDBConfig = dbConfig.database ? dbConfig.database : [];
            prevDBConfig[index] = config;
            setDBConfig((prevData) => ({ ...prevData, database: prevDBConfig }));
            setEnableSaveButton(
                ([DATABASE_TYPES_DICT.mysql, DATABASE_TYPES_DICT.postgresql, DATABASE_TYPES_DICT.redshift].includes(databaseType.id) && config.databaseName && config.host && config.port && config.dbUsername && config.password)
                || ([DATABASE_TYPES_DICT.snowflake].includes(databaseType.id) && config.databaseName && config.account && config.warehouse && config.schema && config.dbUsername && config.password)
            );
            setEnableFileUpload(
                ([DATABASE_TYPES_DICT.csv, DATABASE_TYPES_DICT.excel].includes(databaseType.id) && config.databaseName)
                || ([DATABASE_TYPES_DICT.bigquery].includes(databaseType.id) && config.databaseName && config.projectName)
            )
        }
    }, [config])

    useEffect(() => {
        databaseType && setConfig((prevData) => ({ ...prevData, databaseType: databaseType }));
        setDatabaseName('');
    }, [databaseType])

    const onChange = (e) => {
        setConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    const onDatabaseNameChanged = (value) => {
        setDatabaseName(value)
        setConfig((prevData) => ({ ...prevData, databaseName: value }));
    }

    return (
        <div className='my-2 w-full'>
            <form onChange={onChange} className='flex w-full flex-col gap-4'>
                <div className='flex w-full justify-between gap-4'>
                    <div className='h-fit'>
                        <DropdownWrapper placeholder="Database" options={DATABASE_TYPES} value={databaseType ? databaseType.label : ''} setValue={setDatabaseType} optionsClassName={'w-full max-h-[182px]'} className={'min-w-60'} name={'databaseType'} type={"text"} />
                    </div>
                    <div className='flex flex-col w-full gap-4'>
                        {databaseType && databaseType.id && [DATABASE_TYPES_DICT.csv, DATABASE_TYPES_DICT.excel].includes(databaseType.id) ?
                            <InputWrapper setValue={(value) => onDatabaseNameChanged(value)} value={databaseName} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'w-full'} />
                            :
                            <InputWrapper value={config['databaseName'] ? config['databaseName'] : ''} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'w-full'} />
                        }
                        {databaseType && databaseType.id && [DATABASE_TYPES_DICT.mysql, DATABASE_TYPES_DICT.postgresql, DATABASE_TYPES_DICT.redshift].includes(databaseType.id) &&
                            <>
                                <div className='flex w-full items-center gap-4'>
                                    <InputWrapper value={config['host'] ? config['host'] : ''} label={"Database Host"} name={'host'} type={"text"} containerStyle={'w-3/4'} />
                                    <InputWrapper value={config['port'] ? config['port'] : ''} label={"Database Port"} name={'port'} type={"text"} containerStyle={'w-1/4'} />
                                </div>
                                <div className='flex w-full items-center gap-4'>
                                    <InputWrapper value={config['dbUsername'] ? config['dbUsername'] : ''} label={"Username"} name={'dbUsername'} type={"text"} containerStyle={'w-full'} />
                                    <InputWrapper value={config['password'] ? config['password'] : ''} label={"Password"} name={"password"} type={viewPassword ? "text" : "password"} rightIcon={config['password'] && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} autoComplete={'new-password'} containerStyle={'w-full'} />
                                </div>
                                <p className='text-gray -mt-2 text-sm'>For more information on retrieving your Database details, please visit the following <a href={DATABASES_USER_GUIDES[databaseType.id]} rel='noreferrer' target="_blank" className='text-blue underline'>link</a>.</p>
                            </>
                        }
                        {databaseType && databaseType.id && [DATABASE_TYPES_DICT.bigquery].includes(databaseType.id) &&
                            <>
                                <InputWrapper value={config['projectName'] ? config['projectName'] : ''} label={"Project Name"} name={'projectName'} type={"text"} />
                                <p className='text-gray -mt-2 text-sm'>For more information on retrieving your Database details, please visit the following <a href={DATABASES_USER_GUIDES[databaseType.id]} rel='noreferrer' target="_blank" className='text-blue underline'>link</a>.</p>
                            </>
                        }
                        {databaseType && databaseType.id && [DATABASE_TYPES_DICT.snowflake].includes(databaseType.id) &&
                            <>
                                <div className='flex w-full items-center gap-4'>
                                    <InputWrapper value={config['account'] ? config['account'] : ''} label={"Database Account"} name={'account'} type={"text"} containerStyle={'w-2/3'} />
                                    <InputWrapper value={config['warehouse'] ? config['warehouse'] : ''} label={"Database Warehouse"} name={'warehouse'} type={"text"} containerStyle={'w-1/3'} />
                                </div>
                                <InputWrapper value={config['schema'] ? config['schema'] : ''} label={"Schema"} name={"schema"} type={"text"} />
                                <div className='flex w-full items-center gap-4'>
                                    <InputWrapper value={config['dbUsername'] ? config['dbUsername'] : ''} label={"Username"} name={'dbUsername'} type={"text"} containerStyle={'w-full'} />
                                    <InputWrapper value={config['password'] ? config['password'] : ''} label={"Password"} name={"password"} type={viewPassword ? "text" : "password"} rightIcon={config['password'] && <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} onClick={() => setViewPassword(!viewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} autoComplete={'new-password'} containerStyle={'w-full'} />
                                </div>
                                <p className='text-gray -mt-2 text-sm'>For more information on retrieving your Database details, please visit the following <a href={DATABASES_USER_GUIDES[databaseType.id]} rel='noreferrer' target="_blank" className='text-blue underline'>link</a>.</p>
                            </>
                        }
                    </div>
                    {databaseType && databaseType.id && [DATABASE_TYPES_DICT.csv, DATABASE_TYPES_DICT.excel, DATABASE_TYPES_DICT.bigquery].includes(databaseType.id) ?
                        <FileUpload setLoading={setLoading} loading={loading} disabled={!enableFileUpload} appId={ROUTES_ID.DATABASE_SEARCH} buttonType={'fill'} name={'Upload a file'} acceptFiles={ACCEPTED_FILE_TYPE[databaseType.id]} allowMultiple={false} folder={false} path={'db_search'} dbConfig={dbConfig} setDBConfig={setDBConfig} className={'w-[182.5px] border-2'} />
                        :
                        <Button loading={loading} disabled={!enableSaveButton} name={"Save"} handleFunction={onSave} type={"fill"} className={'w-[250px] h-fit py-[10px]'} />
                    }
                </div>
            </form>
        </div>
    )
}

export default DBConfigWrapper;
