import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useLLMManager = () => {
    const { dispatch } = useStore();

    return {
        getModelsData: async () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .get(
                    `${API_URL}/llm_manager/models_data`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS_DATA",
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
        },
        getModels: async () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .get(
                    `${API_URL}/llm_manager/models`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS",
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
        },
        fetchModels: (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/llm_manager/models/fetch`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS_DATA",
                        payload: res.data
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data Fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        saveModels: async (data, onSuccess) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/llm_manager/models/save`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS",
                        payload: res.data
                    })
                    onSuccess && onSuccess();
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Connection Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateModels: async (data, onSuccess) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/llm_manager/models/update`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS",
                        payload: res.data
                    })
                    onSuccess && onSuccess();
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteModels: async (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/llm_manager/models/delete`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_LLM_MODELS",
                        payload: res.data
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Remove Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
