import React, { useEffect, useState } from 'react';
import { InputWrapper } from '../InputWrapper';
import KeyUpload from '../KeyUpload';

const AuthZero = ({ containerStyle, authConfig, setAuthConfig, setCanSave, setShowToast }) => {
    const [auth0Data, setAuth0Data] = useState(authConfig && authConfig.value ? authConfig.value : {});
    const [disabledFields, setDisabledFileds] = useState(false);
    const [path, setPath] = useState(null);

    useEffect(() => {
        setDisabledFileds(authConfig && authConfig.type && (authConfig.type !== 'auth0'));
    }, [authConfig])

    useEffect(() => {
        path && setAuth0Data((prevData) => ({ ...prevData, publicKeyPath: path }));
    }, [path])

    useEffect(() => {
        if (Object.keys(auth0Data).length !== 0) {
            if (auth0Data.domain || path || auth0Data.clientId || auth0Data.clientSecret)
                setAuthConfig({ type: 'auth0', label: 'Auth Zero', value: auth0Data });
            else
                setAuthConfig({ type: null, label: null, value: null });
        }

        setCanSave(auth0Data.domain && path && auth0Data.clientId && auth0Data.clientSecret);
    }, [auth0Data])

    const onChange = (e) => {
        setAuth0Data((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    return (
        <div className={`bg-white-gray-500 dark:bg-gray-black p-6 rounded-2xl items-center self-stretch ${containerStyle}`}>
            <p className="mb-3 py-3 text-gray-dark dark:text-white text-lg font-semibold text-center">Auth0</p>
            <form onChange={onChange} onClick={() => disabledFields && setShowToast(true)}>
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.domain ? authConfig.value.domain : ''} label={"Domain"} name={"domain"} type={"text"} disabled={disabledFields} containerStyle={"mb-4"} />
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.clientId ? authConfig.value.clientId : ''} label={"Client ID"} name={"clientId"} type={"text"} disabled={disabledFields} containerStyle={"mb-4"} />
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.clientSecret ? authConfig.value.clientSecret : ''} label={"Client Secret"} name={"clientSecret"} disabled={disabledFields} type={"password"} containerStyle={'mb-4'} autoComplete={"new-password"} />
                <KeyUpload keyType={'auth0PublicKey'} setFilePath={setPath} allowMultiple={false} disabled={disabledFields} path={'/publicKey'} />
            </form>
        </div>
    )
}

export default AuthZero;
