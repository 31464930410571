import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AccordionList from '../../components/AccordionList';
import BottomBar from '../../components/Home/BottomBar';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useCoPilot } from "../../hooks/useCoPilot";
import { useStore } from "../../store/AppContext";
import { TextArea } from '../../components/InputWrapper';
import Cookies from "js-cookie";
import { Button } from '../../components/Button';

const SingleEmail = ({ activeLLM }) => {
    const navigate = useNavigate();
    const coPilotHook = useCoPilot();
    const { isCoPilotStreaming, copilotInference, coPilotSummary, coPilotResponseEmail } = useStore().state;
    const [suggestedReply, setSuggestedReply] = useState(false);
    const [copilotEmail, setCopilotEmail] = useState(localStorage.copilotEmail ? JSON.parse(localStorage.copilotEmail) : null);

    useEffect(() => {
        if (!copilotEmail || !activeLLM)
            navigate("/service-pilot/app");

        return () => {
            localStorage.service_pilot_inference = '';
            localStorage.service_pilot_summary = '';
        }
    }, [])

    useEffect(() => {
        if (!isCoPilotStreaming && activeLLM) {
            const user = JSON.parse(Cookies.get('user'));
            const req = {
                "check_sql_db": false,
                "check_vector_db": false,
                "streaming": true,
                "integration": "local",
                "inference_task": "service_pilot",
                "inference_sub_task": "email_summary",
                "email_subject": copilotEmail && copilotEmail.subject ? copilotEmail.subject : null,
                "email_body": copilotEmail && copilotEmail.body ? copilotEmail.body : null,
                "user_id": user.user_id
            }

            coPilotHook.clearData();
            coPilotHook.fetchSummary_streaming(req);
        }
    }, [])

    useEffect(() => {
        if ((copilotInference === "suggested_reply") && activeLLM) {
            const user = JSON.parse(Cookies.get('user'));
            const req = {
                "check_sql_db": false,
                "check_vector_db": false,
                "streaming": true,
                "integration": "local",
                "inference_task": "service_pilot",
                "inference_sub_task": "email_draft_response",
                "email_subject": copilotEmail && copilotEmail.subject ? copilotEmail.subject : null,
                "email_body": copilotEmail && copilotEmail.body ? copilotEmail.body : null,
                "user_id": user.user_id
            }

            coPilotHook.fetchSuggestedReply_streaming(req);
        }
    }, [copilotInference])

    const handleComposeResponse = () => {
        setSuggestedReply(coPilotResponseEmail.response_email);
    }

    const handleSend = () => {
        const data = {
            to: copilotEmail.sender_mail,
            message_id: copilotEmail.message_id,
            subject: copilotEmail.subject,
            body: suggestedReply
        }
        coPilotHook.sendEmail(data, navigate, '/service-pilot/app');
    }

    const onSuggestedReplyChange = (e) => {
        setSuggestedReply(e.target.value);
    }

    return (
        <main className="w-full overflow-y-auto content-container-with-top-bar mx-auto px-6">
            <div className='flex w-full gap-8'>
                {copilotEmail ?
                    <div className='flex flex-col w-full relative text-gray-dark dark:text-white'>
                        <button className='w-full flex items-start' onClick={() => navigate("/service-pilot/app")}><FontAwesomeIcon className='text-gray' icon={faArrowLeft} /></button>
                        <div className='border-b border-gray-dark-600 pb-6'>
                            <h3 className='my-8 text-lg font-semibold dark:text-white text-gray'><span>Subject:</span>{copilotEmail.subject ? copilotEmail.subject : "No Subject"}</h3>
                            <div className='flex justify-between'>
                                <h4 className='text-md dark:text-white-gray text-gray-light'>{copilotEmail.sender_name}<span className='text-gray-light ml-2'>{copilotEmail.sender_mail}</span></h4>
                                <p className='text-gray-light'> {moment(copilotEmail.send_date).local().format("DD/MM/YYYY, HH:mm A")} ({moment(copilotEmail.send_date).fromNow()})</p>
                            </div>
                        </div>
                        <div className='px-4 py-2 mt-4 mb-2 dark:text-white text-gray-dark bg-white dark:bg-gray-dark-400 text-justify rounded-lg'>
                            <div dangerouslySetInnerHTML={{ __html: copilotEmail.markup_body }} />
                        </div>
                        {suggestedReply &&
                            <>
                                <TextArea rows={20} name={'suggestedReply'} value={suggestedReply} onChange={onSuggestedReplyChange} placeholder="Suggested Reply" className={'max-h-72'} />
                                <Button name={'Send'} handleFunction={handleSend} className={'w-max mt-4'} />
                            </>
                        }
                    </div>
                    :
                    null
                }
                <div className='w-[500px]'>
                    <AccordionList
                        title={"Summary"}
                        textData={coPilotSummary.summary_response}
                        className={'co-pilot-summary-content-container'}
                        info={"Generated summary from subject email."}
                    />
                    <AccordionList
                        title={"Suggested Reply"}
                        textData={coPilotResponseEmail.response_email}
                        button={coPilotResponseEmail.response_email && coPilotResponseEmail.response_email.trim() && <button onClick={handleComposeResponse} disabled={isCoPilotStreaming} className="flex items-center h-11 bg-blue text-white text-base font-bold px-3 py-[10px] rounded-lg disabled:bg-gray-light disabled:dark:bg-gray-light hover:bg-blue-600">Compose Response</button>}
                        className={'co-pilot-suggested-reply-content-container'}
                        info={"Generated response according to custom rules, connected databases, and uploaded files."}
                    />
                </div>
            </div>
        </main>
    )
}

export default SingleEmail;
