import React, { useEffect, useState } from 'react';
import { useStore } from '../store/AppContext';
import AuthZero from '../components/AuthConfig/AuthZero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import LDAP from '../components/AuthConfig/LDAP';
import FileBased from '../components/AuthConfig/FileBased';
import BottomBar from '../components/Home/BottomBar';
import { useConfig } from '../hooks/useConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmPopup from '../components/ConfirmPopup';

const Authentication = (props) => {
	const location = useLocation();
	const configHook = useConfig();
	const navigate = useNavigate();
	const { state } = useStore();
	const [authConfig, setAuthConfig] = useState(state.authConfig);
	const [edit, setEdit] = useState(false);
	const [canSave, setCanSave] = useState(false);
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	useEffect(() => {
		props.setTitle && props.setTitle('Authentication');
	}, [])

	const onNewAuthConfig = () => {
		navigate('/auth-config', { state: { previousPath: location.pathname } });
	}

	const onAuthConfigEdit = () => {
		setEdit(true);
	}

	const onAuthConfigDelete = () => {
		setDeleteConfirm(true);
	}

	const onDeleteConfirm = () => {
		setDeleteConfirm(false);
		configHook.deleteConfig('AUTH', { type: state.authConfig.type, label: null, value: null }, false, null, null);
	}

	const onDeleteCancel = () => {
		setDeleteConfirm(false);
	}

	const handleClose = () => {
		setEdit(false);
	}

	const handleSave = () => {
		if (authConfig.type === 'file_based')
            configHook.uploadFileBased(authConfig.value, navigate, '/users');
        else
			configHook.updateConfig('AUTH', authConfig, true, navigate, '/auth');
		setEdit(false);
	}

	const ButtonList = [
		{
			"name": "Cancel",
			"handleFunction": handleClose,
			"disabled": false,
			"className": "bg-gray border-gray"
		},
		{
			"name": "Save",
			"handleFunction": handleSave,
			"disabled": !canSave
		}
	]

	return (
		<div className="flex p-0">
			<main className="w-full p-0">
				{edit ?
					<div className='w-full flex flex-col'>
						<div className="content-container w-fit mx-6">
							{(state.authConfig.type === 'auth0') && <AuthZero authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} />}
							{(state.authConfig.type === 'ldap') && <LDAP authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} />}
							{(state.authConfig.type === 'file_based') && <FileBased authConfig={authConfig} setAuthConfig={setAuthConfig} setCanSave={setCanSave} />}
						</div>
						<BottomBar ButtonList={ButtonList} />
					</div>
					:
					<div className="mx-6">
						<div className="flex mb-6 justify-end">
							<button
								type="button"
								onClick={onNewAuthConfig}
								disabled={state.authConfig.type}
								className="flex flex-row h-11 items-center bg-transparent disabled:text-gray-light-700 disabled:border-gray-light-700 disabled:pointer-events-none border-blue border text-blue px-6 py-3 rounded-lg hover:text-white hover:bg-blue focus:outline-none"
							>
								<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="currentColor" />
								</svg>
								<span className="ml-2 font-bold">Add New</span>
							</button>
						</div>
						<div className="bg-white-gray-500 dark:bg-gray-black px-6 py-3">
							<p className="text-gray-dark dark:text-white font-semibold">Authentication Type</p>
						</div>
						{state.authConfig.type &&
							<div className="flex flex-row p-6 justify-between">
								<p className="text-gray-dark dark:text-white font-normal">{state.authConfig.label}</p>
								<div className="flex flex-row">
									{state.authConfig.type !== 'file_based' ?
										<button
											type="button"
											className="text-gray-dark dark:text-white hover:text-blue-dark hover:dark:text-blue"
											onClick={onAuthConfigEdit}
										>
											<FontAwesomeIcon className="w-5 mr-6" icon={faPencil} />
										</button>
										:
										<button
											type="button"
											onClick={onAuthConfigEdit}
											className="w-5 mr-6 text-gray-dark dark:text-white hover:text-blue-dark hover:dark:text-blue"
										>
											<svg width="19" height="19" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="currentColor" />
											</svg>
										</button>
									}
									<button
										type="button"
										onClick={onAuthConfigDelete}
										className="text-gray-dark dark:text-white hover:text-blue-dark hover:dark:text-blue"
									>
										<FontAwesomeIcon className="fa-regular w-5" icon={faTrash} />
									</button>
								</div>
							</div>
						}
					</div>
				}
			</main>
			<ConfirmPopup id={'auth_method_delete_popup_div'} title={`Are you sure you want to delete authentication?`} message={'Users will lose their login access.'} confirmLabel={"Delete"} isOpen={deleteConfirm} onConfirm={onDeleteConfirm} onClose={onDeleteCancel} />
		</div>
	);
};

export default Authentication;
