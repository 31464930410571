import React, { useEffect, useState } from 'react';
import { DATABASE_TYPES_DICT, ROUTES_ID } from '../config';
import { useStore } from '../store/AppContext';
import { useConfig } from '../hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import DBConfigWrapper from './DBConfigWrapper';

const DatabaseManager = () => {
    const navigate = useNavigate();
    const configHook = useConfig();
    const { state } = useStore();
    const [loading, setLoading] = useState(false);
    const [dbSearchConfig, setDbSearchConfig] = useState({});

    useEffect(() => {
        configHook.getConfig(ROUTES_ID.DATABASE_SEARCH);
    }, [])

    useEffect(() => {
        if (state.dbSearchConfig && Object.keys(state.dbSearchConfig).length > 0)
            setDbSearchConfig(state.dbSearchConfig);
    }, [state.dbSearchConfig])

    const onSave = () => {
        if ([DATABASE_TYPES_DICT.mysql, DATABASE_TYPES_DICT.postgresql, DATABASE_TYPES_DICT.redshift, DATABASE_TYPES_DICT.bigquery, DATABASE_TYPES_DICT.snowflake].includes(dbSearchConfig?.database[0]?.databaseType?.id)) {
            setLoading(true);
            configHook.updateConfig(ROUTES_ID.DATABASE_SEARCH, dbSearchConfig, true, navigate, '/db-search/app', null, setLoading);
        }
    }

    return (
        <div className={`flex w-full px-6 mt-6 relative overflow-auto hide-scrollbar email-manager-content-container`}>
            <DBConfigWrapper dbConfig={dbSearchConfig} setDBConfig={setDbSearchConfig} index={0} title={`Database`} onSave={onSave} loading={loading} setLoading={setLoading} />
        </div>
    )
}

export default DatabaseManager;
