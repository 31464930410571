import React from 'react';
import { useNavigate } from 'react-router';
import { useStore } from '../../store/AppContext';
import { LICENSE_SERVER_URL, USER_ROLE } from '../../config';
import { Button, TextButton } from '../../components/Button';
import Topbar from '../../components/Home/Topbar';

const RenewSubscription = () => {
    const navigate = useNavigate();
    const { state } = useStore();

    const onHaveLicenseKey = () => {
        navigate('/subscription')
    }

    const onRenewSubscription = () => {
        window.open((state.licenseInfo?.url || LICENSE_SERVER_URL), '_blank');
    }

    return (
        <div className="flex-col min-h-screen items-center justify-center align-middle bg-white dark:bg-gray-dark">
            <Topbar />
            <div className="flex flex-row h-full justify-between items-center gap-20 px-60 bg-white dark:bg-gray-dark">
                <div className='flex flex-col w-full items-center justify-center gap-6'>
                    <img src={state.isDarkMode ? "/assets/images/logo-generate-enterprise-stack-dark-mode.svg" : "/assets/images/logo-generate-enterprise-stack-light-mode.svg"} alt="interplay-logo" className='w-44 mb-0' />
                    <p className='w-full text-center text-2xl font-bold text-gray-black dark:text-white'>
                        {state.licenseIonf?.valid ? 'Your Subscription Has Expired' : 'Your Subscription Is Invalid'}
                    </p>
                    {state.licenseInfo?.valid ?
                        <p className='w-full text-center text-base font-normal text-gray-black dark:text-white'>
                            Your subscription to Generate has expired. To renew, please {(state.userRole === USER_ROLE.SUPER_ADMIN) ? 'click the link below' : 'contact your administrator'}.
                        </p>
                        :
                        <p className='w-full text-center text-base font-normal text-gray-black dark:text-white'>
                            The license is already activated on another machine. To obtain a new license, please {(state.userRole === USER_ROLE.SUPER_ADMIN) ? 'click the link below' : 'contact your administrator'}.
                        </p>
                    }
                    {(state.userRole === USER_ROLE.SUPER_ADMIN) && <Button className={"w-full mt-3 mb-3 py-[8px] gap-2 flex flex-row items-center justify-center"} handleFunction={onRenewSubscription} name={"Activate"} />}
                    {(state.userRole === USER_ROLE.SUPER_ADMIN) && <TextButton addHandleFunction={onHaveLicenseKey} label={"Already have a license key?"} labelClassName={'my-2 pb-1 border-b-2 border-b-white dark:border-b-gray-dark hover:dark:border-b-blue hover:border-b-blue'} />}
                </div>
                <div className='flex w-full bg-white-gray dark:bg-gray-medium-400 rounded-2xl'>
                    <img src='/assets/images/generate-enterprise-branding.png' height={50} width={"100%"} style={{ borderRadius: 16 }} alt='img' />
                </div>
            </div>
        </div>
    )
}

export default RenewSubscription;