import React from 'react';

export const CardDashboard = ({ title, dataList, videoLink, setOpenVideoPlayer }) => {

    const formatNumber = n => {
        if (!n) return 0
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    return (
        <div className='px-6 relative py-8 rounded-xl w-full bg-white-gray-500 dark:bg-gray-black-500'>
            <h4 className='text-gray dark:text-white-gray-400 text-xs'> {title}</h4>
            {!videoLink ?
                (dataList ?
                    <div className='w-2/3 mt-4'>
                        <table className="min-w-full text-left text-sm font-light border border-gray-600">
                            <tbody>
                                {dataList.map((summary, index) => {
                                    return (
                                        summary.label !== "Database search" ?
                                            <tr key={index} className="border border-gray-600 space-x-3">
                                                <td className='p-2 text-black dark:text-white'>{summary.label}</td>
                                                <td className='p-2 font-bold text-black dark:text-white'>{formatNumber(summary.value)}</td>
                                            </tr> : null
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> : null)
                :
                <a href="https://www.youtube.com/playlist?list=PL1--FOq2UuQvJOj3nYOt5_sgOywzQ4l0I" target="_blank" rel="noreferrer">
                    <div className='flex flex-row items-center mt-4 cursor-pointer'>
                        <h6 className='text-blue-dark-400 dark:text-blue-dark-400 mr-2 font-bold text-base'>Watch Now</h6>
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 13.5L11 8L2 2.5V13.5ZM1.25 1.21875C1.71875 0.9375 2.3125 0.9375 2.78125 1.21875L11.7812 6.71875C12.2188 7 12.5 7.5 12.5 8C12.5 8.53125 12.2188 9.03125 11.7812 9.28125L2.78125 14.7812C2.3125 15.0938 1.71875 15.0938 1.25 14.8125C0.78125 14.5625 0.5 14.0625 0.5 13.5V2.5C0.5 1.96875 0.78125 1.46875 1.25 1.21875Z" fill="#336FE4" />
                        </svg>
                    </div>
                </a>
            }
        </div>
    )
}

export const UserCardDashboard = ({ icon, title, subtitle, onClick }) => {
    return (
        <div onClick={onClick} className='flex flex-col justify-center items-center px-6 py-8 rounded-xl w-full bg-white-gray-500 dark:bg-gray-black-500 cursor-pointer'>
            <img src={icon} className="mb-3" />
            <h4 className='text-blue text-lg font-bold mb-3'>{title}</h4>
            <h6 className='text-gray-dark dark:text-white text-base font-normal text-center'>{subtitle}</h6>
        </div>
    )
}


export const SuggestionCard = ({ item, disabled, handleClick }) => {
    return (
        <div onClick={() => !disabled && handleClick(item)} className={`p-4 relative ${disabled ? ' cursor-default ' : ' cursor-pointer '} rounded-2xl border border-white-gray-400 dark:border-gray-400 w-full`}>
            <div className='flex w-full justify-center items-center'>
                <img src={item.src} />
            </div>
            <p className='pt-3 pb-2 text-gray-light text-[13px] font-normal'>{item.title}</p>
            <p className='text-gray-dark dark:text-white text-base font-semibold'>{item.question}</p>
        </div>
    )
}