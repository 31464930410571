import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import AppRoute from "./AppRoute";
import { useStore } from "../store/AppContext";
import { useConfig } from "../hooks/useConfig";
import { LICENSE_KEY } from "../config";

function RootRoute() {
    const { state } = useStore();
    const configHook = useConfig();
    const [loading, setLoading] = useState(true);
    const [isLicenseKeyFetched, setLicenseKeyFetched] = useState(false);

    useEffect(() => {
        !state.loading && setLoading(false);
    }, [state.loading])

    useEffect(() => {
        configHook.getConfig(LICENSE_KEY, onLicenseFetchedResult);
    }, [])
    
    useEffect(() => {
        setLoading(true);
        configHook.getConfig('AUTH');
    }, [state.authConfigChanged])

    const onLicenseFetchedResult = () => {
        setLicenseKeyFetched(true);
    }

    return (
        !loading && isLicenseKeyFetched &&
        (state.authConfig && state.authConfig.type === "auth0" ?
            <Auth0Provider
                domain={state.authConfig.value.domain}
                clientId={state.authConfig.value.clientId}
                authorizationParams={{
                    redirect_uri: window.location.origin + "/login"
                }}
            >
                <Router>
                    <AppRoute />
                </Router>
            </Auth0Provider>
            :
            <Router>
                <AppRoute />
            </Router>
        )
    )
}

export default RootRoute;
