import { useEffect } from "react";
import Cookies from "js-cookie";
import { useStore } from "./store/AppContext";
import useWebSocket from 'react-use-websocket';
import { BASE_WEBSOCKET_URL } from "./config";

const AppWrapper = (props) => {
    const { state, dispatch } = useStore();
    const { lastJsonMessage } = useWebSocket(`${BASE_WEBSOCKET_URL}/api/v1/vdb/ws`, { shouldReconnect: () => true });

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.user_id && state.user && (lastJsonMessage.user_id === state.user.user_id)) {
            dispatch({
                type: `UPDATE_${lastJsonMessage.appId}_DOC_STATUS`,
                payload: lastJsonMessage,
            })
        }
    }, [lastJsonMessage, state.user && state.user.user_id]);

    useEffect(() => {
        if (!state.token && Cookies.get('token') && Cookies.get("user")) {
            dispatch({
                type: "LOGIN",
                payload: {
                    token: Cookies.get("token"),
                    user: JSON.parse(Cookies.get("user"))
                }
            })
        }
    }, [])

    return props.children;
}

export default AppWrapper;
