import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY, ROUTES_ID, DATABASE_TYPES_DICT, LICENSE_KEY } from "../config";
import { useStore } from '../store/AppContext';
import { useDbSearch } from "./useDbSearch";

export const useConfig = () => {
    const { dispatch, state } = useStore();
    const dbSearchHook = useDbSearch()

    return {
        getConfig: (configKey, callback) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            configKey === LICENSE_KEY && dispatch({
                type: 'SET_LICENSE_INFO_FETCHED',
                payload: false,
            })
            axios
                .get(`${API_URL}/config/${configKey}`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res?.data,
                    })
                    if (configKey === LICENSE_KEY) {
                        dispatch({
                            type: 'SET_LICENSE_INFO',
                            payload: res?.data?.licenseInfo,
                        })
                        dispatch({
                            type: 'SET_LICENSE_INFO_FETCHED',
                            payload: true,
                        })
                    }
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    callback && callback();
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data Fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                    configKey === LICENSE_KEY && dispatch({
                        type: 'SET_LICENSE_INFO_FETCHED',
                        payload: true,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    callback && callback();
                })
        },
        updateConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null, message, setLoading) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            configKey === LICENSE_KEY && dispatch({
                type: 'SET_LICENSE_INFO_FETCHED',
                payload: false,
            })
            axios
                .post(`${API_URL}/config/save/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: configValue,
                    })
                    if (configKey === LICENSE_KEY) {
                        dispatch({
                            type: 'SET_LICENSE_INFO',
                            payload: res.data.licenseInfo,
                        })
                        dispatch({
                            type: 'SET_LICENSE_INFO_FETCHED',
                            payload: true,
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: message ? message : {
                                type: "success",
                                title: "Activated Successfully",
                                subtitle: "Your license has been activated successfully"
                            }
                        })
                    } else {
                        showmessage && dispatch({
                            type: "SET_MESSAGE",
                            payload: message ? message : {
                                type: "success",
                                title: "Saved Successfully",
                                subtitle: "Your changes have been saved successfully"
                            }
                        })
                    }
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })

                    if ((configKey === ROUTES_ID.DATABASE_SEARCH) && configValue && configValue.database.length > 0) {
                        let newDBConfig = {
                            database_name: configValue.database[0].databaseName,
                            database_type: configValue.database[0].databaseType.id,
                            user_id: state.user?.user_id,
                            integration: 'local'
                        }
                        switch (configValue.database[0].databaseType.id) {
                            case DATABASE_TYPES_DICT.csv:
                            case DATABASE_TYPES_DICT.excel:
                                newDBConfig.file_name = configValue.database[0].doc_path ? configValue.database[0].doc_path.split('/').pop() : null;
                                break
                            case DATABASE_TYPES_DICT.mysql:
                            case DATABASE_TYPES_DICT.postgresql:
                            case DATABASE_TYPES_DICT.redshift:
                                newDBConfig.host = configValue.database[0].host;
                                newDBConfig.port = configValue.database[0].port;
                                newDBConfig.dbUsername = configValue.database[0].dbUsername;
                                newDBConfig.password = configValue.database[0].password;
                                break
                            case DATABASE_TYPES_DICT.bigquery:
                                newDBConfig.project_name = configValue.database[0].projectName;
                                newDBConfig.service_account_file = configValue.database[0].doc_path ? configValue.database[0].doc_path.split('/').pop() : null;
                                break
                            case DATABASE_TYPES_DICT.snowflake:
                                newDBConfig.account = configValue.database[0].account;
                                newDBConfig.warehouse = configValue.database[0].warehouse;
                                newDBConfig.schema = configValue.database[0].schema;
                                newDBConfig.dbUsername = configValue.database[0].dbUsername;
                                newDBConfig.password = configValue.database[0].password;
                                newDBConfig.role = 'ACCOUNTADMIN';
                                break
                            default:
                                break;
                        }
                        dbSearchHook.connectDatabase(true, newDBConfig, navigate, navLink, setLoading);
                    } else {
                        setLoading && setLoading(false);
                        navLink && navigate(navLink);
                    }
                })
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    configKey === LICENSE_KEY && dispatch({
                        type: 'SET_LICENSE_INFO_FETCHED',
                        payload: true,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err?.response?.data?.message?.error ? err?.response?.data?.message?.error : err?.response?.data?.message ? err?.response?.data?.message : "Please try again"
                        }
                    })
                    setLoading && setLoading(false);
                })
        },
        deleteConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/config/delete/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Delete Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        uploadFileBased: (formData, navigate, navLink) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/file_based`, formData,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                .then((res) => {
                    console.log(res.data)
                    if (['success', 'warning'].includes(res.data.status)) {
                        dispatch({
                            type: `UPDATE_AUTH_CONFIG`,
                            payload: { type: 'file_based', label: 'File Based', value: {} },
                        })
                        dispatch({
                            type: "LOADING",
                            payload: false,
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Configured Successfully",
                                subtitle: res.data.message,
                                close: (res.data.status === 'warning') ? "manual" : ""
                            }
                        })
                        navLink && navigate(navLink);
                    } else {
                        dispatch({
                            type: "LOADING",
                            payload: false,
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Configured Failed",
                                subtitle: res.data.message
                            }
                        })
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
