
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import TooltipWrapper from '../TooltipWrapper';
import { APPS, APP_SUPPORT_INTEGRATION_TYPES, APP_SUPPORT_VECTOR_DB_TYPES, ROUTES_ID, SUB_PAGE_APPS, TOOL_TIPS, USER_ROLE_LABEL } from '../../config';
import { useUser } from '../../hooks/useUser';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileModal from '../ProfileModal';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ThemeChange from '../ThemeChange';

const Topbar = ({ isOpen, onToggleSidebar, showHeaderIcon, activeTabId, sectionTitle, activeVDB, activeLLM }) => {
	const userHook = useUser();
	const { state, dispatch } = useStore();
	const vdbDropdownRef = useRef(null);
	const vdbDropdownOptionsRef = useRef(null);
	const llmDropdownRef = useRef(null);
	const llmDropdownOptionsRef = useRef(null);
	const llmDropdownSubOptionsRef = useRef(null);
	const integrationDropdownRef = useRef(null);
	const integrationDropdownOptionsRef = useRef(null);
	const profileIconRef = useRef(null);
	const profileDropMenuRef = useRef(null);
	const [showVDBs, setShowVDBs] = useState(false);
	const [showLLMs, setShowLLMs] = useState(false);
	const [models, setModels] = useState({});
	const [selectedModelInfo, setSelectedModelInfo] = useState(null);
	const [showIntegrations, setShowIntegrations] = useState(false);
	const [openProfileMenu, setOpenProfileMenu] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isThemeOpen, setIsThemeOpen] = useState(false);
	const { logout } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		let newModels = {};
		state.llmModels && state.llmModels.forEach(model => {
			let appModelTypes = newModels[model.app_id] ? newModels[model.app_id] : [];

			const index = appModelTypes.findIndex(appModel => (appModel.model_type_id === model.model_type_id));
			if (index > -1) {
				let appModels = appModelTypes[index];
				appModels.models && appModels.models.push(model);
				appModelTypes[index] = appModels;
			} else {
				appModelTypes.push({ model_type_id: model.model_type_id, model_type_name: model.model_type_name, models: [model] });
			}

			newModels[model.app_id] = appModelTypes;
		})

		setModels({ ...newModels });
	}, [state.llmModels])

	useEffect(() => {
		setShowLLMs(false);
		setShowVDBs(false);
	}, [activeTabId])

	useEffect(() => {
		const handleClickOutsideLLM = (event) => {
			if (profileIconRef.current && !profileIconRef.current.contains(event.target) && profileDropMenuRef.current && !profileDropMenuRef.current.contains(event.target)) {
				handleLeave();
			}
		}

		document.addEventListener("mousedown", handleClickOutsideLLM);
		return () => document.removeEventListener("mousedown", handleClickOutsideLLM);
	}, [profileIconRef, profileDropMenuRef])

	useEffect(() => {
		const handleClickOutsideIntegrations = (event) => {
			if (integrationDropdownRef.current && !integrationDropdownRef.current.contains(event.target) && integrationDropdownOptionsRef.current && !integrationDropdownOptionsRef.current.contains(event.target)) {
				setShowIntegrations(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideIntegrations);
		return () => document.removeEventListener("mousedown", handleClickOutsideIntegrations);
	}, [integrationDropdownRef, integrationDropdownOptionsRef])

	useEffect(() => {
		const handleClickOutsideLLM = (event) => {
			if (llmDropdownRef.current && !llmDropdownRef.current.contains(event.target) && llmDropdownOptionsRef.current && !llmDropdownOptionsRef.current.contains(event.target) && ((!llmDropdownSubOptionsRef.current) || (llmDropdownSubOptionsRef.current && !llmDropdownSubOptionsRef.current.contains(event.target)))) {
				setSelectedModelInfo(null);
				setShowLLMs(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideLLM);
		return () => document.removeEventListener("mousedown", handleClickOutsideLLM);
	}, [llmDropdownRef, llmDropdownOptionsRef])

	useEffect(() => {
		const handleClickOutsideVDB = (event) => {
			if (vdbDropdownRef.current && !vdbDropdownRef.current.contains(event.target) && vdbDropdownOptionsRef.current && !vdbDropdownOptionsRef.current.contains(event.target)) {
				setShowVDBs(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideVDB);
		return () => document.removeEventListener("mousedown", handleClickOutsideVDB);
	}, [vdbDropdownRef, vdbDropdownOptionsRef])

	const onLlmDropdownSelect = (option) => {
		let config = state.allConfig && state.allConfig[activeTabId] ? { ...state.allConfig[activeTabId] } : {};
		config.llm = option;
		const llmConfig = { appId: activeTabId, modelId: option.model_id, modelName: option.model_name };
		userHook.updateUserLLMConfig(llmConfig, activeTabId, config, false);

		setSelectedModelInfo(null);
		setShowLLMs(false);
	}

	const onIntegrationsDropdownSelect = (option) => {
		let integrations = state.selectedIntegration;
		integrations[activeTabId] = option;

		dispatch({
			type: 'SET_SELECTED_INTEGRATION',
			payload: integrations
		})
		setShowIntegrations(false);
	}

	const handleProfileMenu = () => {
		setOpenProfileMenu(!openProfileMenu);
	}

	const handleLeave = () => {
		setOpenProfileMenu(false);
	}

	const onClose = () => {
		setIsModalOpen(false);
	}

	const onCloseTheme = () => {
		setIsThemeOpen(false);
	}

	const onLogout = () => {
		dispatch({
			type: 'LOGOUT'
		})

		Cookies.remove("token");
		Cookies.remove("user");
		if (state.authConfig.type === "auth0")
			logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
		else
			navigate("/login");
	}

	const getItemLocation = (tab) => {
		if (activeTabId === ROUTES_ID.SERVICE_PILOT)
			return `/service-pilot/config?tabId=${tab}`
		else if (activeTabId === ROUTES_ID.DATABASE_SEARCH)
			return `/db-search/config?tabId=${tab}`
		else if (activeTabId === ROUTES_ID.DOCUMENT_SEARCH)
			return `/doc-search/config?tabId=${tab}`
	}

	return (
		<header className="flex flex-row h-20 w-full bg-white dark:bg-gray-dark">
			{onToggleSidebar &&
				(isOpen ?
					<button
						className="absolute z-50  text-gray-600 -ml-8  mt-2 w-6 focus:outline-none"
						onClick={onToggleSidebar}
					>
						<FontAwesomeIcon icon="xmark" />
					</button>
					:
					<button
						className="absolute z-50  text-gray-600 ml-4  mt-6 w-6 focus:outline-none"
						onClick={onToggleSidebar}
					>
						<FontAwesomeIcon icon={faBars} />
					</button>
				)
			}
			<div className="w-full px-6 py-4 flex justify-between items-center">
				{showHeaderIcon &&
					<div className="flex flex-row items-center">
						<img src={state.isDarkMode ? "/assets/images/logo-generate-enterprise-dark-mode.svg" : "/assets/images/logo-generate-enterprise-light-mode.svg"} alt="interplay-logo" className='w-60 mb-0' />
					</div>
				}
				<div className='flex flex-row items-center'>
					<h1 className={`text-2xl font-semibold text-gray-dark dark:text-gray-800 ${!isOpen ? "ml-12" : "ml-0"}`}>{sectionTitle}</h1>
					{TOOL_TIPS[activeTabId] &&
						<TooltipWrapper content={TOOL_TIPS[activeTabId]} tootTipStyle={'w-max max-w-[400px] mt-[15.5px]'} autoHide={false} isShowArrow={true}>
							<div className='flex ml-1 items-center justify-center'>
								<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
								</svg>
							</div>
						</TooltipWrapper>
					}
					{activeTabId && USER_ROLE_LABEL[state.userRole] &&
						<div className="relative group md:flex items-center px-2 py-1 border-2 border-white-gray-dark-700 dark:border-gray-medium rounded-lg ml-3 cursor-default">
							<p className="text-gray-dark dark:text-white text-sm text-center">{USER_ROLE_LABEL[state.userRole]}</p>
						</div>
					}
				</div>
				<div className="flex items-center">
					{activeVDB && activeTabId !== ROUTES_ID.DATABASE_SEARCH &&
						<>
							<div className='relative'>
								<div ref={vdbDropdownRef} className={`relative md:flex items-center border-2 py-1 px-2 border-white-gray-dark-700 dark:border-gray-medium rounded-lg cursor-default`}>
									<div className="relative inline-flex mr-2">
										<div className="w-2 h-2 bg-green rounded-full"></div>
										<div className="w-2 h-2 bg-green rounded-full absolute top-0 left-0 animate-ping"></div>
									</div>
									<p className="text-gray-dark dark:text-white text-base text-center font-normal">{activeVDB}</p>
								</div>
								{showVDBs &&
									<div ref={vdbDropdownOptionsRef} className={`block w-full min-w-[110px] max-h-52 p-2 z-10 absolute rounded-lg bg-gray-900 dark:bg-gray-black hide-scrollbar overflow-auto`}>
										{state.allConfig && APP_SUPPORT_VECTOR_DB_TYPES[activeTabId] && APP_SUPPORT_VECTOR_DB_TYPES[activeTabId].map((option, index) => {
											return <div id={option.id} className={`text-gray-dark cursor-pointer dark:text-white w-full flex justify-start ${(index < state.allConfig[activeTabId].llmList.length - 1) ? 'mb-[10px]' : ''} p-1 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.label}>{option.label}</div>
										})}
									</div>
								}
							</div>
							<TooltipWrapper content={`Active Vector Database for ${APPS[activeTabId]}.`} tootTipStyle={'w-40 mt-[15px]'} autoHide={false} isShowArrow={true} onTop={false}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</>
					}
					{APPS[activeTabId] &&
						<div className='pl-6 flex items-center'>
							<div className='relative'>
								<div ref={llmDropdownRef} className={`${(!activeLLM && state.showNoLLMAlert) ? 'animate-bounce border-red dark:border-red' : 'border-white-gray-dark-700 dark:border-gray-medium'} relative md:flex items-center border-2 py-1 px-2 rounded-lg cursor-pointer`} onClick={() => setShowLLMs(!showLLMs)}>
									{activeLLM ?
										<div className="relative inline-flex mr-2">
											<div className="w-2 h-2 bg-green rounded-full"></div>
											<div className="w-2 h-2 bg-green rounded-full absolute top-0 left-0 animate-ping"></div>
										</div>
										:
										<div className="relative inline-flex mr-2">
											<div className={`w-2 h-2 ${state.showNoLLMAlert ? 'bg-red' : 'bg-gray-light'} rounded-full`}></div>
										</div>
									}
									<p className={` ${(!activeLLM && state.showNoLLMAlert) ? 'text-red dark:text-red' : 'text-gray-dark dark:text-white'} text-base text-center font-normal`}>{activeLLM ? activeLLM : "Select an LLM"}</p>
								</div>
								{(!activeLLM && state.showNoLLMAlert) &&
									<div className='flex max-w-60 w-max h-max absolute right-0 mt-[5px]'>
										<div className='flex p-3 w-full max-h-40 relative z-10 rounded-lg bg-gray-900 dark:bg-gray-black overflow-y-auto'>
											<p className='text-gray-dark dark:text-white'>Select an LLM to proceed. Contact admin if none are available. <a href="https://interplay.helpjuice.com/generate" target="_blank" rel="noreferrer" className='text-blue text-nowrap font-semibold'>Learn More</a></p>
										</div>
									</div>
								}
								<div className='flex w-max h-max absolute right-0 mt-[3px]'>
									<div className='flex w-full relative'>
										{selectedModelInfo && selectedModelInfo.info &&
											<div ref={llmDropdownSubOptionsRef} style={{ marginTop: selectedModelInfo.offset }} className={`w-max min-w-40 h-max max-h-60 mr-[3px] p-2 z-10 rounded-lg bg-gray-900 dark:bg-gray-black overflow-auto`}>
												{selectedModelInfo.info.models.map((model, index) => {
													return (
														<div id={model.model_name} onClick={() => onLlmDropdownSelect(model)} className={`text-gray-dark cursor-pointer dark:text-white w-full flex px-2 justify-start ${(index < selectedModelInfo.info.models.length - 1) ? 'mb-[10px]' : ''} p-1 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={model.model_name}>{model.model_name}</div>
													)
												})}
											</div>
										}
										{showLLMs && models[activeTabId] && models[activeTabId].length > 0 &&
											<div ref={llmDropdownOptionsRef} className={`w-52 max-h-56 h-max p-2 z-10 rounded-lg bg-gray-900 dark:bg-gray-black overflow-auto`}>
												{models[activeTabId].map((option, index) => {
													return (
														<div id={option.model_type_id} onMouseEnter={() => setSelectedModelInfo({ offset: `${index * 42}px`, info: option })} className={`text-gray-dark dark:text-white w-full flex items-center px-2 gap-2 justify-between cursor-pointer ${(index < models[activeTabId].length - 1) ? 'mb-[10px]' : ''} p-1 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index}>
															<p>{option.model_type_name}</p>
															<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1.5 0L6 5L1.5 10H0V0H1.5Z" fill="currentColor" />
															</svg>
														</div>
													)
												})}
											</div>
										}
									</div>
								</div>
							</div>
							<TooltipWrapper content={`Active LLM for ${APPS[activeTabId]}.`} tootTipStyle={'w-32 mt-[15px] -ml-[70px]'} autoHide={false} isShowArrow={true} onTop={false}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</div>
					}
					{activeTabId && activeTabId !== ROUTES_ID.DATABASE_SEARCH && state.selectedIntegration && state.selectedIntegration[activeTabId] && state.selectedIntegration[activeTabId].id &&
						<div className='relative flex items-center pl-6'>
							<div ref={integrationDropdownRef} onClick={() => setShowIntegrations(!showIntegrations)} className={`relative flex w-max gap-2 items-center border-2 px-2 py-[6px] border-white-gray-dark-700 dark:border-gray-medium rounded-lg cursor-pointer`}>
								<div className='flex w-4 h-4 items-center justify-center'>{state.selectedIntegration && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH] && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id ? state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].icon : ''}</div>
								<p className='w-full text-nowrap text-gray-dark dark:text-white text-sm'>{state.selectedIntegration && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH] && state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id ? state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].label : 'Select an Integration'}</p>
							</div>
							{showIntegrations &&
								<div ref={integrationDropdownOptionsRef} className={`block max-h-36 w-max pt-1 z-10 absolute top-[37px] right-0 bg-gray-900 dark:bg-gray-black overflow-auto rounded-lg`}>
									{APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH] && APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH].map((option, index) => !option.comingSoon &&
										<div id={option.id} onClick={() => onIntegrationsDropdownSelect(option)} className={`flex flex-row gap-2 p-2 m-2 cursor-pointer dark:text-white items-center justify-start ${(index < APP_SUPPORT_INTEGRATION_TYPES[ROUTES_ID.DOCUMENT_SEARCH].length - 1) ? '' : ''} hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.label}>
											<div className={`relative md:flex w-5 h-5 items-center`}>{option.icon}</div>
											<p className='text-nowrap text-gray-dark dark:text-white self-center text-sm'>{option.label}</p>
										</div>
									)}
								</div>
							}
							<TooltipWrapper content={"Select an Integration"} tootTipStyle={'w-max mt-[15px] -ml-20'} autoHide={false} isShowArrow={true}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</div>
					}
					<div className="flex relative items-center" >
						<button ref={profileIconRef} className="focus:outline-none bg-none ml-4 w-10 h-10 rounded-full overflow-hidden" onClick={handleProfileMenu} >
							{state.user && state.user.user_image && state.user.user_image !== 'null' ?
								<div className='pb-4'>
									<img className='w-10 h-10 rounded-full' src={state.user.user_image} alt="" />
								</div>
								:
								<div className='w-16'>
									<div className='w-10 h-10 flex justify-center items-center text-gray dark:text-white-gray-dark bg-white-gray-600 dark:bg-gray-black rounded-full p-2'>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
											<path fillRule="evenodd" clipRule="evenodd" d="M10.7945 4.36364C10.7945 5.97028 9.49202 7.27273 7.88538 7.27273C6.27873 7.27273 4.97629 5.97028 4.97629 4.36364C4.97629 2.75699 6.27873 1.45455 7.88538 1.45455C9.49202 1.45455 10.7945 2.75699 10.7945 4.36364ZM12.249 4.36364C12.249 6.77361 10.2953 8.72727 7.88538 8.72727C5.47541 8.72727 3.52174 6.77361 3.52174 4.36364C3.52174 1.95367 5.47541 0 7.88538 0C10.2953 0 12.249 1.95367 12.249 4.36364ZM14.3396 15.5539C14.4784 15.8203 14.7471 16 15.0474 16C15.569 16 15.9317 15.4835 15.6988 15.0168C14.2681 12.1505 11.3067 10.1818 7.88538 10.1818C4.4641 10.1818 1.50263 12.1505 0.0719854 15.0168C-0.160924 15.4835 0.20177 16 0.723307 16C1.02368 16 1.29237 15.8203 1.43114 15.5539C2.64377 13.226 5.07894 11.6364 7.88538 11.6364C10.6918 11.6364 13.127 13.226 14.3396 15.5539Z" fill="currentColor" />
										</svg>
									</div>
								</div>
							}
						</button>
						{openProfileMenu &&
							<div ref={profileDropMenuRef} className={`flex flex-col absolute w-max z-50 right-0 top-12 dark:text-white bg-white-600 dark:bg-gray-black px-2 py-2 rounded-lg box-shadow-lg border-gray-800 border`}>
								<button onClick={() => { setIsModalOpen(true); setOpenProfileMenu(false) }} className='py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md'>Profile</button>
								<button onClick={() => { setIsThemeOpen(true); setOpenProfileMenu(false) }} className='py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md'>Theme</button>
								{SUB_PAGE_APPS.MY_DOCUMENTS[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('my-documents')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>My Documents</button>}
								{SUB_PAGE_APPS.MY_DATABASES[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('my-databases')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>My Databases</button>}
								{SUB_PAGE_APPS.EMAIL_MANAGER[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('email-manager')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>Email Manager</button>}
								{SUB_PAGE_APPS.DATABASE_MANAGER[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('database-manager')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>Database Manager</button>}
								{SUB_PAGE_APPS.LLM_MANAGER[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('llm-manager')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>LLM Manager</button>}
								{SUB_PAGE_APPS.INTEGRATIONS[state.userRole].includes(activeTabId) && <button onClick={() => { navigate(getItemLocation('integrations')); setOpenProfileMenu(false) }} className={`py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light disabled:dark:text-grat-light`}>Integrations</button>}
								<button onClick={onLogout} className='py-1 px-4 my-1 text-left hover:bg-white-gray-500 hover:dark:bg-gray-dark-600 rounded-md'>Logout</button>
							</div>
						}
					</div>
				</div>
			</div>
			<ProfileModal onClose={onClose} isOpen={isModalOpen} />
			<ThemeChange onClose={onCloseTheme} isOpen={isThemeOpen} />
		</header>
	)
}

export default Topbar;
