import React, { useEffect, useState } from 'react';
import { CardDashboard } from '../components/Cards';
import { useStore } from '../store/AppContext';
import { useDashboard } from "../hooks/useDashboard";
import Table from "../components/Table";
import moment from "moment";
import { TextButton } from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { ROUTES_ID } from '../config';
import TooltipWrapper from '../components/TooltipWrapper';

const DashboardPage = () => {
	const dashboardHook = useDashboard();
	const { state } = useStore();
	const [tab, setTab] = useState('db_search');
	const [appUsageList, setAppUsageList] = useState([]);
	const [documentSearchQueries, setDocumentSearchQueries] = useState([]);
	const [databaseSearchQueries, setDatabaseSearchQueries] = useState([]);
	const [openVideoPlayer, setOpenVideoPlayer] = useState(false)
	const [fileUploadList, setFileUploadList] = useState([]);

	useEffect(() => {
		dashboardHook.getDashboardSummary();
		dashboardHook.getQueries();
	}, [])

	useEffect(() => {
		if (state.dashboardSummary && Object.keys(state.dashboardSummary).length > 0) {
			setAppUsageList([
				{ label: "Database Search", value: state.dashboardSummary.dbSearchCount },
				{ label: "Document search", value: state.dashboardSummary.documentSearchCount }
			])
			setFileUploadList([
				{ label: "Service Pilot", value: state.dashboardSummary.docs_count[ROUTES_ID.SERVICE_PILOT] ? state.dashboardSummary.docs_count[ROUTES_ID.SERVICE_PILOT] : 0 },
				{ label: "Database Search", value: state.dashboardSummary.docs_count[ROUTES_ID.DATABASE_SEARCH] ? state.dashboardSummary.docs_count[ROUTES_ID.DATABASE_SEARCH] : 0 },
				{ label: "Document search", value: state.dashboardSummary.docs_count[ROUTES_ID.DOCUMENT_SEARCH] ? state.dashboardSummary.docs_count[ROUTES_ID.DOCUMENT_SEARCH] : 0 }
			])
		}
	}, [state.dashboardSummary])

	useEffect(() => {
		if (state.dashboardQueries.documentSearch && state.dashboardQueries.documentSearch.queries) {
			let documentSearchQueries = [];
			state.dashboardQueries.documentSearch.queries.forEach(documentSearch => {
				documentSearchQueries.push({ created: moment(documentSearch.created).format('DD/MM/YYYY, h:mm a'), query: documentSearch.question, user: ((documentSearch.first_name ? documentSearch.first_name : "") + " " + (documentSearch.last_name ? documentSearch.last_name : "")).trim(), safe_search: documentSearch.safe_search ? JSON.parse(documentSearch.safe_search).label : null })
			})
			setDocumentSearchQueries(documentSearchQueries);
		}

		if (state.dashboardQueries.dbSearch && state.dashboardQueries.dbSearch.queries) {
			let databaseSearchQueries = [];
			state.dashboardQueries.dbSearch.queries.forEach(dbSearch => {
				databaseSearchQueries.push({ created: moment(dbSearch.created).format('DD/MM/YYYY, h:mm a'), query: dbSearch.search_queary, user: ((dbSearch.first_name ? dbSearch.first_name : "") + " " + (dbSearch.last_name ? dbSearch.last_name : "")).trim() })
			})
			setDatabaseSearchQueries(databaseSearchQueries);
		}
	}, [state.dashboardQueries])

	const onClickMore = (db, page) => {
		dashboardHook.getQueriesPage(db, page);
	}

	const doc_columns = [
		{
			id: "created",
			title: "Date",
			flex: 0.15,
			dataClassName: 'py-3'
		},
		{
			id: "query",
			title: "Query",
			flex: 0.85
		}
	]

	const db_columns = [
		{
			id: "created",
			title: "Date",
			flex: 0.15,
			dataClassName: 'py-3'
		},
		{
			id: "query",
			title: "Query",
			flex: 0.85
		}
	]

	const tooltipInfo = {
		"APP USAGE": "The total number of queried prompts.",
		"FILE UPLOADS": "The total number of uploaded files.",
		"VIDEO TUTORIAL": "Generate AIPC video tutorial on core features and functionalities."
	}

	return (
		<div className="flex px-6">
			<main className="w-full mx-auto">
				{state.dashboardSummary && Object.keys(state.dashboardSummary).length > 0 &&
					<div className='grid grid-cols-3 pb-6 gap-6'>
						<CardDashboard title={"APP USAGE"} dataList={appUsageList} tooltipInfo={tooltipInfo} />
						<CardDashboard title={"FILE UPLOADS"} dataList={fileUploadList} tooltipInfo={tooltipInfo} />
						<CardDashboard title={"VIDEO TUTORIAL"} videoLink={true} openVideoPlayer={openVideoPlayer} setOpenVideoPlayer={setOpenVideoPlayer} tooltipInfo={tooltipInfo} />
					</div>
				}
				<div className='flex flex-row items-center w-full mb-3'>
					<TextButton addHandleFunction={() => setTab('db_search')} label={"Database Search Queries"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'db_search') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-b-white dark:border-b-gray-dark '}`} />
					<TooltipWrapper content="Past Database Search Queries" tootTipStyle={'w-max mt-[15px]'} autoHide={false} isShowArrow={true}>
						<div className='flex ml-1 -mt-3 items-center justify-center'>
							<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
							</svg>
						</div>
					</TooltipWrapper>
					<span className='w-6' />
					<TextButton addHandleFunction={() => setTab('doc_search')} label={"Document Search Queries"} labelClassName={`border-b-2 pb-3 text-lg font-bold ${(tab === 'doc_search') ? ' text-gray-dark dark:text-white border-b-gray-dark dark:border-b-white ' : ' text-gray border-b-white dark:border-b-gray-dark '}`} />
					<TooltipWrapper content="Past Document Search Queries" tootTipStyle={'w-max mt-[15px]'} autoHide={false} isShowArrow={true}>
						<div className='flex ml-1 -mt-3 items-center justify-center'>
							<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
							</svg>
						</div>
					</TooltipWrapper>
				</div>
				{(tab === 'db_search') &&
					<div className='relative'>
						<Table columns={db_columns} data={databaseSearchQueries} className={'dashboard-queries-content-container'} emptyResultMessage={'No queries'} />
						{state.dashboardQueries && state.dashboardQueries.dbSearch && state.dashboardQueries.dbSearch.hasMore &&
							<TextButton addHandleFunction={() => onClickMore('dbSearch', state.dashboardQueries.dbSearch.page + 1)} label={"More"} className={"absolute right-0 text-gray-dark-500 dark:text-white mt-2"} icon={<FontAwesomeIcon icon={faList} />} />
						}
					</div>
				}
				{(tab === 'doc_search') &&
					<div className='relative'>
						<Table columns={doc_columns} data={documentSearchQueries} className={'dashboard-queries-content-container'} emptyResultMessage={'No queries'} />
						{state.dashboardQueries && state.dashboardQueries.documentSearch && state.dashboardQueries.documentSearch.hasMore &&
							<TextButton addHandleFunction={() => onClickMore('documentSearch', state.dashboardQueries.documentSearch.page + 1)} label={"More"} className={"absolute right-0 text-gray-dark-500 dark:text-white mt-2"} icon={<FontAwesomeIcon icon={faList} />} />
						}
					</div>
				}
			</main>
		</div>
	)
}

export default DashboardPage;
